import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../../graphql/mutations';
import {useParams,useHistory} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../../css/uploadurl.css";
import {getPlayslide} from "../../../graphql/queries";
import Navbars from "../../Navbar";
import Foot from "../../Foot";
import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Leftarrow from "../../../img/left-arrow.png";

function Uploadvimeo() {
    const { id } = useParams();
    const history = useHistory();
    const [val, setVal] = useState("");
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [images, setImages] = useState([]);
    const [uploadedmessage, setUploadedmessage] = useState("");
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    const handleChange = e => {
        setVal(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const rid = uuid();
            const rurl = `https://demo.reachum.com/vimeoshowroom/${id}/${rid}`;
            const erurl = `https://demo.reachum.com/vimeoedit/${id}/${rid}`
            const urldata = {
                rid,
                title:"embed video",
                lurl:val,
                rurl,
                eurl: erurl
            };
            const allImages = [...images, urldata]
            const input = {
                id,
                playlistlinks: allImages
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
            setUploadedmessage("Uploading...")
            setTimeout(()=> {
                setUploadedmessage("The file added to the content list.")
                history.push(`/editroom/${id}`);
            },1000)
        }catch(err){
            console.log(err)
        }
    }
    function scp(){
        history.push(`/editroom/${id}`);
    }
    return (
        isOwner === isUser ?
        <>
            <Helmet>
                <title>Upload Vimeo or Youtube Embed URL</title>
            </Helmet>
            <Navbars />
        <div className="uploadurl">
            <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
            <br/>
            <br/>
                    <section>
                        <p>
                            Add a Youtube or Vimeo video
                            <br/> and play/pause controlled by the viewer
                        </p>
                    </section>
                    <br/>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                                <input onChange={handleChange} className="form-control" name="clink" placeholder="URL"/>
                                </Grid>
                                <Grid item xs={3}>
                                <input type="submit" value="Submit" className="inputstyles"/>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                   <p className="uploaded">{uploadedmessage}</p>
            </div>
            <Foot />
            </>
            :""
    );
}

export default Uploadvimeo;
