import { useEffect, useState } from 'react';
import { Grid } from '@giphy/react-components';
import ResizeObserver from "react-resize-observer";
import { getTrending, performSearch } from './giphyService';

export default function GiphyGrid({ onGifClick, searchTerm }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [term, setTerm] = useState(searchTerm);

  useEffect(() => {
    setTerm(searchTerm);
  }, [searchTerm]);

  const getSearch = (offset) => {
    const searchOp = performSearch(term);
    return searchOp(offset);
  }

  const getGrid = (fetch) => {
    return (
      <Grid
        onGifClick={onGifClick}
        fetchGifs={fetch}
        width={width}
        columns={4}
        gutter={8}
        hideAttribution="true"
      />
    );
  }

  const renderGrid = () => {
    if (term) {
      return (
        <div className="gifresult">
          <p>Results for <u>{term}</u></p>
          {getGrid(getSearch)}
        </div>
      );
    }
    return (
      <>
        {getGrid(getTrending)}
      </>
    );
  }

  return (
    <>
      {renderGrid()}
      <ResizeObserver
        onResize={({ width }) => {
          setWidth(width);
        }}
      />
    </>
  );
}
