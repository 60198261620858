import React from "react";

function  Adobeedit(){
    return (
                <div className="adobeedittext">
                    <br/>
                    <h4>To edit this slide, follow these steps:</h4>
<ol>
    <li>Delete this slide.</li>
    <li>Click the "Adobe Express" button.</li>
    <li>Click the "Create images or slides in Adobe Express" button.</li>
    <li>Navigate to "Your stuff" folder in Adobe Express.</li>
    <li>Locate your file.</li>
    <li>Edit the file.</li>
    <li>Save your changes.</li>
    <li>Submit it again.</li>
</ol>
                </div>

    )
}

export default Adobeedit;

