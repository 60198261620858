import React, { useState } from "react"
import Folder from "./Folder"
import Grid from "@material-ui/core/Grid";
import Add from "./icons/AddSub-FolderSmall.svg";
import {Button} from 'react-bootstrap';

export default function Subfolders({
  folder,
  changeFolderName,
  deleteFolder,
  toggleSelect,
  selectedIds,
  createSubfolder,
  folders,
    handleDelete,
                                     onIdClick
}) {

  const [subfolderName, setSubfolderName] = useState('');
  const [openthis, setOpenthis] = useState(true)
  function handleSubNameChange(event) {
    setSubfolderName(event.target.value)
  }
  function handleAddClick() {
    if (subfolderName === '') {
      return
    }
    createSubfolder(subfolderName)
    setSubfolderName('');
    setOpenthis(true)
  }
  const folderValues = Object.values(folders)
  const subFolders = folderValues.filter(otherFolder => {
    return otherFolder.parentId === folder.id
  })
  const subFolderViews = subFolders.map(subfolder => {
    return (
      <Folder
        key={subfolder.id}
        folder={subfolder}
        changeFolderName={changeFolderName}
        deleteFolder={deleteFolder}
        toggleSelect={toggleSelect}
        selectedIds={selectedIds}
        createSubfolder={createSubfolder}
        folders={folders}
        subfolder
        handleDelete={handleDelete}
        onIdClick={onIdClick}
      />
    )
  })
  const openform = () =>{
    setOpenthis(false)
  }
  return (
    <>
      <div className="subfoldersviewcss">
      {subFolderViews}
      </div>
      <div>
        <br/>
        {openthis ?
            <img src={Add} onClick={openform} width="50px" alt="Add subfolder"/>
            :
            <>
              <Grid container spacing={0}>
                <Grid item xs={8} className="rightside">
                  <input value={subfolderName} placeholder="New sub-folder name" onChange={handleSubNameChange} className="dasheditroomnamefoder"/>
                </Grid>
                <Grid item xs={4} className="leftside">
                  <Button onClick={handleAddClick} className="foldersavechanges"> Add Sub-Folder </Button>
                </Grid>
              </Grid>
            </>
        }
      </div>
      <br/>
    </>
  )
}
