import React, { useState} from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './logo.png';
import "./css/Navbar.css"

function Navbarconvoze() {
    return (
        <>
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="https://reachum.com"><img src={logo} alt="Reachum" className="logos"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav activeKey="https://demo.reachum.com" className="navmenus">
                        <Nav className="me-auto">
                            <Nav.Link href="/convozedashboard"><p className="navthissub">Dashboard</p></Nav.Link>
                            <Nav.Link href="/profile"><p className="navthissub">My Account</p></Nav.Link>
                            <Nav.Link href="/pricing"><p className="redthissub">Subscribe</p></Nav.Link>
                        </Nav>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
    );
}

export default Navbarconvoze;
