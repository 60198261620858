import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {getDesmosfillin, getPlayslide, listLeaderboards} from '../../../../graphql/queries';
import {useHistory, useParams} from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {Button} from "react-bootstrap";
import "../../../css/poll.css";
import {createLeaderboard, updateLeaderboard, createEngagement} from "../../../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {Form, Input} from "element-react";
import Mathfield from "../../../mathslide/Mathfield";


function Mathfasteranswerroom() {
    const [isOpen, setIsOpen] = useState(true);
    const [isShows, setIsShows] = useState(true);
    const [quest, setQuest] = useState();
    const [messages, setMessages] = useState();
    const [isOwner, setIsOwner] = useState(false);
    const [isemail, setIsemail] = useState(false);
    const [ans, setAns] = useState([]);
    const [mathdata, setMathdata] = useState("");
    const [mathopen, setMathopen] = useState(false)
    const { id, rid } = useParams();
    const [counter, setCounter] = useState();
    const [playpause, setPlaypause] = useState(true);
    const [timeval, setTimeval] = useState(0);
    const [thissaved, setThissaved] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    })
    const [mathInput, setMathInput] = useState('');
    const [thisimage, setThisimage] = useState("")
    let history = useHistory();
    useEffect( () => {
        fetchDas();
        Auth.currentAuthenticatedUser()
            .then(user => {
                const thisuser = user.attributes.email;
                setIsemail(user.attributes.email);
                (async () => {
                    const response = await API.graphql({
                        query: getPlayslide,
                        variables: {id}
                    })
                    const datas = response.data.getPlayslide;
                    const iowner = datas.owner;
                    setIsOwner(iowner);
                    if (thisuser === iowner) {
                        setIsOpen(true)
                    }else{
                        const reachumnname = localStorage.getItem("vreachumnewername");
                        if(!reachumnname){
                            setIsOpen(false)
                        }
                    }
                })()
            })
        const submitrids = sessionStorage.getItem("submitrid");
        if(submitrids){
            setThissaved(submitrids)
            if(submitrids.includes(rid)){
                setDisabled(true);
            }
        }
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getDesmosfillin,
                variables: {id:rid}
            })
            const datas = response.data.getDesmosfillin;
            const timers = datas.ftimer;
            setCounter(timers)
            const ques = datas.fquestion;
            setQuest(ques);
            const ma = datas.mathstate;
            if(ma !== null) {
                setMathdata(ma);
                setMathopen(true)
            }
            const mmage = datas.image;
            if(mmage !== null) {
                setThisimage(mmage);
            }
            const tests = datas.fanswer;
            setAns(tests);
        }catch(err){
            console.log(err)
        }
    }
    function handleMathInputChange (value) {
        setMathInput(value);
    }
    const psubmit = async () => {
        let tt = [];
        if (thissaved){
            tt = [thissaved, rid]
        }else{
            tt = [rid]
        }
        sessionStorage.setItem("submitrid", tt);
        setPlaypause(false);
        let mymeetnames = localStorage.getItem("vreachumnewername");
        let mymeetemails = localStorage.getItem("vreachumneweremail");
        if (mathInput === ans) {
                    try {
                        API.graphql({
                            query: listLeaderboards,
                            variables: {
                                filter: {
                                    "meetemail": {
                                        eq: mymeetemails
                                    },
                                    "answerid":{
                                        eq: id
                                    }
                                },
                                limit:10000
                            }
                        }).then(datas => {
                            const plays = datas.data.listLeaderboards.items;
                            const playsl = plays.length;
                            if(playsl > 0) {
                                const myscore = plays[0].score;
                                const myid = plays[0].id;
                                const myscores = timeval + myscore;
                                try {
                                    const input = {
                                        id: myid,
                                        score: myscores,
                                        thisgamescore: timeval
                                    }
                                    API.graphql({
                                        query: updateLeaderboard,
                                        variables: {input}
                                    });
                                } catch (err) {
                                    console.log(err)
                                }
                            }else{
                                try {
                                    const input = {
                                        answerid: id,
                                        name: mymeetnames,
                                        meetemail:mymeetemails,
                                        score: timeval,
                                        thisgamescore: timeval
                                    }
                                    API.graphql({
                                        query: createLeaderboard,
                                        variables: {input}
                                    });
                                } catch (err) {
                                    console.log(err)
                                }
                            }
                            setMessages(`You scored ${timeval} points.`)
                        })
                    } catch (err) {
                        console.log(err)
                    }
                }
             else {
                    try {
                        API.graphql({
                            query: listLeaderboards,
                            variables: {
                                filter: {
                                    "meetemail": {
                                        eq: mymeetemails
                                    },
                                    "answerid":{
                                        eq: id
                                    }
                                },
                                limit:10000
                            }
                        }).then(datas => {
                            const plays = datas.data.listLeaderboards.items;
                            const playsl = plays.length;
                            if (playsl === 0) {
                                try {
                                    const input = {
                                        answerid: id,
                                        name: mymeetnames,
                                        meetemail:mymeetemails,
                                        score: 0,
                                        thisgamescore:0
                                    }
                                    API.graphql({
                                        query: createLeaderboard,
                                        variables: {input}
                                    });

                                } catch (err) {
                                    console.log(err)
                                }
                            }else{
                                const myid = plays[0].id;
                                try {
                                    const input = {
                                        id: myid,
                                        thisgamescore:0
                                    }
                                    API.graphql({
                                        query: updateLeaderboard,
                                        variables: {input}
                                    });
                                } catch (err) {
                                    console.log(err)
                                }
                            }
                        })
                    } catch (err) {
                        console.log(err)
                    }
                setMessages(`Sorry. You scored 0 point`)
            }
       setIsShows(false);
        try {
            const input = {
                questionid: id,
                engage: 1,
                people: 1
            }
            API.graphql({
                query: createEngagement,
                variables: {input}
            });
        } catch (err) {
            console.log(err)
        }
    };

    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setTimeval(0)
            return <div className="timer">0</div>;
        }else{
            setTimeval(remainingTime)
        }
        return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
        );
    }

    const handleSubmit2 = async event => {
        event.preventDefault();
        try {
            const ssname = stateData.meetname;
            const ssemail = stateData.meetemail;
            localStorage.setItem("vreachumnewername", ssname);
            localStorage.setItem("vreachumneweremail", ssemail);
            setIsOpen(true)

        }catch(err){
            console.log(err)
        }
    }
    const getdatas = async () =>{
        const mymeetnams = localStorage.getItem("vreachumnewername");
        const mymeetemals = localStorage.getItem("vreachumneweremail");
        if(mymeetemals) {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "meetemail": {
                                eq: mymeetemals
                            },
                            "answerid": {
                                eq: id
                            }
                        },
                        limit:10000
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl === 0) {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnams,
                                meetemail: mymeetemals,
                                score: 0,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }else{
                        const myid = plays[0].id;
                        try {
                            const input = {
                                id: myid,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                    setMessages("😳 The quiz timed out. You scored 0 point")
                    setIsShows(false);
                    try {
                        const input = {
                            questionid: id,
                            engage: 0,
                            people: 1
                        }
                        API.graphql({
                            query: createEngagement,
                            variables: {input}
                        });
                    } catch (err) {
                        console.log(err)
                    }
                })
            } catch (err) {
                console.log(err)
            }
        }else{
            setMessages("Time is up.")
            setIsShows(false);
        }
    }
    function thisprivacy(){
        history.push("/termandConditions");
    }
    return (
            <div className="fastanswerspage2">
                {isOpen ?
                    <>
                        <div className="greenback">
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                        <div className="lpollheadclock2">
                                <CountdownCircleTimer
                                    isPlaying={playpause}
                                    duration={counter}
                                    size={50}
                                    colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                    colorsTime={[7, 5, 2, 0]}
                                    onComplete={getdatas}
                                >
                                    {renderTime}
                                </CountdownCircleTimer>
                        </div>
                     </Grid>
                             <Grid item xs={10}>
                            <div className="fastanswes">
                        <h4>{quest}</h4>
                            </div>
                     </Grid>
                        </Grid>
                        </div>
                        <div className="centerside">
                            <br/>
                            <img src={thisimage} className="pximages400" />
                            <br/>
                            <br/>
                            {mathopen ?
                                <img src={mathdata} className="fillinthimgshow"/>
                                : ""
                            }
                        </div>
                         {isShows ?
                             <>
                                 <div className="cccenter">
                                     <br/>
                                     <p>Your answer:</p>

                                     <div className="mathinputstyle">
                                     <Mathfield value={mathInput} onChange={handleMathInputChange}/>
                                 </div>
                                     <br/>
                                 </div>
<br/>
                                     <Grid container spacing={0}>
                                         <Grid item xs={5}>
                                         </Grid>
                                         <Grid item xs={7}>
                                             <Button id="pollsubmit" onClick={psubmit}
                                                     className="inputpolllist" disabled={isDisabled}>Submit</Button>
                                         </Grid>
                                     </Grid>
                             </>
                             :
                             <div className="fastanswers">
                                 <h4 className="pollmaeeage">{messages}</h4>
                             </div>
                         }
                         <br/>
                         <br/>
                         <br/>
                         <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                          </> :
                <>
                    <div className="enteryournameform">
                        <Form className="market-header">
                            <h5> Create Display Name</h5>
                            <Form.Item>
                                <Input
                                    type="text"
                                    value={stateData.meetname}
                                    onChange={meetname => setStateData({
                                        ...stateData,
                                        meetname: meetname
                                    })}
                                    maxLength={80}
                                    placeholder="Username"
                                    className="craeteroominput"
                                />
                            </Form.Item>
                            <Form.Item>
                                <h5> Enter Email</h5>
                                <Input
                                    type="email"
                                    value={stateData.meetemail}
                                    onChange={meetemail => setStateData({
                                        ...stateData,
                                        meetemail: meetemail
                                    })}
                                    maxLength={100}
                                    placeholder="Your Email"
                                    className="craeteroominput"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={6} className="rightside">
                                        <Button
                                            disabled={!stateData.meetemail || !stateData.meetname}
                                            onClick={handleSubmit2}
                                            size="lg"
                                            className="ibuttns"
                                        >
                                            Enter
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form.Item>
                            <p className="privacytext">
                                We respect your privacy. Any personal data you provide to REACHUM in our meeting platform
                                will only be stored for game platform and progress tracking purposes. REACHUM does not share information
                                with third parties. Read our full privacy policy <span className="privacytextspan" onClick={thisprivacy}>here</span>.</p>
                        </Form>
                    </div>
                </>
                }
            </div>
    )
}

export default Mathfasteranswerroom;

