import React  from "react";
import  Draws  from "./draw";


function Realtimewhiteboard() {

	return (
				<>
					<Draws />
				</>
	);
}
export default Realtimewhiteboard;
