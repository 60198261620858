
export default function Item ({ answer, item, active, setActive }) {
  const image = item.image && item.image !== '' && (
    <img src={item.image} alt={item.text} width='220' />
  )
  function handleClick () {
    setActive(item);
  }
  const activated = active?.text === item.text
  const activeStyles = activated ? { background: '#72B5A4', color:"#ffffff", transform:"scale(1.5, 1.5)"} : {}
    const correctIcon = answer === true && (<>✔</>)
    const incorrectIcon = answer === false && (<>✘</>)
  return (
   <div>
     <div onClick={handleClick} style={{ padding: '5px 8px', margin: '5px', textAlign:"center", borderRadius:"8px", ...activeStyles }}>
         {correctIcon}
         {incorrectIcon}
        <span>{item.text}</span>
         <br/>
        {image}
      </div>
    </div>
  )
}
