import React from "react";
import "./style.css";
import { Excalidraw } from "@excalidraw/excalidraw";

function Draws() {
    return (
        <>
            <div className="iroomedit" style={{ height: "100vh" }}>
                <Excalidraw />
            </div>
        </>
    );
}

export default Draws
