import React, {Suspense, useEffect, useState} from "react";
import { API } from 'aws-amplify';
import {getPlayslide} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import {Image} from "react-bootstrap";
import "../../css/imgshowroom.css";
function Mp3room() {
    const [jvfile, setVjfile] = useState();
    const [itt, setItt] = useState("");
    const { id, rid } = useParams();
    const { imgurls, setImgurls } = useState("");
    const audioRef = React.useRef(null);
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getPlayslide,
            variables: {id}
        });
        const datas = response.data.getPlayslide.playlistlinks;
        const newdatas = datas.filter(data => data.rid === rid);
        const iurl  = newdatas[0].lurl;
        console.log("newdatas", iurl)

       /* const vbucket = newdatas[0].file.bucket;
        const vkey = newdatas[0].file.key;
        const rurls = `https://${vbucket}.s3.amazonaws.com/public/${vkey}`;
        setVjfile(rurls);
        console.log(rurls)*/
    }

    return (
        <>
            <div>
                <p>{imgurls} """"</p>
            </div>
        </>
    );
}

export default Mp3room;
