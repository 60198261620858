import React, { useState, useRef, useEffect } from 'react';
import {API, Auth} from 'aws-amplify';
import {useParams,useHistory} from "react-router-dom";
import DesmosCalculator from "./desmosCalculator";
import {
    createDesmomath,
    createDesmomathslide,
    createDesmosfillin,
    updateDesmomath,
    updatePlayslide
} from "../../graphql/mutations";
import Resizer from "react-image-file-resizer";
import {getPlayslide} from "../../graphql/queries";
import {v4 as uuid} from "uuid";
import {Helmet} from "react-helmet";
import Navbars from "../Navbar";
import Leftarrow from "../../img/left-arrow.png";
import MathJax from "react-mathjax";
import Mathfield from "./Mathfield";
import {Button} from 'react-bootstrap';
import Grid from "@material-ui/core/Grid";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            960,
            540,
            "JPEG",
            85,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
const resizeFile1 = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            400,
            400,
            "JPEG",
            85,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
const  Createmathslide = ()  =>{
    const { id } = useParams();
    const addRef = useRef(null);
    const history = useHistory();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [images, setImages] = useState([]);
    const [selectedFile, setSelectedFile] = useState("");
    const [mathInput, setMathInput] = useState('')
    const d = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    function handleMathInputChange (value) {
        setMathInput(value);
    }
    const handleFileChange = async (event) => {
        const img = event.target.files[0];
        const resized = await resizeFile1(img);
        setSelectedFile(resized)
    };
    const reorderurl = `https://demo.reachum.com/mathslideshow/${id}/${d}`;
    const erurl = `https://demo.reachum.com/editmathslide/${id}/${d}`;

 async function savethis(){
     const calculatorState = addRef.current.getState()
     const latexExpressions = calculatorState.expressions.list.filter(expression => {
         return expression.latex != null
     })
     let resized;
     if (latexExpressions.length === 0) {
         resized = "";
     } else {
         const screenshot = addRef.current.screenshot()
         const response = await fetch(screenshot)
         const blob = await response.blob()
         resized = await resizeFile(blob)
     }
     try {
         const input = {
             id:d,
             mathstate:mathInput,
             graphic:resized,
             image:selectedFile
         }
         await API.graphql({
             query: createDesmomathslide,
             variables: {input}
         });
         const urldata = {
             rid:d,
             title:"math slide",
             rurl: reorderurl,
             eurl:erurl
         };
         const allImages = [...images, urldata]
         const inputs = {
             id,
             playlistlinks: allImages
         }
         await API.graphql({
             query: updatePlayslide,
             variables: {input:inputs}
         })
         setTimeout(() => {
             history.push(`/editroom/${id}`);
         }, 500)
     } catch (err) {
         console.log(err)
     }
}
    function scp(){
        history.push(`/editroom/${id}`);
    }
    return (
        <>
            {isOwner === isUser ?
            <>
                <Helmet>
                    <title>Math slide</title>
                </Helmet>
                <Navbars />
                <div className="slidepage">
                    <br/>
                    <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
                    <br/>
                    <div className="thiscenter">
                        <h3>Math slide</h3>
                    </div>
                </div>
                <br/>
            <div className="mathslidepage">
                <Grid container spacing={0}>
                    <Grid item xs={3} className="rightside">
                        <p>Upload image: (option) &nbsp;</p>
                    </Grid>
                    <Grid item xs={9} className="leftside">
                        <input type="file" onChange={handleFileChange} />
                    </Grid>
                </Grid>
                <br/>
                <div className="mathcccenter">
                <img src={selectedFile} className="pximages400" />
                </div>
                <Grid container spacing={0}>
                    <Grid item xs={3} className="rightside">
                        <p>Type math formulas: (option) &nbsp;</p>
                    </Grid>
                    <Grid item xs={9} className="leftside">
                        <div className="pxsize500">
                            <Mathfield value={mathInput} onChange={handleMathInputChange} />
                        </div>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <p>Graphing Calculator: (option) <span className="redthis">( Once submitted, you won't be able to make changes to the graphics.) </span></p>
                <DesmosCalculator ref={addRef} />
                <br/>
                <Button onClick={savethis}>Submit</Button>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>

              </>
                    :""
            }
            </>
    );
}

export default Createmathslide;
