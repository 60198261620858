import React, {useEffect,useState} from "react";
import { API} from 'aws-amplify';
import {useParams} from "react-router-dom";
import {getOptionsdrawlist} from '../../../graphql/queries';
import "../../css/correctanswerpage.css";

function Drawcorrctanswer() {
    const [thisqq, setThisqq] = useState("");
    const [thisaa, setThisaa] = useState("");
    const [thisimg, setThisimg] = useState("");
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getOptionsdrawlist,
            variables:{id:rid}
        })
        const datas = response.data.getOptionsdrawlist;
       const qq = datas.pquestion;
        const imgqq = datas.drawques;
        const tests = datas.ptest;
       const aa = datas.oanswer;
       const yy = tests[aa].panswer;
       setThisqq(qq);
       setThisaa(yy);
        setThisimg(imgqq)
    }

    return (
            <div className="correctanswerpage">
            <div className="corrctcenterthis">
                <h3>{thisqq}</h3>
                <img src={thisimg} className="drawwidth" />
                <p>The correct answer is: </p>
                <h3 className="redthis">{thisaa}</h3>
            </div>
            </div>
    );
}

export default Drawcorrctanswer;
