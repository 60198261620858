import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {getPlayslide, listLeaderboards} from '../../../graphql/queries';
import {Table} from 'react-bootstrap';
import "../../css/leaderboard.css";
import Deleteroom from "../../../img/dash/Delete-Button-X.png";
import {deleteLeaderboard} from "../../../graphql/mutations";

function Hostleaderboard(props) {
    const  id  = props.data;
    const [myData, setMyData] = useState([]);
    const [isOwner, setIsOwner] = useState("");
    const [isUser, setIsUser] = useState("");
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
        fetchDas();
    }, []);

    async function fetchDas() {
        const scounts = [];
        const dcounts=[];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "roomid": {
                            eq: id
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            datas.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            setMyData(dcounts)
        }catch(err){
            console.log(err)
        }
    }
    const handleDeleteperson = async (itemId) => {
        let text = "Are you sure you want to delete this player? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            const input = {
                id: itemId
            };
            await API.graphql({
                query: deleteLeaderboard,
                variables: {input}
            });
            window.location.reload();
        }
    };
    const renderleaderboard =()=>{
        return myData.map((item, index) => (
            isOwner === isUser ?
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.meetemail}</td>
                <td>{item.score}</td>
                <td><img src={Deleteroom} onClick={()=> {handleDeleteperson(item.id)}} className="dropbtn" alt="delete a person"/></td>
            </tr>:""
        ))
    }


    return (
        <>
                        <div className="leaderpage">
                                <Table striped bordered hover size="sm">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Player Name</th>
                                        <th>Player Email</th>
                                        <th>Total Score</th>
                                        <th>Delete</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderleaderboard()}
                                    </tbody>
                                </Table>
                        </div>
                    </>
    )
}

export default Hostleaderboard;
