import React from "react";
import Chatbot from "react-chatbot-kit";


function Chat() {
    return (
        <div className="App">

        </div>
    );
}

export default Chat;
