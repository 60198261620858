import React from "react";
import {
    Authenticator,
    useAuthenticator,
    useTheme,
    Button,
    View
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import "./css/login.css";
import {useHistory} from "react-router-dom";


const Login = () => {
    let history = useHistory();
    return (
        <>
            <Authenticator hideSignUp={true}>
            </Authenticator>
        </>
    )
}

export default Login
