import React, {useEffect,useState} from "react";
import { API, graphqlOperation } from 'aws-amplify';
import {getPlayslide} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Ratio} from "react-bootstrap";
import ReactPlayer from "react-player/lazy";

function Liveshowroom() {
    const [lvfile, setLvfile] = useState();
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = response.data.getPlayslide.playlistlinks;
        const newdatas = datas.filter(data => data.rid === rid);
        const vbucket = newdatas[0].lurl;
        setLvfile(vbucket);
    }

    return (
        <>
            <div className="vidshows">
                <Ratio aspectRatio="16x9">
                    <div className='player-wrapper'>
                        <ReactPlayer
                            url={lvfile}
                            playing="true"
                            playsinline="false"
                            width='100%'
                            height='100%'
                        />
                    </div>
                </Ratio>
            </div>
        </>
    );
}

export default Liveshowroom;
