import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import GiphyGrid from './giphyGrid';
import SearchInput from './SearchInput';
import GifModal from './gifModal';
import {useParams} from "react-router-dom";
import Navbars from "../Navbar";
import Foot from "../Foot";
import {API, Auth} from "aws-amplify";
import {getPlayslide} from "../../graphql/queries";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'normal',
    overflow: 'scroll',
  },
  content: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: "100%",
    height: "100vh",
      backgroundColor:"#ffffff"
  },
}));

const Giphy = ()  => {
    const classes = useStyles();
    const [modalGif, setModalGif] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const { id } = useParams();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);
    const doSearch = (action) => {
      console.log("doSearch...", action);
      setSearchTerm(action);
    }

    const clearSearch = () => {
      console.log("clearSearch...");
      setSearchTerm('');
    }

    const closeModal = () => {
      setModalGif(null);
    }

    return(
        isOwner === isUser ?
            <>
                <Helmet>
                    <title>GIPHY</title>
                </Helmet>
                <Navbars />
      <div className={classes.root}>
        <div className={classes.content}>

          <SearchInput onSearch={doSearch} onClear={clearSearch} />

          <GiphyGrid
            searchTerm={searchTerm}
            onGifClick={(gif, e) => {
              e.preventDefault();
              setModalGif(gif);
            }}
          />
          {modalGif && <GifModal gif={modalGif} onClose={closeModal}/> }
        </div>
      </div>
                <Foot />
            </>
            :""
    )
}

export default Giphy;
