import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getPlayslide, listLeaderboards, listEngagements} from '../../../graphql/queries';
import "../../css/leaderboard.css";
import {deleteLeaderboard, deleteEngagement} from "../../../graphql/mutations";
var XLSX = require("xlsx");

function Cdleaderboard(props) {
    const  id  = props.data;
    const [myData, setMyData] = useState([]);
    const [roomname, setRoomname] = useState("");
    const [thisdatas, setThisdatas] = useState([]);
    useEffect(() => {
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname)
        })()
        fetchDas();
    }, []);

    async function fetchDas() {
        const scounts = [];
        const dcounts=[];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "answerid": {
                            eq: id
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            setThisdatas(datas);
            datas.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            setMyData(dcounts)
        }catch(err){
            console.log(err)
        }
    }
    const convertToCSV = (array) => {
        const header = Object.keys(array[0]).join(',');
        const rows = array.map(obj => Object.values(obj).join(','));
        return [header, ...rows].join('\n');
    };

    const downloadFile = () => {
        const mynewdata = myData.map(row => ({
            name: row.name,
            email: row.meetemail,
            score: row.score
        }));
        const csvContent = convertToCSV(mynewdata);
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${roomname} - leaderboard.csv`;
        link.click();
    };

    const clearresults = async ()=>{
        let text = "Are you sure you want to delete all players? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                let cl = thisdatas.length;
                for (let i = 0; i < cl; i++) {
                    const input = {
                        id: thisdatas[i].id
                    };
                    await API.graphql({
                        query: deleteLeaderboard,
                        variables: {input}
                    });
                }
            } catch (err) {
                console.log(err)
            }
            try {
                const response = await API.graphql({
                    query: listEngagements,
                    variables: {
                        filter: {
                            "questionid": {
                                eq: id
                            }
                        },
                        limit:10000
                    }
                })
                const datas = response.data.listEngagements.items;
                const datalength = datas.length;
                for(var j=0; j<datalength; j++) {
                    const input = {
                        id: datas[j].id
                    };
                    await API.graphql({
                        query: deleteEngagement,
                        variables: {input}
                    });
                }
            }catch(err){
                console.log(err)
            }
            window.location.reload();
        }
    }
    return (
        <>
                        <div className="leaderpage">
                                <div>
                                    <button onClick={downloadFile} className="savespread">Save as CSV File</button>
                                    <br/>
                                    <br/>
                                    <button onClick={clearresults} className="clearresub">Clear Results</button>
                                </div>
                        </div>
                    </>
    )
}

export default Cdleaderboard;
