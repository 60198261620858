import React, {useEffect, useState} from "react";
import { API} from 'aws-amplify';
import {listLeaderboards} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Table} from 'react-bootstrap';
import "../css/leaderboard.css";
import {Helmet} from "react-helmet";

function Leaderboard() {
    const [myData, setMyData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetchDas();
    }, []);
    async function fetchDas() {
        const scounts = [];
        const dcounts=[];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "answerid": {
                            eq: id
                        }
                    },
                    limit:10000
                    }
            })
            const datas = response.data.listLeaderboards.items;
            datas.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            setMyData(dcounts)
        }catch(err){
            console.log(err)
        }
    }
    const renderleaderboard =()=>{
        return myData.map((item, index) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.thisgamescore}</td>
                <td>{item.score}</td>
            </tr>
        ))
    }
    return (
        <>
        <Helmet>
            <title>Leaderboard</title>
        </Helmet>
        <>
            <div className="leaderpageview">
                <div className="viewerleaderpageview">
            <div className="faviewleader">
                <h3 className="leadhead">Leaderboard</h3>
                <Table bordered size="sm">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Player</th>
                        <th>Last Game Score</th>
                        <th>Total Score</th>
                    </tr>
                    </thead>
                    <tbody className="leadertable">
                    {renderleaderboard()}
                    </tbody>
                </Table>
            </div>
                </div>
            </div>
        </>
            </>
    )
}

export default Leaderboard;
