import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {createFlipcard, updatePlayslide} from '../../../graphql/mutations';
import {Form,Table} from "react-bootstrap";
import {getPlayslide} from "../../../graphql/queries";
import Navbars from "../../Navbar";
import Foot from "../../Foot";
import {useParams,useHistory} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../../css/poll.css";
import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Leftarrow from "../../../img/left-arrow.png";

function Flipcards() {
	const { id } = useParams();
	const history = useHistory();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [fname, setFname] = useState('')
	const [ftimer, setFtimer] = useState('')
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, [id]);

	const reorderurl = `https://demo.reachum.com/flipshowroom/${id}/${d}`;
	const erurl = `https://demo.reachum.com/flipcardsedit/${id}/${d}`;
	const ccurl = `https://demo.reachum.com/flipcardcorrctanswer/${id}/${d}`;
	const ileaderboardurl = `https://demo.reachum.com/leaderboard/${id}`;
	const tt = `${d}1`;
	const ccu = `${d}333`;
	async function handleSubmit (event){
		event.preventDefault()
		const card1 = { type: type1, text: text1 }
		const card2 = { type: type2, text: text2 }
		const card3 = { type: type3, text: text3 }
		const card4 = { type: type4, text: text4 }
		const card5 = { type: type5, text: text5 }
		const card6 = { type: type6, text: text6 }
		const newCards = [card1, card2, card3, card4, card5, card6]
		try {
			const input = {
				id:d,
				pfname:fname,
				ptimer:ftimer,
				fwords:newCards
			}
			await API.graphql({
				query: createFlipcard,
				variables: {input}
			});
			const urldata = {
				rid:d,
				title:fname,
				rurl: reorderurl,
				eurl: erurl
			};
			const leaderboard = {
				rid:tt,
				title:"leaderboard",
				rurl:ileaderboardurl
			}
			const ccorect = {
				rid:ccu,
				title:"correct answer",
				rurl:ccurl
			}
			const allImages = [...images, urldata, ccorect, leaderboard]
			const inputs = {
				id,
				playlistlinks: allImages
			}
			await API.graphql({
				query: updatePlayslide,
				variables: {input:inputs}
			})
			setTimeout(() => {
				setUploadedmessage("The file added to the content list.")
				history.push(`/editroom/${id}`);
			}, 1000)
		} catch (err) {
			console.log(err)
		}
	}
	function changedname(event){
		setFname(event.target.value)
	}
	function changedtimer(event){
		setFtimer(event.target.value)
	}
	const [type1, setType1] = useState('')
	function changeType1 (event) {
		setType1(event.target.value)
	}
	const [text1, setText1] = useState('')
	function changeText1 (event) {
		setText1(event.target.value)
	}
	const [type2, setType2] = useState('')
	function changeType2 (event) {
		setType2(event.target.value)
	}
	const [text2, setText2] = useState('')
	function changeText2 (event) {
		setText2(event.target.value)
	}
	const [type3, setType3] = useState('')
	function changeType3 (event) {
		setType3(event.target.value)
	}
	const [text3, setText3] = useState('')
	function changeText3 (event) {
		setText3(event.target.value)
	}
	const [type4, setType4] = useState('')
	function changeType4 (event) {
		setType4(event.target.value)
	}
	const [text4, setText4] = useState('')
	function changeText4 (event) {
		setText4(event.target.value)
	}
	const [type5, setType5] = useState('')
	function changeType5 (event) {
		setType5(event.target.value)
	}
	const [text5, setText5] = useState('')
	function changeText5 (event) {
		setText5(event.target.value)
	}
	const [type6, setType6] = useState('')
	function changeType6 (event) {
		setType6(event.target.value)
	}
	const [text6, setText6] = useState('')
	function changeText6 (event) {
		setText6(event.target.value)
	}
	function scp(){
		history.push(`/editroom/${id}`);
	}
	return (
		isOwner === isUser ?
			<>
				<Helmet>
					<title>Memory matching game</title>
				</Helmet>
				<Navbars />
				<div className="flipcardscreatepage">
					<h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
					<div className="thiscenter">
						<h3>Memory Matching Game - Flipping Card</h3>
					</div>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={0}>
								<Grid item xs={2}>
							Game name : &nbsp;
								</Grid>
								<Grid item xs={10}>
								<input type="text" value={fname} onChange={changedname} maxLength="200" className="ques" />
								</Grid>
							</Grid>
						<br/>
							<Grid container spacing={0}>
								<Grid item xs={2}>
							Timer (Seconds) : &nbsp;
								</Grid>
								<Grid item xs={10}>
								<input
									value={ftimer}
									type="number"
									onChange={changedtimer}
									className="dragdroptimer"
									maxLength="3"
								/>
								</Grid>
							</Grid>
							<br/>
							<p>Match a word/text to another word/text (limit 30)</p>
							<Form.Group className="mb-3" controlId="word1">
									<ol>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
											<input value={type1} type="text" onChange={changeType1} maxLength="50" className="finput"/>
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text1} type="text" onChange={changeText1} maxLength="50" className="finput"/>
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
											<Grid item xs={5}>
											<input value={type2} type="text" onChange={changeType2} maxLength="50" className="finput" />
											</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text2} type="text" onChange={changeText2} maxLength="50" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
											<input value={type3} type="text" onChange={changeType3} maxLength="50" className="finput" />
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text3} type="text" onChange={changeText3} maxLength="50" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
											<input value={type4} type="text" onChange={changeType4} maxLength="50" className="finput" />
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text4} type="text" onChange={changeText4} maxLength="50" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
													<input value={type5} type="text" onChange={changeType5} maxLength="50" className="finput" />
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
													<input value={text5} type="text" onChange={changeText5} maxLength="50" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
													<input value={type6} type="text" onChange={changeType6} maxLength="50" className="finput" />
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
													<input value={text6} type="text" onChange={changeText6} maxLength="50" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
									</ol>
							</Form.Group>
							<Table>
								<tr>
									<td>
									</td>
									<td className="orderright">
										{uploadedmessage} <input type="submit" value="Submit" className="submitcolor"/>
									</td>
								</tr>
							</Table>
					</form>
				</div>
				<Foot />
			</>
			:""
	);
}
export default Flipcards;
