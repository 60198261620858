import React, {useEffect, useState} from 'react'
import CategoryItem from './categoryItem'
import {API, Auth} from "aws-amplify";
import {getDraganddrop} from "../../../graphql/queries";
import {updateDraganddrop} from "../../../graphql/mutations";
import {useHistory, useParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from '../../../img/dash/remove.png';


const placeholderCategories = [
  {
    name: '',
    items: [
      { text: '', image: ""}
    ]
  },
  {
    name: '',
    items: [
      { text: '', image: "" }
    ]
  }
]


export default function Categorytaptapedit () {
  const [error, setError] = useState();
  const { id, rid } = useParams();
  const history = useHistory();
  const [uploadedmessage, setUploadedmessage] = useState("");
  const [dname, setDname] = useState('')
  const [dtimer, setDtimer] = useState('')
  const [dquestion, setDquestion] = useState('')

  useEffect( () => {
    (async() => {
      const response = await API.graphql({
        query: getDraganddrop,
        variables:{id:rid}
      })
      const datas = response.data.getDraganddrop;
      const dn = datas.dname
      setDname(dn)
      const t = datas.dtimer
      setDtimer(t)
      const q = datas.dquestion
      setDquestion(q)
      const t1 = datas.categories;
      sessionStorage.setItem("categories",JSON.stringify(t1))
    })()
  }, []);
  const [categories, setCategories] = useState(() => {
    const storedCategories = sessionStorage.getItem('categories')
    if (storedCategories == null) {
      return placeholderCategories
    }
    return JSON.parse(storedCategories)
  })
  function changedname(event){
    setDname(event.target.value)
  }
  function changedtimer(event){
    setDtimer(event.target.value)
  }
  function changedquestion(event){
    setDquestion(event.target.value)
  }
  const categoryViews = categories.map((category, categoryIndex) => {
    function handleNameChange (event) {
      setCategories(categories.map((category, i) => {
        if (i === categoryIndex) {
          return {
            ...category,
            name: event.target.value
          }
        }
        return category
      }))
    }
    const itemViews = category.items.map((item, itemIndex) => {
      return (
        <CategoryItem
          key={itemIndex}
          item={item}
          categoryIndex={categoryIndex}
          itemIndex={itemIndex}
          categories={categories}
          setCategories={setCategories}
        />
      )
    })
    function handleAddItem () {
      setCategories(categories.map((category, i) => {
        if (i === categoryIndex) {
          return {
            ...category,
            items: [...category.items, { text: '', image: '' }]
          }
        }
        return category
      }))
    }
    function handleDeleteCategory () {
      setCategories(currentCategories => {
        return currentCategories.filter((currentCategory, currentCategoryIndex) => {
          if (currentCategoryIndex !== categoryIndex) return true
          return false
        })
      })
    }
    return (
      <div key={categoryIndex} className="itemswidth">
        <Grid container spacing={0}>
          <Grid item xs={9}>
        <input placeholder='Category name' value={category.name} onChange={handleNameChange} className="ques"/>
          </Grid>
          <Grid item xs={3} className="rightside">
            <img src={DeleteIcon} onClick={handleDeleteCategory} />
          </Grid>
        </Grid>
        <br/>
        <ol style={{marginLeft:"-30px"}}>{itemViews}</ol>
        <br/>
        <button onClick={handleAddItem}>Add Item</button>
      </div>
    )
  })
  function handlePlusCategory () {
    const category = {
      name: '',
      items: [{ text: '', image: '' }]
    }
    setCategories([...categories, category])
  }
  async function handleSave () {
    const invalidItem = categories.some(category => category.items.some(item => item.text == null || item.text === ''))
    if (invalidItem) {
      setError('Item text is required')
      return
    }
    const invalidCategory = categories.some(category => category.name == null || category.name === '')
    if (invalidCategory) {
      setError('Category name is required')
      return
    }
    setError(null)
    const ccc = categories;
    try {
      const input = {
        id: rid,
        dtimer: dtimer,
        dquestion: dquestion,
        categories: ccc
      }
      await API.graphql({
        query: updateDraganddrop,
        variables: {input}
      });
      setUploadedmessage("Updated!")
    } catch (err) {
      console.log(err)
    }

  }
  const errorView = error != null && (
    <div style={{ background: 'red', color: 'white'}}>
      {error}
    </div>
  )
  function scp(){
    history.push(`/editroom/${id}`);
  }
  return (
    <div style={{  width: '98%',margin: "10px auto",paddingBottom:"80px" }}>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
        Game Name: &nbsp;
        </Grid>
        <Grid item xs={10}>
          {dname}
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
         Timer (Seconds): &nbsp;
        </Grid>
        <Grid item xs={10}>
    <input
        value={dtimer}
        type="number"
        onChange={changedtimer}
        className="dragdroptimer"
        maxLength="3"
    />
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
       Question: &nbsp;
        </Grid>
        <Grid item xs={10}>
    <textarea type="text" value={dquestion} onChange={changedquestion} className="dragdropques" maxLength="150" />
        </Grid>
      </Grid>
      <br/>
      <div style={{ display: 'flex', width:"100%", flexWrap: 'wrap' }}>
        {categoryViews}
        <Button style={{ height: 'fit-content'}} onClick={handlePlusCategory} variant="success" size="xs">Add Category</Button>
      </div>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={7} className="rightside" style={{color:"red"}}>
      {errorView}{uploadedmessage}
        </Grid>
        <Grid item xs={1}>

        </Grid>
        <Grid item xs={4}>
      <Button onClick={handleSave} variant="success">Update</Button>
        </Grid>
      </Grid>
    </div>

  )
}
