import React, {useEffect, useState} from "react";
import "../../css/imgshowroom.css";

const Hotspot = ({ onClick, isHotspot }) => {
    // Apply different styles based on whether it's a hotspot or not
    const style = {
        width: '50px',
        height: '50px',
        backgroundColor: isHotspot ? 'red' : 'blue',
        margin: '5px',
        borderRadius: '50%',
        cursor: 'pointer',
    };

    return <div style={style} onClick={onClick}></div>;
};


function Pptxshowroom() {
    const [spots, setSpots] = useState(Array(25).fill(false));

    // Function to handle hotspot click
    const handleSpotClick = (index) => {
        // Copy the current state array
        const newSpots = [...spots];
        // Toggle the hotspot status of the clicked spot
        newSpots[index] = !newSpots[index];
        // Update the state
        setSpots(newSpots);
    };

    return (
        <>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '300px' }}>
                {spots.map((isHotspot, index) => (
                    <Hotspot
                        key={index}
                        isHotspot={isHotspot}
                        onClick={() => handleSpotClick(index)}
                    />
                ))}
            </div>
            </>
    );
}

export default Pptxshowroom;
