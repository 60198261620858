import React from "react";
import { Authenticator,Text, View,Image,Heading,useTheme,useAuthenticator,CheckboxField} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'
import "../../css/signup.css";
import Logo from "./signup.jpg";
import Signupright from "./signupright.jpg"
import {useHistory} from "react-router-dom";

const Presignup = () => {
    let history = useHistory();
    function gotosite(){
        history.push("/convozedashboard")
    }
    const formFields = {
        signUp: {
            email: {
                order:3,
                labelHidden: true,
                placeholder: 'Your Email'
            },
            given_name: {
                order: 1,
                labelHidden: true,
                placeholder: 'First Name'
            },
            family_name: {
                order: 2,
                labelHidden: true,
                placeholder: 'Last Name'
            },
            password: {
                order: 4
            },
            confirm_password: {
                order: 5
            },
        },
    }
    const components = {
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" paddingTop={tokens.space.large}>
                    <Image
                        alt="REACHUM logo"
                        src={Logo}
                        className="signuplogo"
                    />
                </View>
            );
        },

        Footer() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center">
                    <Text color={tokens.colors.neutral[80]}>
                        <p onClick={()=> history.push("/termandConditions")} className="smallsizefooter1">Terms & Conditions</p>
                        <p className="smallsizefooter">&copy; 2023 Click-Video LLC All rights reserved.<br/>
                            Patent No: 11757666</p>
                    </Text>
                </View>
            );
        },
        SignUp: {
            FormFields() {
                const { validationErrors } = useAuthenticator();
                return (
                    <>
                        <Authenticator.SignUp.FormFields />
                        <CheckboxField
                            errorMessage={validationErrors.acknowledgement}
                            hasError={!!validationErrors.acknowledgement}
                            name="acknowledgement"
                            value="yes"
                            label="I agree with the Terms & Conditions"
                        />
                    </>
                );
            },
            Footer() {
                return (
                    <View textAlign="center">

                            <p className="smallsize">Already have an account? <span onClick={gotosite} className="underlinetext">Log in here.</span></p>

                    </View>
                );
            },
        },
        ConfirmSignUp: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                    >
                        Confirmation Sent.
                    </Heading>
                );
            }
        },
    };
    const services={
        async validateCustomSignUp(formData) {
            if (!formData.acknowledgement) {
                return {
                    acknowledgement: 'You must agree to the Terms & Conditions',
                };
            }
        },
    }
    return (
        <>
            <div className="signuppage">
                <div className="signupleft">
            <Authenticator formFields={formFields} components={components} services={services} initialState="signUp">
            </Authenticator>
                </div>
                <div className="signupright1">
                <img src={Signupright} />
                </div>
            </div>
            </>
    )
}

export default Presignup
