import React from "react";
import {
    Authenticator,
    useAuthenticator,
    useTheme,
    Button,
    View, Image
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import "./../css/login.css";
import {useHistory} from "react-router-dom";
import Logo from "./convoze.jpg";


const Convozelogin = () => {
    let history = useHistory();
    const components = {
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" paddingTop={tokens.space.large}>
                    <Image
                        alt="Convoze logo"
                        src={Logo}
                    />
                </View>
            );
        },
        SignIn: {
            Footer() {
                const { toResetPassword } = useAuthenticator();
                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toResetPassword}
                            size="small"
                            variation="link"
                        >
                            Forgot your password?
                        </Button>
                        <br/>
                        <br/>
                    </View>
                );
            },
        }
    };
    return (
        <>
            <br/>
            <br/>
            <br/>
            <Authenticator components={components} hideSignUp={true}>
            </Authenticator>
        </>
    )
}

export default Convozelogin
