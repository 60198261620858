import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Image} from "react-bootstrap";
import QRCode from 'qrcode'
import "./css/qrshow.css";
import {Helmet} from "react-helmet";
import html2PDF from 'jspdf-html2canvas';

var opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    margin: 10,
    width:500
}

function QRcodepage() {
    const [jvfile, setVjfile] = useState();
    const { id } = useParams();
    QRCode.toDataURL(`https://demo.reachum.com/iview/${id}`, opts)
        .then(url => {
                setVjfile(url)
        })
        .catch(err => {
            console.error(err)
        })
    function saveqrcode(){
        let pages = document.getElementById('thiscontent');
        html2PDF(pages, {
            jsPDF: {
                format: 'a4',
            },
            imageType: 'image/jpeg',
            output: 'generate.pdf'
        });
    }
    return (
        <>
            <Helmet>
                <title>QR code</title>
            </Helmet>
            <button id="btn" onClick={saveqrcode}>Generate</button>
            <div className="qrshows" id="thiscontent">
                <Image src={jvfile} fluid className="imgviewsite"/>
            </div>
        </>
    );
}

export default QRcodepage;
