import React, {useEffect, useState, useRef} from "react";
import {API, Auth} from 'aws-amplify';
import {createTextslide, updatePlayslide} from '../../graphql/mutations';
import {getPlayslide} from "../../graphql/queries";
import Navbars from "../Navbar";
import Foot from "../Foot";
import {useParams,useHistory} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../css/slide.css";
import {Helmet} from "react-helmet";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import katex from 'katex'
import Leftarrow from "../../img/left-arrow.png";
import "./style.css";
import Resizer from "react-image-file-resizer";
const resizeFile = (file,width,height) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            "JPEG",
            85,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const buttonList = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['table', 'link', 'image', 'video', 'math'],
    [ 'showBlocks', 'codeView'],
    ['preview', 'print'],
    ['save']
]
function Textslide() {
    const { id } = useParams();
    const history = useHistory();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [images, setImages] = useState([]);
    const d = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            console.log("datas",datas)
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            console.log("images",images)
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);

    const reorderurl = `https://demo.reachum.com/newerteshowroom/${id}/${d}`;
    const erurl = `https://demo.reachum.com/newerteslideedit/${id}/${d}`;
   async function handleSave(content){
       const response = await API.graphql({
           query: getPlayslide,
           variables:{id}
       })
       const datas = response.data.getPlayslide;
       const images = datas.playlistlinks;
   try {
            const input = {
                id:d,
                bgcolor:content,
                pfname:"slide"
            }
       API.graphql({
                query: createTextslide,
                variables: {input}
            });
            const urldata = {
                rid: d,
                title: "slide",
                rurl: reorderurl,
                eurl: erurl
            };
            const allImages = [...images, urldata];
            const inputs = {
                id,
                playlistlinks: allImages
            }
           API.graphql({
                query: updatePlayslide,
                variables: {input:inputs}
            })
          setTimeout(() => {
                history.push(`/editroom/${id}`);
            }, 500)
        } catch (err) {
            console.log(err)
        }
    }

    const options = { buttonList, katex, callBackSave: handleSave }
    function scp(){
        history.push(`/editroom/${id}`);
    }
    async function handleImageUpload(target, index, state, imageInfo) {
        const response = await fetch(target.src);
        const blob = await response.blob()
        const resized = await resizeFile(blob, target.width, target.height)
        target.src = resized
    }
    return (
        isOwner === isUser ?
            <>
                <Helmet>
                    <title>Text slide</title>
                </Helmet>
                <Navbars />
                <div className="slidepage">
                    <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
                    <br/>
                    <div className="thiscenter">
                    <h3>Text slide</h3>
                    </div>
                    <div>
                            <SunEditor setOptions={options} placeholder="Please type here..." onImageUpload={handleImageUpload} height="540px" />
                    </div>
                </div>
                <Foot />
            </>
            :""
    );
}
export default Textslide;
