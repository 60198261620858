import {API, Auth} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide} from "../../graphql/queries";
import { updatePlayslide} from "../../graphql/mutations";
import {useParams,useHistory} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import Grid from '@material-ui/core/Grid';
import Right from "../../img/host/next-btn.png";
import Left from "../../img/host/back-btn.png";
import "../css/mroom.css";
import Timeron from "../../img/host/Timer.png";
import Noi from "../../img/no.png";
import Lists from "../../img/host/List.png";
import Modal from 'react-modal';
import Fullscreen from "../../img/host/fullscreen.png";
import Expand from "../../img/host/expand.png";
import Close from "../../img/host/close.png";
import Reacltimewhiteboard from "../../img/host/whiteboard.png"
import Math from "../../img/host/mathematics.png"
import {newUpdatePlayslide} from "../../graphql/subscriptions";

Modal.setAppElement('#root');

function Imroom() {
    const [urls, setUrls] = useState([]);
    const [user, setUser] = useState();
    const [count, setCount] = useState(0);
    const [isShows, setIsShows] = useState(true);
    const [mylists, setMylists] = useState([]);
    const [isShowfulls, setIsShowfulls] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const { id } = useParams();
    let history = useHistory();
    const rechu = sessionStorage.getItem("reachumteamstest")
    if(rechu){
        console.log("teams", rechu)
    }
    const rechumemail = localStorage.getItem("vreachumneweremail")
    if(rechumemail){
        localStorage.removeItem("vreachumnewername");
        localStorage.removeItem("vreachumneweremail")
    }
    useEffect(  () => {
        fetchvlick();
        const subscription = API.graphql(
            {
                query: newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        });
        async function fetchData() {
            try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
                setUrls(datas.clink);
            }catch(error){
                console.log("hi")
            }
        }
        return()=>{
            subscription.unsubscribe()
        }
    })

    async function fetchvlick() {
        try {
        const response = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = response.data.getPlayslide;
            const h = datas.playlistlinks;
            setUrls(datas.clink);
            let count = Number(datas.counts);
            if (count > 0) {
                setUrls(h[count].rurl);
            }else {
                setUrls(h[0].rurl);
            }
        }catch(error){
                console.log("hi")
            }
    }
    const goups = async () =>{
        const re = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = re.data.getPlayslide;
        const f = Number(datas.counts);
        const t = f+1;
        const h = datas.playlistlinks;
        const ilists = h.length; // playlist length
        let i = ilists - 1;
        if (t <= i) {
            const input = {
                id,
                counts:t,
                clink: h[t].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }else {
            const input = {
                id,
                counts:0,
                clink: h[0].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }
    }
    const godowns = async ()=>{
        const re = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = re.data.getPlayslide;
        const f = Number(datas.counts);
        const t = f-1;
        const h = datas.playlistlinks;
        if (t <= 0) {
            const input = {
                id,
                counts:0,
                clink: h[0].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }else {
            const input = {
                id,
                counts:t,
                clink: h[t].rurl
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }
    }

    return (
                        <>
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        {urls !== "" && (
                                            <iframe
                                                className="mviframe"
                                                id="theFrame"
                                                src={urls}
                                                width="100%"
                                                height="100vh"
                                                frameBorder="0"
                                                allow="fullscreen"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} className="bottoms">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={9} className="centerside">
                                        <>
                                                <img src={Left} className="mimg" onClick={godowns} />

                                                <img src={Right} onClick={goups} className="mimg"/>

                                        </>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                </Grid>
                            </div>
                    </>
    );
}
export default Imroom;
