import React, {useEffect, useState} from "react";
import {API, Auth } from 'aws-amplify';
import {createAnswers, updatePlayslide} from '../../graphql/mutations';
import { useForm } from "react-hook-form";
import {getPlayslide} from "../../graphql/queries";
import Navbars from "../Navbar";
import Foot from "../Foot";
import {useParams,useHistory} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../css/answers.css";
import {Helmet} from "react-helmet";
import Leftarrow from "../../img/left-arrow.png";
import Grid from "@material-ui/core/Grid";

function Answer() {
	const { id } = useParams();
	const history = useHistory();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, [id]);
	const {register, handleSubmit} = useForm();
	const reorderurl = `https://demo.reachum.com/answerroom/${id}/${d}`;
	const editurl = `https://demo.reachum.com/answeredit/${id}/${d}`;
	const onSubmit = async (data) => {
		try {
			const input = {
				id:d,
				ffname:data.ffname,
				fquestion:data.fquestion
			}
			await API.graphql({
				query: createAnswers,
				variables: {input}
			});
			const urldata = {
				rid:d,
				title:data.ffname,
				rurl: reorderurl,
				eurl: editurl
			};
			const allImages = [...images, urldata]
			const inputs = {
				id,
				playlistlinks: allImages
			}
			await API.graphql({
				query: updatePlayslide,
				variables: {input:inputs}
			})
			setTimeout(() => {
				history.push(`/editroom/${id}`);
			}, 500)
		} catch (err) {
			console.log(err)
		}
	}
	function scp(){
		history.push(`/editroom/${id}`);
	}
	return (
		isOwner === isUser ?
		<>
			<Helmet>
				<title>SafeAsk</title>
			</Helmet>
			<Navbars />
			<div className="answerlist">
				<h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
				<br/>
				<br/>
				<div className="thiscenter">
				<h3>SafeAsk</h3>
				</div>

		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					Question name: &nbsp;
				</Grid>
				<Grid item xs={10}>
				<input
					name="ffname"
					defaultValue=""
					ref={register({ required: true, maxLength: 200 })}
					className="fullline"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					Question: &nbsp;
				</Grid>
				<Grid item xs={10}>
				<textarea
					name="fquestion"
					defaultValue=""
					ref={register({ required: true, maxLength: 500})}
					className="quesname"
				/>
				</Grid>
			</Grid>
			<br/>
		<div className="rightside">
					<input type="submit" value="Submit" className="answersubmit"/>
		</div>
		</form>
			</div>
			<Foot />
			</>
			:""
	);
}
export default Answer;
