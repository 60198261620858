import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {createAdobeexpress,updatePlayslide} from '../../graphql/mutations';
import { getPlayslide } from "../../graphql/queries";
import {useParams,useHistory} from "react-router-dom";
import Navbars from "../Navbar";
import {Button} from 'react-bootstrap';
import Foot from "../Foot";
import {v4 as uuid} from "uuid";
import {Helmet} from "react-helmet";
import Leftarrow from "../../img/left-arrow.png";
import Resizer from "react-image-file-resizer";
import "../css/adobe.css"

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            960,
            640,
            "JPEG",
            90,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

function  Adobe() {
    const [isopen, setIsopen] = useState(true);
    let history = useHistory();
    const {id} = useParams();
    const [adobeimage, setAdobeimage] = useState([]);
    const [images, setImages] = useState([]);
    const [adobevideo, setAdobevideo] = useState('');
    const [adobevideoid, setAdobevideoid] = useState('');
    const d = uuid();

    useEffect(() => {
        (async () => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const images = datas.playlistlinks;
            if (images != null) {
                setImages(images);
            }
        })()
    }, []);
    (async () => {
        const ccEverywhere = await window.CCEverywhere.initialize({
            clientId: 'b84a7f8b1a284e12bb86dfec2fa7463c',
            appName: 'REACHUM Platform',
            platformCategory: 'web'
        });
        const createButton = document.getElementById("createDesign");
        createButton.addEventListener('click', () => {
            setIsopen(false)
            const createDesignCallback = {
                onPublish: (publishParams) => {
                    const localData = {
                        project: publishParams.projectId,
                        images: publishParams.asset,
                        image: publishParams.asset[0].data,
                        filetype: publishParams.asset[0].fileType
                    };
                    const thisimag = localData.image;
                    const thisfiletype = localData.filetype;
                    const thisproject = localData.project;
                    setAdobevideoid(thisproject);
                    if (thisfiletype === "video/mp4") {
                        setAdobevideo(thisimag);
                    } else if (thisfiletype === "image/jpeg") {
                        const tt = localData.images;
                        setAdobeimage(tt);
                    } else {
                        console.log("save as jpg file")
                    }
                },
            }
            ccEverywhere.createDesign({
                callbacks: createDesignCallback,
                inputParams: {
                    canvasSize: {
                        height: 640,
                        width: 960,
                        unit: 'px'
                    }
                },
                outputParams: {
                    allowedFileTypes: [
                        "image/jpeg"
                    ],
                    videoQuality: 1280
                },
                ModalParams: {
                    parentElementId: "reachumembeded"
                }
            })
        })
    })();
    const addImageTopDB = (input) => {
        try {
             API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        } catch (error) {
            console.log(error)
        }
    }
        const handleupload = async () => {
            const adobeimages = adobeimage.map(obj => ({ data: obj.data }));
            const newImageArray = [];
            for (const image of adobeimages) {
                    const response = await fetch(image.data)
                    const blob = await response.blob();
                    const resized = await resizeFile(blob);
                    newImageArray.push({...image, data: resized});
                }
            const inputs = {
                id: d,
                dname: "Design image in adobe express",
                adobeprojectid: adobevideoid,
                adobeimags: newImageArray
            }
          await API.graphql({
                query: createAdobeexpress,
                variables: {input: inputs}
            });
            const ryrl = `https://demo.reachum.com/adobeshow/${id}/${d}`;
            const eryrl = `https://demo.reachum.com/adobeedit/${id}/${d}`;
            const vvv = {
                title: "Adobe",
                rid: d,
                rurl: ryrl,
                eurl: eryrl
            }
            const allImages = [...images, vvv]
            const input = {
                id,
                playlistlinks: allImages
            }
            addImageTopDB(input);
            setTimeout(() => {
                history.push(`/editroom/${id}`);
            }, 1000)
    }
    function scp(){
        history.push(`/editroom/${id}`);
    }

    return (
            <>
                <Helmet>
                    <title>Adobe Express</title>
                </Helmet>
                <Navbars />
                <div className="adobepage">
                    <br/>
                    <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
                    <br/>
                    <div className="centerthis">
                    <h3>Adobe Express</h3>
                        <br/>
                        <br/>
                    {isopen ?
                        <>
                        <p className="redthis">Please do not save more than 5 pages at a time.</p>
                        <Button id="createDesign" className="createadobebutton">Create images or slides in Adobe Express</Button>
                        <br/>
                            <i className="icode">If the button is unresponsive, please reload the page and click it again.</i>
                        </>
                        :
                        <>
                        <div className="centerside">
                            {adobeimage.map((item, index) => (
                                <>
                                    <div key={index}><img src={item.data} className="adobedisplaythum"/></div>
                                    <br/>
                                </>
                            ))}
                        </div>
                            <br/>
                        <Button onClick={handleupload} variant="success">Submit</Button>
                        </>
                    }
                    </div>
                </div>
                 <Foot />
        </>
    )
}


export default Adobe;

