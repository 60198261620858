import { Expression, GraphingCalculator } from 'desmos-react'
import { forwardRef } from 'react'

function DesmosCalculator(props, ref) {
  return (
    <>
      <GraphingCalculator
        attributes={{ className: 'calculator' }}
        keypad
        projectorMode
        ref={ref}
      >
        <Expression id="x=1" latex="" type='expression' />
      </GraphingCalculator>
    </>
  )
}
export default forwardRef(DesmosCalculator)
