export function DropZone({
  onFilesDrop,
  children,
  Component = 'div',
  ...rest
}) {
  const handleDragStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragEnd = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      onFilesDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  return (
    <Component
      {...rest}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onDragLeave={handleDragEnd}
      onDragEnter={handleDragStart}
    >
      {children}
    </Component>
  );
}
