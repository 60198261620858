import React, {useEffect, useState} from "react";
import { Auth,API} from 'aws-amplify';
import {getVideooptionslist, listLeaderboards, getPlayslide} from '../../../graphql/queries';
import {useHistory, useParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import "./videoplayer.css";
import {createLeaderboard, updateLeaderboard, createEngagement} from "../../../graphql/mutations";
import Grid from "@material-ui/core/Grid";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {Form, Input} from "element-react";
import Cheers from "../../../img/tontonfriends-cheers.gif";

function Showoptions() {
    const [isOpen, setIsOpen] = useState(true);
    const [quest, setQuest] = useState();
    const [pchecked, setPchecked] = useState();
    const [isOwner, setIsOwner] = useState(false);
    const [isemail, setIsemail] = useState(false);
    const [messages, setMessages] = useState();
    const [isShows, setIsShows] = useState(true);
    const [clist, setClist] = useState([]);
    const [playpause, setPlaypause] = useState(true);
    const [timeval, setTimeval] = useState(0);
    const { id, rid } = useParams();
    const [counter, setCounter] = useState();
    const [ans, setAns] = useState([]);
    const [cans, setCans] = useState([]);
    const [thissaved, setThissaved] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    useEffect( () => {
        fetchDas();
        const submitrids = sessionStorage.getItem("submitrid");
        if(submitrids){
            setThissaved(submitrids)
            if(submitrids.includes(rid)){
                setDisabled(true);
            }
        }
    }, []);

    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getVideooptionslist,
                variables: {id:rid}
            })
            const datas = response.data.getVideooptionslist;
            const timers = datas.vtimer;
            setCounter(timers)
            const ques = datas.vquestion;
            setQuest(ques);
            const tests = datas.vtest;
            setClist(tests);
            const ct = datas.vanswer;
            setAns(ct);
            const canss = tests[ct].panswer;
            setCans(canss)

        }catch(err){
            console.log(err)
        }
    }
    const psubmit = async () => {
        let tt = [];
        if (thissaved){
            tt = [thissaved, rid]
        }else{
            tt = [rid]
        }
        sessionStorage.setItem("submitrid", tt);
        setPlaypause(false);
       let mymeetnames = localStorage.getItem("vreachumnewername");
       let mymeetemails = localStorage.getItem("vreachumneweremail");
        if (pchecked === ans) {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "meetemail": {
                                eq: mymeetemails
                            },
                            "answerid": {
                                eq: id
                            }
                        },
                        limit:10000
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl > 0) {
                        const myscore = plays[0].score;
                        const myid = plays[0].id;
                        const myscores = timeval + myscore;
                        try {
                            const input = {
                                id: myid,
                                score: myscores,
                                thisgamescore: timeval
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    } else {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnames,
                                meetemail:mymeetemails,
                                score: timeval,
                                thisgamescore: timeval
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                    setMessages(`👏 You scored ${timeval} points.`)
                })
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "meetemail": {
                                eq: mymeetemails
                            },
                            "answerid": {
                                eq: id
                            }
                        },
                        limit:10000
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl === 0) {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnames,
                                meetemail:mymeetemails,
                                score: 0,
                                thisgamescore: 0
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });

                        } catch (err) {
                            console.log(err)
                        }
                    }else{
                        const myid = plays[0].id;
                        try {
                            const input = {
                                id: myid,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                })
            } catch (err) {
                console.log(err)
            }
            setMessages(`😳 The correct answer is ${cans}. You scored 0 point`)
        }
        setIsShows(false);
        try {
            const input = {
                questionid: id,
                engage: 1,
                people: 1
            }
            API.graphql({
                query: createEngagement,
                variables: {input}
            });
        } catch (err) {
            console.log(err)
        }
    };
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            setTimeval(0)
            return <div className="timer">0</div>;
        }else{
            setTimeval(remainingTime)
        }
        return (
            <div className="timer">
                <div className="value">{remainingTime}</div>
            </div>
        );
    }

    const getdatas = async () =>{
        const mymeetnams = localStorage.getItem("vreachumnewername");
        const mymeetemals = localStorage.getItem("vreachumneweremail");
        if(mymeetemals) {
            try {
                API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "meetemail": {
                                eq: mymeetemals
                            },
                            "answerid": {
                                eq: id
                            }
                        },
                        limit:10000
                    }
                }).then(datas => {
                    const plays = datas.data.listLeaderboards.items;
                    const playsl = plays.length;
                    if (playsl === 0) {
                        try {
                            const input = {
                                answerid: id,
                                name: mymeetnams,
                                meetemail: mymeetemals,
                                score: 0,
                                thisgamescore: 0
                            }
                            API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }else{
                        const myid = plays[0].id;
                        try {
                            const input = {
                                id: myid,
                                thisgamescore:0
                            }
                            API.graphql({
                                query: updateLeaderboard,
                                variables: {input}
                            });
                        } catch (err) {
                            console.log(err)
                        }
                    }
                    setMessages("😳 The quiz timed out. No answer submitted. You scored 0 point")
                    setIsShows(false);
                    try {
                        const input = {
                            questionid: id,
                            engage: 0,
                            people: 1
                        }
                        API.graphql({
                            query: createEngagement,
                            variables: {input}
                        });
                    } catch (err) {
                        console.log(err)
                    }
                })
            } catch (err) {
                console.log(err)
            }
        }else{
            setMessages("Time is up.")
            setIsShows(false);
        }
    }

    return (
        <>
            <div className="drawoptionpage2">
                        <div className="greenback">
                            <Grid container spacing={0}>
                                <Grid item xs={2}>
                                    <div className="lpollheadclock2">
                            <CountdownCircleTimer
                                isPlaying={playpause}
                                duration={counter}
                                size={50}
                                colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                colorsTime={[7, 5, 2, 0]}
                                onComplete={getdatas}
                            >
                                {renderTime}
                            </CountdownCircleTimer>
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <div className="fastanswes">
                                        <h4>{quest}</h4>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="drawoptioninsidepage">
                        {isShows ?
                            <>
                                <div>
                                    <ol className="pollcontainer">
                                        {
                                            clist.map((item, index) => {
                                                    return (
                                                        <li key={item.id} className="polllist">
                                                            <input
                                                                type="radio"
                                                                defaultValue={item.panswer}
                                                                name="panswer"
                                                                onChange={() => {
                                                                    setPchecked(item.id)
                                                                }}
                                                                id={item.id}
                                                            />
                                                            <label htmlFor={item.id}>{item.panswer}</label>
                                                            <div className="check"></div>
                                                        </li>
                                                    )
                                                }
                                            )
                                        }

                                    </ol>
                                    <br/>
                                    <Grid container spacing={0} className="pollbottoms">
                                        <Grid item xs={7}>
                                        </Grid>
                                        <Grid item xs={5} className="centerside">
                                            <Button id="pollsubmit" onClick={psubmit}
                                                    className="inputpolllist" disabled={isDisabled}>Submit</Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </> :
                            <>
                                <div className="vfastanswers">
                                    <h4 className="pollmaeeage">{messages}</h4>
                                </div>
                            </>
                        }
                        </div>
            </div>
        </>
    )
}

export default Showoptions;
