import React from "react";
import "./videorecordpager.css";
import Bigsmile from "../../img/smile.gif"
function Ideoedit (){

    return (
        <>
                    <div className="thiscenter">
                        <br/>
                        <br/>
                <img src={Bigsmile} />
                    </div>
                </>


    );
};

export default Ideoedit

