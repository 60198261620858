import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {updatePlayslide} from '../../graphql/mutations';
import { getPlayslide } from "../../graphql/queries";
import {useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Save, Close } from '@material-ui/icons';
import { Gif } from '@giphy/react-components';
import { getGif, createGif } from './graphqlService';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, .9)"
  },
  gifscontainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '510px',
    background: 'white',
    padding: '0 10px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  gifContainer: {
    display: 'inline-block',
    padding: '5px',
  },
  button: {
    flexGrow: '1',
    margin: '5px',
  }
}));

export default function GifModal({
  gif,
  onClose = () => { console.log('onClose not implemented') }
}) {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const { id } = useParams();
  const [images, setImages] = useState([]);

  useEffect( () => {
    (async() => {
      const response = await API.graphql({
        query: getPlayslide,
        variables:{id}
      })
      const datas = response.data.getPlayslide;
      const images = datas.playlistlinks;
      if(images != null) {
        setImages(images);
      }
    })()
  }, [id]);
  useEffect(() => {
    const setup = async () => {
      const foundGif = await getGif(gif);
      if (foundGif) {
        setSaved(true);
      }
    };
    setup();
  }, [gif]);

  const saveGif = async () => {
    try{
    const gtitle = gif.title;
      const gid = gif.id;
      const gurl = gif.images.fixed_height.url;
    const ginput = {
       rid: gid,
       title:gtitle,
       lurl:gurl,
       rurl:`https://demo.reachum.com/gifshowroom/${id}/${gid}`,
       eurl:`https://demo.reachum.com/giphyedit/${id}/${gid}`
    }
    const allImages = [...images].concat(ginput);
    const input = {
      id,
      playlistlinks: allImages
    };
    await API.graphql({
      query: updatePlayslide,
      variables: {input}
    });

  } catch (err) {
    console.error(err);
  }
    setSaving(true);
    try {
      await createGif(gif);
      setSaved(true);
    } catch (err) {
      console.error(err);
    }
    setSaving(false);
  }

  return (
    <div
      className={classes.modal}
    >
      <div className={classes.gifscontainer}>
        <div className={classes.buttonContainer}>
          <Button
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={<Close />}
              onClick={() => {
                onClose();
              }}
          >
            Close
          </Button>

          <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<Save />}
              disabled={saving || saved}
              onClick={() => {
                saveGif();
              }}
          >
            {saving && 'Saving...'}
            {saved && 'Saved'}
            {!saving && !saved && 'Save Gif'}
          </Button>
        </div>

        <div
          className={classes.gifContainer}
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          <Gif gif={gif} width={480} hideAttribution="true" />
        </div>
      </div>
    </div>
  );
}
