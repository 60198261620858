import {Auth, API, Hub} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {listPlayslides, listFolders, listDesktopitems, listSelfstudytimers, listHosts} from "../../graphql/queries";
import {
    newUpdatePlayslide,
    newDeletePlayslide,
    newUpdateDesktopitems,
    newUpdateFolders
} from "../../graphql/subscriptions";
import {
    createFolders,
    deletePlayslide,
    updatePlayslide,
    createDesktopitems,
    updateFolders,
    updateDesktopitems,
    createPlayslide, updateHost, createHost
} from "../../graphql/mutations";
import "../css/dashboard.css";
import Login from "../login";
import {Button, Modal} from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {v4 as uuid} from "uuid";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
    useDroppable,
    pointerWithin
} from '@dnd-kit/core';
import Grid from "@material-ui/core/Grid";
import { SortableItem } from './SortableItem';
import Folder from "./Folder";
import DesktopView from "./desktop";
import {Form, Input} from "element-react";
import moment from "moment-timezone";
import {Helmet} from "react-helmet";
import Navbars from "../../components/Navbar";
import Foot from "../../components/Foot";
import Foott from "../../components/Foott";
import {useHistory} from "react-router-dom";
import Inviteform from "../invitepeople/inviteform";
import Inviteglform from "../invitepeople/inviteglform";
import Invitecoform from "../invitepeople/invitecoform";
import Shareroom from "../shareroom";
import ListGroup from "react-bootstrap/ListGroup";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Info from "../../img/dash/pop-up-icon.svg";

function Dashboard() {
    const [myData, setMyData] = useState([])
    const [myroomData, setMyroomData] = useState([])
    const [user, setUser] = useState();
    const [username, setUsername] = useState("");
    const [newFolderName, setNewFolderName] = useState('')
    const [folders, setFolders] = useState([]);
    const [desktopitems, setDesktopitems] = useState([]);
    const [copied, setCopied] = useState("");
    const [icopied, setCiopied] = useState("");
    const [ccopied, setCcopied] = useState("");
    const [cionopied, setCionopied] = useState("")
    const [dragging, setDragging] = useState(false);
    const [savebuttonshow, setSavebuttonshow] = useState(false)
    const [draggingIds, setDraggingIds] = useState([]);
    const [changesavedmessage, setChangesavedmessage] = useState("");
    const [mymeeing, setMymeeting] = useState();
    const [extime, setExtime] = useState();
    const [thisname,setThisname] = useState("");
    const [thisid,setThisid] = useState("");
    const [thismid,setThismid] = useState("");
    const [show7, setShow7] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {setShow2(false);setShow(true)};
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => {setShow3(false);setShow(true)};
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => {setShow4(false);setShow(true)};
    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [mname, setMname] = useState("");
    const [myplan, setMyplan] = useState("");
    const [isthislink, setIsthislink] = useState("full")
    const [isthisplan, setIsthisplan] = useState(true);
    const [openthis, setOpenthis] = useState(true);
    const [showprocess, setShowprocess] = useState("");
    const [openthisitems, setOpenthisitems] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [stateData, setStateData] = useState({
        meetingname:"",
        fields: ["hostRoomUrl"]
    });
    let history = useHistory();
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5
            }
        }),
    );
    const [selectedIds, setSelectedIds] = useState([])
    const d = uuid();
    useEffect(() => {
        getUserData();
        Hub.listen('auth', (data) => {
            const { payload } = data;
            onAuthEvent(payload);
        });
        fetchData();
        fetchfolder();
        fetchdesktofiles();
        const deletePlayslide = API.graphql(
            {
                query:newDeletePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const updatePlayslide = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        });
        const updateDesktopitems = API.graphql(
            {
                query:newUpdateDesktopitems
            }
        ).subscribe({
            next: () => {
                fetchdesktofiles();
            }
        })
        return()=>{
            deletePlayslide.unsubscribe();
            updatePlayslide.unsubscribe();
            updateDesktopitems.unsubscribe();
        }
    }, []);
    const  getUserData = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const useremail = user.attributes.email;
        if(user){
            setUsername(user.attributes.email);
            setUser(user);
        }else{
            setUser(null)
        }
        try {
            const response = await API.graphql({
                query: listHosts,
                variables: {
                    filter: {
                        "owner": {
                            eq: useremail
                        }
                    },
                    limit: 10000
                }
            })
            const datas = response.data.listHosts.items;
            if(datas.length === 0) {
                const input = {
                    owner: useremail
                }
                await API.graphql({
                    query: createHost,
                    variables: {input}
                });
            }
        }catch (err) {
            console.log(err)
        }

    }
        function onAuthEvent(payload)  {
        switch (payload.event) {
            case "signIn":
                getUserData();
                fetchData();
                fetchfolder();
               fetchdesktofiles();
                break;
            case "signUp":
                break;
            default:
                return
        }
    }
        const handleClick = () => {
            setSavebuttonshow(true)
        };
    async function fetchData() {
        const user = await Auth.currentAuthenticatedUser();
        const thisuser = user.attributes.email;
        const response = await API.graphql({
            query: listPlayslides,
            variables: {
                filter: {
                    "owner": {
                        eq: thisuser
                    }
                },
                limit:10000
            }
        });
        const datas = response.data.listPlayslides.items;
        const transformedData = datas.map(item => ({
            id: item.id,
            meetingname: item.meetingname,
            mid:item.mid
        }));
        setMyData(transformedData);
    }
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsFixed(scrollTop > 100); // Adjust this value based on when you want the div to become fixed
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    async function fetchfolder() {
        const user = await Auth.currentAuthenticatedUser();
        const thisuser = user.attributes.email;
        const response = await API.graphql({
            query: listFolders,
            variables: {
                filter: {
                    "owner": {
                        eq: thisuser
                    }
                },
                limit:10000
            }
        })
        const datas = response.data.listFolders.items;
        if(datas.length !== 0) {
            const thisfolder = JSON.parse(datas[0].folderlists);
            setFolders(thisfolder)
        }
    }
    async function fetchdesktofiles() {
        const user = await Auth.currentAuthenticatedUser();
        const thisuser = user.attributes.email;
        const response = await API.graphql({
            query: listDesktopitems,
            variables: {
                filter: {
                    "owner": {
                        eq: thisuser
                    }
                },
                limit:10000
            }
        })
        const datas = response.data.listDesktopitems.items;
        if(datas.length !== 0) {
            if(datas[0].deskitems.length !== 0) {
                const thisfolder = JSON.parse(datas[0].deskitems);
                setDesktopitems(current => thisfolder);
                setMyData(current => {
                    // const newItems = thisfolder.filter(thisItem => {
                    //     return !current.some(currentItem => currentItem.id === thisItem.id)
                    // })
                    // return [...current, ...newItems]
                    return thisfolder
                })
            }
        }else{
            const user = await Auth.currentAuthenticatedUser();
            const thisuser = user.attributes.email;
            const response = await API.graphql({
                query: listPlayslides,
                variables: {
                    filter: {
                        "owner": {
                            eq: thisuser
                        }
                    },
                    limit:10000
                }
            });
            const datas = response.data.listPlayslides.items;
         //   const scending = [...datas].sort((a, b) => a.meetingname.toLowerCase() < b.meetingname.toLowerCase() ? -1 : 1);
            const transformedData = datas.map(item => ({
                id: item.id,
                meetingname: item.meetingname,
                mid:item.mid
            }));
            const input={
                deskitems:JSON.stringify(transformedData),
                owner:thisuser
            }
            await API.graphql({
                query: createDesktopitems,
                variables: {input}
            });
            setDesktopitems(transformedData)
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);
    const handleDelete = async (itemId) => {
        let text = "Are you sure you want to delete this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            const input = {
                id: itemId
            };
            await API.graphql({
                query: deletePlayslide,
                variables: {input}
            });
            setOpenthisitems(false)
            const response = await API.graphql({
                query: listFolders,
                variables: {
                    filter: {
                        "owner": {
                            eq: username
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listFolders.items;
            if(datas.length !== 0) {
                const thisfolderid = datas[0].id;
                const thisfolder = JSON.parse(datas[0].folderlists);
                const updatedData = { ...thisfolder };
                for (const key in updatedData) {
                    updatedData[key].items = updatedData[key].items.filter(item => item.id !== itemId);
                }
                setFolders(current => {
                    const currentEntries = Object.entries(current)
                    const newEntries = currentEntries.map(entry => {
                        const folder = entry[1]
                        const newItems = folder.items.filter(item => item.id !== itemId)
                        const newFolder = { ...folder, items: newItems }
                        return [entry[0], newFolder]
                    })
                    return Object.fromEntries(newEntries)
                })
                const input ={
                    id:thisfolderid,
                    folderlists: JSON.stringify(updatedData)
                }
                await API.graphql({
                    query: updateFolders,
                    variables: {input}
                });
            }
            const response1 = await API.graphql({
                query: listDesktopitems,
                variables: {
                    filter: {
                        "owner": {
                            eq: username
                        }
                    },
                    limit:10000
                }
            })
            const datas1 = response1.data.listDesktopitems.items;
            if(datas1.length !== 0) {
                const thisitemid = datas1[0].id;
                const thisfolderitem = JSON.parse(datas1[0].deskitems);
                const updatedData1 = thisfolderitem.filter(item => item.id !== itemId);
                setDesktopitems(updatedData1)
                const input1 ={
                    id:thisitemid,
                    deskitems: JSON.stringify(updatedData1)
                }
                await API.graphql({
                    query: updateDesktopitems,
                    variables: {input:input1}
                });
            }
            setDraggingIds(current => current.filter(draggingId => draggingId !== itemId))
            setSelectedIds(current => current.filter(selectedId => selectedId !== itemId))
        }
    };
    const goCreate = () => {
        setShow7(true);
    }
    const renderDatalinks = () => {
        return (
            <ListGroup.Item >
                <Tabs
                    defaultActiveKey={isthislink}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                        <Tab eventKey="full" title="Video Call with Content">
                            <br/>
                            <Grid container spacing={0} className="topspace1">
                                <Grid item xs={6}>
                                    <button onClick={() => {
                                        window.open(`/host/${thisid}/${thismid}`, "_blank");
                                    }}
                                            className="startbuttoncolor">Start Meeting
                                    </button>
                                </Grid>
                                <Grid item xs={6} className="rightside">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className="invitebuttoncolor">
                                            Calendar Invite
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.ItemText>Click to invite</Dropdown.ItemText>
                                            <ul>
                                                <Dropdown.Item onClick={() => {
                                                    setShow(false)
                                                    setShow2(true)
                                                }}><li>Guest</li></Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    setShow(false)
                                                    setShow3(true)
                                                }}><li>Group Leader</li></Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    setShow(false)
                                                    setShow4(true)
                                                }}><li>Co-host</li></Dropdown.Item>
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Grid>
                            </Grid>
                            <br/>
                            <br/>
                            <span className="darkgreen">GUEST LINK (Click to Copy):</span>
                            <br/>
                            <CopyToClipboard text={`https://demo.reachum.com/view/${thisid}`} onCopy={() => {
                                setCopied(true);
                                setTimeout(() => {
                                    setCopied(false);
                                }, 3000);
                            }}>
                                <span className="buttoncolor">{`https://demo.reachum.com/view/${thisid}`}</span>
                            </CopyToClipboard>
                            <br/>
                            {copied ? <span style={{color: 'red'}}>Copied</span> : null}
                            <br/>
                            <br/>
                            <span className="lightdarkgreen">Link for GROUP LEADERS (click to copy)</span>
                            <div className="upgradenew2">
                                <img src={Info} width="30px" alt="Info" />
                                <span className="tooltiptext2">Group leaders have screen sharing enabled so they can assist the presenter without requiring permission.</span>
                            </div>:
                            <br/>
                            <br/>
                            <span className="lightgreen">Link for COHOSTS (click to copy) </span>
                            <div className="upgradenew2">
                                <img src={Info} width="30px" alt="Info" />
                                <span className="tooltiptext2">COHOSTS have all capabilities of Hosts except for locking the room. They can advance or go backward in the presentation, share their screen, and silence or delete other users. The only permission they don‚Äôt have is locking the room.</span>
                            </div>:
                            <br/>
                            <CopyToClipboard text={`https://demo.reachum.com/cohost/${thisid}/${thismid}`}
                                             onCopy={() => {
                                                 setCcopied(true);
                                                 setTimeout(() => {
                                                     setCcopied(false);
                                                 }, 3000);
                                             }}>
                                <div className="upgradenew2">
                                        <span
                                            className="buttoncolor">{`https://demo.reachum.com/cohost/${thisid}/${thismid}`}</span>
                                    <span className="tooltiptext2">
                                        Copy the Co-hosts' link
                                    </span>
                                </div>
                            </CopyToClipboard>
                            <br/>
                            {ccopied ? <span style={{color: 'red'}}>Copied</span> : null}

                            <br/>
                            <br/>
                        </Tab>

                    <Tab eventKey="share" title="Content Only (for in-person engagement)">

                        <br/>
                        <Grid container spacing={0}>
                            <Grid item xs={5}>
                                <button onClick={() =>{
                                    window.open(`/ihost/${thisid}/${thismid}`, "_blank");
                                }} className="startbuttoncolor">
                                    Start Meeting</button>

                                <br/>
                                <br/>
                                <span className="darkgreen">GUEST LINK (Click to Copy):</span>
                                <br/>
                                <div>
                                    <CopyToClipboard text={`https://demo.reachum.com/iview/${thisid}`} onCopy={() => {
                                        setCiopied(true);
                                        setTimeout(() => {
                                            setCiopied(false);
                                        }, 5000);
                                    }}>
                                        <span className="buttoncolor">{`https://demo.reachum.com/iview/${thisid}`}</span>

                                    </CopyToClipboard>
                                    <br/>
                                    {icopied ? <span style={{color: 'red'}}>Copied</span> : null}
                                </div>
                            </Grid>
                            <Grid item xs={7} className="centerside">
                            </Grid>
                        </Grid>

                        <br/>
                        <br/>
                        <div className="rightside">
                            <button  onClick={() => {
                                history.push(`/inviteform1/${thisid}`)
                            }} className="invitebuttoncolor">Invite Guests</button>
                        </div>
                        <br/>
                        <br/>

                    </Tab>
                    <Tab eventKey="selfstudy" title="Self-Study">
                        <br/>
                        <Grid container spacing={0}>
                            <Grid item xs={8}>
                                <i className="icode">Before sharing this link,<br/>
                                    please make sure that you have removed the QR code slide.</i>
                            </Grid>
                            <Grid item xs={4} className="rightside">
                            </Grid>
                        </Grid>
                        <br/>
                        <span className="darkgreen">SELF-STUDY LINK (click to copy) </span>
                        <div className="upgradenew3">
                            <img src={Info} width="30px" alt="Info" />
                            <span className="tooltiptext3">You can embed this link into your LMS.</span>
                        </div>:
                        <br/>
                        <div>
                            <CopyToClipboard text={`https://demo.reachum.com/selfStudy/${thisid}`} onCopy={() => {
                                setCionopied(true);
                                setTimeout(() => {
                                    setCionopied(false);
                                }, 5000);
                            }}>
                                <span className="buttoncolor">{`https://demo.reachum.com/selfStudy/${thisid}`}</span>

                            </CopyToClipboard>
                            <br/>
                            {cionopied ? <span style={{color: 'red'}}>Copied</span> : null}
                        </div>
                        <br/>
                        <br/>
                        <div className="rightside">
                            <button  onClick={() => {
                                history.push(`/inviteondemandform/${thisid}`)
                            }} className="invitebuttoncolor">Invite Students</button>
                        </div>
                        <br/>
                        <br/>
                    </Tab>
                </Tabs>
            </ListGroup.Item>

        )
    }

    function sharelist() {
        setShow5(true)
    }
    function selectId(id) {
        setSelectedIds(current => {
            const newIds = [id, ...current]
            const newItems = myData.filter(item => {
                return newIds.includes(item.id)
            })
            return newItems.map(item => item.id)
        })
    }
    function toggleSelect(id) {
        setSelectedIds(current => {
            const selected = current.includes(id)
            if (selected) {
                return current.filter(currentId => currentId !== id)
            } else {
                const newIds = [id, ...current]
                const newItems = myData.filter(item => {
                    return newIds.includes(item.id)
                })
                return newItems.map(item => item.id)
            }
        })
    }
    function onIdClick(id,mname,mid){
        setThisid(id);
        setThisname(mname);
        setThismid(mid);
        setOpenthisitems(true)
    }
    const desktopViews = desktopitems.map(item => {
        const selected = selectedIds.includes(item.id);
        return (
            <SortableItem
                key={item.id}
                item={item}
                selected={selected}
                toggleSelect={toggleSelect}
                handleDelete={handleDelete}
                onIdClick={onIdClick}
            />
        )
    })
    const selectedItems = myData.filter(item => {

        return selectedIds.includes(item.id)
    })
    const selectedClassName = selectedItems ? 'selected' : ''
    const className = `divcss ${selectedClassName}`
    const draggingItems = dragging && selectedItems.map((item) => {
        return (
            <div className={className} key={item.id}>
                <div className="cccenter">
                        <span> {item.meetingname}</span>
                </div>
            </div>
        )
    })
    function handleNewFolderNameChange(event) {
        setNewFolderName(event.target.value)
    }
    function handleCreateFolderClick() {
        if (newFolderName === '') {
            return
        }
        setFolders(current => {
            const id = uuid()
            return {
                ...current,
                [id]: {
                    id,
                    name: newFolderName,
                    items: []
                }
            }
        })
        setTimeout(() => {
            setNewFolderName("")
        }, 500);
    }
    const folderValues = Object.values(folders)
    const topFolders = folderValues.filter(folder => folder.parentId == null)
    const folderViews = topFolders.map(folder => {
        function changeFolderName(newName) {
            setFolders(current => {
                const currentFolder = current[folder.id]
                return {
                    ...current,
                    [folder.id]: {
                        ...currentFolder,
                        name: newName
                    }
                }
            })
        }
        function deleteFolder(folderId) {
            let text = `Are you sure you want to delete this folder? \nEither OK or Cancel.`;
            if (window.confirm(text) === true) {
                setFolders(current => {
                    const currentEntries = Object.entries(current);
                    console.log("current", currentEntries);
                   const newEntries = currentEntries.filter(entry => {
                     if (entry[1].id === folderId) {
                         const itr = entry[1].items;
                         const itemslength = itr.length;
                         for (let i = 0; i < itemslength; i++) {
                             if (itr[i].id) {
                                 const input = {
                                     id: itr[i].id
                                 };
                                 API.graphql({
                                     query: deletePlayslide,
                                     variables: {input}
                                 });
                             }}
                         return false
                    }
                    if (entry[1].parentId === folderId) {
                        const itr = entry[1].items;
                        const itemslength = itr.length;
                        for (let i = 0; i < itemslength; i++) {
                            if (itr[i].id) {
                                const input = {
                                    id: itr[i].id
                                };
                                API.graphql({
                                    query: deletePlayslide,
                                    variables: {input}
                                });
                            }}
                     return false
                     }
                    return true
                   })
                    const newFolders = Object.fromEntries(newEntries)
                    return newFolders
                })
            }
        }
        function createSubfolder (name) {
            setFolders(current => {
                const id = uuid()
                const newFolder = {
                    id,
                    name,
                    items: [],
                    parentId: folder.id
                }
                const newFolders = {...current, [id]: newFolder}
                return newFolders
            })
        }
        return (
            <Folder
                key={folder.id}
                folder={folder}
                changeFolderName={changeFolderName}
                deleteFolder={deleteFolder}
                toggleSelect={toggleSelect}
                selectedIds={selectedIds}
                createSubfolder={createSubfolder}
                folders={folders}
                subfolder={false}
                handleDelete={handleDelete}
                onIdClick={onIdClick}
            />
        )
    })
    function handleDragStart({ active }) {
        selectId(active.id)
        setDragging(true)
        const newSelectedIds = [...selectedIds, active.id]
        const selectedItems = myData.filter(item => {
            return newSelectedIds.includes(item.id)
        })
        const draggingIds = selectedItems.map(item => {
            return item.id
        })
        setDraggingIds(draggingIds)
    }
    function handleDragOver(event) {
        const { active, over } = event;
        if (over == null) {
            return
        }
        const overFolderItemFolder = folderValues.find(folder => {
            return folder.items.some(item => item.id === over.id)
        })
        const overFolder = folders[over.id]
        const folderOver = overFolderItemFolder ?? overFolder
        const overDesktopArea = over.id === 'desktop'
        const overDesktopItem = desktopitems.find(item => item.id === over.id)
        const overDesktop = overDesktopArea || overDesktopItem;
        const selectedItems = draggingIds.map(id => {
            const item = myData.find(item => item.id === id)
            return item
        })
        const overSelected = selectedIds.includes(over.id)
        let foldersClone = JSON.parse(JSON.stringify(folders))
        let desktopClone = JSON.parse(JSON.stringify(desktopitems))
        if (overSelected) {
            if (overDesktop) {
                const withoutSelectedExceptOver = desktopitems.filter(item => {
                    const selected = selectedIds.includes(item.id)
                    if (!selected) {
                        return true
                    }
                    const overItem = item.id === over.id
                    if (overItem) {
                        return true
                    }
                    return false
                })
                const newOverIndex = withoutSelectedExceptOver.findIndex(item => item.id === over.id)
                const beforeNewOver = withoutSelectedExceptOver.slice(0, newOverIndex)
                const afterNewOver = withoutSelectedExceptOver.slice(newOverIndex + 1)
                const newDesktop = [...beforeNewOver, ...selectedItems, ...afterNewOver]
                setDesktopitems(newDesktop)
                return
            }
        }
        if (folderOver != null) {
            const newFolderValues = folderValues.map(folder => {
                if (folder.id === folderOver.id) {
                    const notInFolderItems = selectedItems.filter(item => {
                        return folder.items.every(folderItem => folderItem.id !== item.id)
                    })
                    const newItems = [...folder.items, ...notInFolderItems]
                    const newFolder = {
                        ...folder,
                        items: newItems
                    }
                    return newFolder
                }
                const newItems = folder.items.filter(item => !selectedIds.includes(item.id))
                const newFolder = { ...folder, items: newItems }
                return newFolder
            })
            newFolderValues.forEach(folder => {
                foldersClone[folder.id] = folder
            })
            desktopClone = desktopClone.filter(item => !selectedIds.includes(item.id))
        } else if (overDesktop) {
            const activeIndex = desktopitems.findIndex(item => item.id === active.id)
            const overIndex = desktopitems.findIndex(item => item.id === over.id)
            const forward = overIndex > activeIndex
            const newWithout = desktopitems.filter(item => {
                return !selectedIds.includes(item.id)
            })
            const newOverIndex = newWithout.findIndex(item => item.id === over.id)
            const overFirst = newOverIndex === 0
            if (overFirst && !forward) {
                return [...selectedItems, ...newWithout]
            }
            const offset = forward ? 1 : 0
            const beforeNew = newWithout.slice(0, newOverIndex + offset)
            const afterNew = newWithout.slice(newOverIndex + offset)
            desktopClone = [...beforeNew, ...selectedItems, ...afterNew]
            const newFolderValues = folderValues.map(folder => {
                const newItems = folder.items.filter(item => !selectedIds.includes(item.id))
                return { ...folder, items: newItems }
            })
            newFolderValues.forEach(folder => {
                foldersClone[folder.id] = folder
            })
        }
        setFolders(foldersClone)
        setDesktopitems(desktopClone)
    }
    function handleDragEnd(event) {
        setSelectedIds([])
        setDragging(false)
    }
    async function storeState () {
        const response = await API.graphql({
            query: listFolders,
            variables: {
                filter: {
                    "owner": {
                        eq: username
                    }
                },
                limit:10000
            }
        })
        const datas = response.data.listFolders.items;
        if(datas.length !== 0) {
            const id = datas[0].id;
            const input ={
                id,
                folderlists: JSON.stringify(folders)
            }
            await API.graphql({
                query: updateFolders,
                variables: {input}
            });
        }else{
            const input ={
                folderlists: JSON.stringify(folders),
                owner:username
            }
            await API.graphql({
                query: createFolders,
                variables: {input}
            });
        }
        const response1 = await API.graphql({
            query: listDesktopitems,
            variables: {
                filter: {
                    "owner": {
                        eq: username
                    }
                },
                limit:10000
            }
        })
        const datas1 = response1.data.listDesktopitems.items;
        if(datas1.length !== 0) {
            const id = datas1[0].id;
            const input ={
                id,
                deskitems: JSON.stringify(desktopitems)
            }
            await API.graphql({
                query: updateDesktopitems,
                variables: {input}
            });
        }else{
            const input1 = {
                deskitems:JSON.stringify(desktopitems),
                owner:username
            }
            await API.graphql({
                query: createDesktopitems,
                variables: {input:input1}
            });
        }
        setChangesavedmessage("Saved");
        setTimeout(() => {
            setChangesavedmessage("")
        }, 500);
    }
    var mid = Math.random() * 1000000000000000000;
    const  gobackdash = async () => {
        try {
            const input = {
                ctime:extime,
                mid: mid,
                myrlink: myroomData.myrlinks,
                clientrlink: myroomData.clientrlinks,
                meetingname: mymeeing,
                fulls:false,
                counts:0,
                exiton:false,
                vplaypause:false,
                aplaypause:false,
                owner:username
            };
            const response = await API.graphql({
                query: createPlayslide,
                variables: {input}
            });
            const newitems = response.data.createPlayslide;
            var ids = newitems.id;
            const newitem = {
                id: ids,
                meetingname: mymeeing,
                mid:mid
            }
            const response1 = await API.graphql({
                query: listDesktopitems,
                variables: {
                    filter: {
                        "owner": {
                            eq: username
                        }
                    },
                    limit:10000
                }
            })
            const datas1 = response1.data.listDesktopitems.items;
            if(datas1.length !== 0) {
                const id = datas1[0].id;
                const deskitemss = [...desktopitems, newitem]
                const input ={
                    id,
                    deskitems: JSON.stringify(deskitemss)
                }
                await API.graphql({
                    query: updateDesktopitems,
                    variables: {input}
                });
            }else{
                const input1 = {
                    deskitems:JSON.stringify(newitem),
                    owner:username
                }
                await API.graphql({
                    query: createDesktopitems,
                    variables: {input:input1}
                });
            }
            history.push(`/editroom/${ids}`);
        }catch(err){
            console.log(err)
        }
    }
    const handleSubmit2 = async event => {
        event.preventDefault();
        setShowprocess("Creating...");
        try {
            var thedate = moment();
            var newDate = thedate.add(1, "y");
            const utcendDate = newDate.utc().format();
            const data = {
                body: {
                    endDate: utcendDate,
                    roomMode: "group",
                    fields: ["hostRoomUrl"]
                }
            }
            const exptime = newDate.format('MM/DD/YYYY');
            return await API.post("demowherebyv1","/demowherebyv1",data)
                .then(datas => {
                    const myrlinks = datas.hostRoomUrl;
                    const clientrlinks = datas.roomUrl;
                    const daas = {
                        myrlinks,
                        clientrlinks,
                        meetingname: stateData.meetingname,
                    };
                    setMyroomData(daas)
                    setMymeeting(daas.meetingname)
                    setExtime(exptime)
                    setShowprocess("")
                    setOpenthis(false)
                })
        }catch(err){
            console.log(err)
        }
    }
    return (
        <>
            <Helmet>
                <title>REACHUM | DASHBOARD</title>
            </Helmet>
            <Navbars/>
            {!user ?
                <Login />
        :(
            <>
                <div className="homepage">
                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                            <div className={isFixed ? 'fixed-div' : ''}>
                            <Form className="market-header">
                                <div className="cccenter">
                                    <div className="createheadnote">
                                        <span className="redthis">{showprocess}</span>
                                    </div>
                                    <br/>
                                    <br/>
                                    <Form.Item>
                                        <Input
                                            type="text"
                                            value={stateData.meetingname}
                                            onChange={meetingname => setStateData({
                                                ...stateData,
                                                meetingname: meetingname
                                            })}
                                            placeholder="Room Name"
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        {openthis ?
                                            <>
                                                <Button onClick={() => setShow7(false)}
                                                        className="foldersavechanges"> &nbsp; &nbsp; Cancel &nbsp; &nbsp;</Button> &nbsp; &nbsp; &nbsp;
                                                <Button
                                                    disabled={!stateData.meetingname}
                                                    onClick={handleSubmit2}
                                                    className="foldersavechanges"
                                                >
                                                    &nbsp; &nbsp; &nbsp; Next &nbsp; &nbsp; &nbsp;
                                                </Button>
                                            </>
                                            :
                                            <Button onClick={() => {
                                                gobackdash();
                                                setShow7(false)
                                            }
                                            } className="newdashboardsave">Create a item</Button>
                                        }
                                    </Form.Item>
                                </div>
                            </Form>
                            <br/>
                            <div className="inputfoldername">
                                {' '}
                                <input
                                    type="text"
                                    placeholder="Enter folder name"
                                    value={newFolderName}
                                    onChange={handleNewFolderNameChange}
                                    className="dasheditroomnamefoder"
                                />
                                <br/>
                                <div className="addfolderbutton">
                                    <Button onClick={handleCreateFolderClick} className="foldersavechanges" size="sm">Add
                                        Folder</Button>
                                </div>
                            </div>
                            <br/>
                            <div className="cccenter">
                                <br/>
                                <br/>

                                <Button onClick={storeState}
                                        className="newdashboardsave">Save
                                    Changes</Button> &nbsp; <span className="redthis"> {changesavedmessage} </span>

                            </div>
                            <br/>
                            <hr/>
                            <br/>
                            {openthisitems ?
                                <>
                                        <div className="cccenter">
                                        <h5>
                                            Room Selected:
                                        </h5>
                                        <p className="roomselectedred">{thisname}</p>
                                        <br/>
                                        <p onClick={() => {
                                            history.push(`/editroom/${thisid}`);
                                        }}>
                                            Manage Content
                                        </p>
                                        <p onClick={() => {
                                            history.push(`/reports/${thisid}`);
                                        }}>Reports
                                        </p>
                                        <p onClick={() => {
                                            setMname(thisname);
                                            setThisid(thisid);
                                            sharelist();
                                            setOpenthisitems(false)
                                        }}> Share Room
                                        </p>
                                        <button onClick={() => {
                                            setShow(true);
                                            setThisname(thisname);
                                            setThisid(thisid);
                                            setThismid(thismid);
                                        }} className="golivebutton">Meeting Links
                                        </button>
                                        </div>
                                </>
                                : ""
                            }
                            </div>
                        </Grid>
                        <Grid item xs={9} className="cccenter">
                            <div className="newroomlistpage">
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={pointerWithin}
                                    onDragEnd={handleDragEnd}
                                    onDragOver={handleDragOver}
                                    onDragStart={handleDragStart}
                                >
                                    <DesktopView>
                                        {desktopViews}
                                    </DesktopView>
                                    <DragOverlay>
                                        {draggingItems}
                                    </DragOverlay>
                                    {folderViews}
                                </DndContext>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" size="xl"
                       centered>
                    <Modal.Header closeButton>
                        {thisname} - Meeting Links
                    </Modal.Header>
                    <Modal.Body>
                        {renderDatalinks()}
                    </Modal.Body>
                </Modal>
                <Modal show={show2} onHide={handleClose2} aria-labelledby="contained-modal-title-vcenter" size="xl"
                       centered>
                    <Modal.Header closeButton>
                        Calendar Invite
                    </Modal.Header>
                    <Modal.Body>
                        <Inviteform data={thisid}/>
                    </Modal.Body>
                </Modal>
                <Modal show={show3} onHide={handleClose3} aria-labelledby="contained-modal-title-vcenter" size="xl"
                       centered>
                    <Modal.Header closeButton>
                        Calendar Invite
                    </Modal.Header>
                    <Modal.Body>
                        <Inviteglform data={thisid}/>
                    </Modal.Body>
                </Modal>
                <Modal show={show4} onHide={handleClose4} aria-labelledby="contained-modal-title-vcenter" size="xl"
                       centered>
                    <Modal.Header closeButton>
                        Calendar Invite
                    </Modal.Header>
                    <Modal.Body>
                        <Invitecoform data={thisid}/>
                    </Modal.Body>
                </Modal>
                <Modal show={show5} onHide={handleClose5} aria-labelledby="contained-modal-title-vcenter" size="lg"
                       centered>
                    <Modal.Header closeButton>
                        {mname}
                    </Modal.Header>
                    <Modal.Body>
                        <Shareroom data={thisid}/>
                    </Modal.Body>

                </Modal>

            </>
                )
            }

            <Foot/>
            <Foott/>
        </>


    );
}

export default Dashboard;

