import { Clear, Search } from '@material-ui/icons';
import React from 'react';
import Giphylogo from "../../img/ZZBX-logo.gif";
import {useHistory, useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "../css/gif.css";
import Leftarrow from "../../img/left-arrow.png";
const ENTER_KEY = 13;

export default function SearchInput({
  onSearch = (search) => { console.log('onSearch not implemented') },
  onClear = () => { console.log("clear")},
  placeholder = 'Search',
}) {
  const { id } = useParams();
  const history = useHistory();
  const [value, setValue] = React.useState('');

  const onSearchClick = (e) => {
    e.preventDefault();
    onSearch(value);
  }

  const onChange = (e) => {
    setValue(e.target.value);
  }

  const clearSearch = (e) => {
    e.preventDefault();
    setValue('');
    onClear();
  }

  const onEnterBound = (e) => {
    const isEnterPressed = e.which === ENTER_KEY || e.keyCode === ENTER_KEY;
    if (isEnterPressed ) {
      onSearch(value);
    }
  }
  function scp(){
    history.push(`/editroom/${id}`);
  }
  return (
    <div>
        <Grid container spacing={0}>
            <Grid item xs={4} className="searchbarleft">
                <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
            </Grid>
            <Grid item xs={5}>
      <div className="searchbarmiddle">
      <input
        className="searchInput"
        onKeyPress={onEnterBound}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        value={value}
      />
        <button
            aria-label="search"
            onClick={onSearchClick}
            className="searchInputbutton"
        >
            <Search />
        </button>
          <button
              aria-label="delete"
              disabled={!value}
              onClick={clearSearch}
              className="searchInputbuttons"
          >
              <Clear/>
          </button>
      </div>
            </Grid>
            <Grid item xs={3} className="searchbarmiddle">
                <img src={Giphylogo} className="gitsearchexit" width="167" height="35"/>
            </Grid>
        </Grid>
    </div>
  )
}
