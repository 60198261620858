import React, { useState, useEffect } from 'react';
import {API} from 'aws-amplify';
import {useParams} from "react-router-dom";
import { getDesmomathslide } from "../../graphql/queries";
import MathJax from "react-mathjax";


const  Mathslideshow = ()  =>{
    const { id,rid } = useParams();
    const [thisimage, setThisimage] = useState('')
    const [thisgimage, setThisgimage] = useState('')
    const [thismathimage, setThismathimage] =useState('')
    useEffect(() => {
        fetchData();
    }, []);

   async function fetchData(){
            const response = await API.graphql({
                query: getDesmomathslide,
                variables:{id:rid}
            })
            const datas = response.data.getDesmomathslide;
            const tt = datas.graphic;
            if(tt!== null){
                setThisgimage(tt)
            }
       const mt = datas.mathstate;
       if(mt!== null){
           setThismathimage(mt)
       }
       const mimage = datas.image;
       if(mimage !== null){
           setThisimage(mimage)
       }
}
    return (
        <>
            <div className="cccenter">
                <br/>
                <br/>
                <MathJax.Provider>
                    <div className="mathshowfont">
                        <MathJax.Node formula={thismathimage} />
                    </div>
                </MathJax.Provider>
                <img src={thisimage} className="pximages400" />
                <br/>
                <br/>
                <img src={thisgimage} className="pximage960"/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </>
    );
}

export default Mathslideshow;
