import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../../graphql/mutations';
import {useParams,useHistory} from "react-router-dom";
import "../../css/uploadurl.css";
import {getPlayslide} from "../../../graphql/queries";
import Navbars from "../../Navbar";
import Foot from "../../Foot";
import { v4 as uuid } from 'uuid';
import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Leftarrow from "../../../img/left-arrow.png";


function Uploadppt() {
    const { id } = useParams();
    const history = useHistory();
    const [val, setVal] = useState("");
    const [vals, setVals] = useState("");
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [images, setImages] = useState([]);
    const [uploadedmessage, setUploadedmessage] = useState("");
    const [uploadedmessages, setUploadedmessages] = useState("");
    const d = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    var sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;
    const handleChange = e => {
        const tt = sanitizeUrl(e.target.value)
        setVal(tt);
    }
    const handleChanges = e => {
        const tt = sanitizeUrl(e.target.value)
        setVals(tt);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const riurl = `https://demo.reachum.com/pptshowroom/${id}/${d}`;
        const erurl = `https://demo.reachum.com/pptedit/${id}/${d}`;
        try {
                const urldata = {
                    rid:d,
                    title:"Webpage",
                    lurl: vals,
                    rurl: riurl,
                    eurl: erurl
                };
            const allImages = [...images, urldata]
            const input = {
                id,
                qrstring:val,
                playlistlinks: allImages
            }
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
            setUploadedmessage("Uploading...")
            setTimeout(()=> {
                setUploadedmessage("")
                history.push(`/editroom/${id}`);
            },1000)
        }catch(err){
            console.log(err)
        }
    }

    function scp(){
        history.push(`/editroom/${id}`);
    }
    return (
        isOwner === isUser ?
            <>
                <Helmet>
                    <title>UPLOAD URL</title>
                </Helmet>
                <Navbars />
                <div className="uploadurl">
                    <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
                    <br/>
                    <br/>
                    <h3>Powerpoint Live</h3>
                    <form onSubmit={handleSubmit}>
                        <p>Presentor URL</p>
                        <div className="form-row">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                                        <input onChange={handleChange} className="form-control urlsform" name="rurl" placeholder="URL" />
                                </Grid>
                                        <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </div>
                        <br/>
                        <p>Viewer URL</p>
                        <div className="form-row">
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                                    <input onChange={handleChanges} className="form-control urlsform" name="lurl" placeholder="Secure URL"/>
                                </Grid>
                                <Grid item xs={3}>
                                    <input type="submit" value="Submit" className="inputstyles"/>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                    <p className="uploaded">{uploadedmessages}</p>
                </div>
                <Foot />
                </>
            :""
    );
}

export default Uploadppt;
