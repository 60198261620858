import { useRef, useEffect, forwardRef } from 'react'
import Resizer from "react-image-file-resizer";
import DesmosCalculator from '../DesmosCalculator';

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            400,
            400,
            "JPEG",
            95,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

export default function DesmosExporter ({
                                            initialize,
                                            desmosState,
                                            setDesmosState,
                                            itemId
                                        }) {
    const calculatorRef = useRef(null)
    async function checkRef() {
        const screenshot = calculatorRef.current.screenshot()
        const response = await fetch(screenshot)
        const blob = await response.blob()
        const resized = await resizeFile(blob)
        return resized
    }
    useEffect(() => {
        initialize(checkRef, calculatorRef)
        calculatorRef.current.observeEvent('change', (eventName, event) => {
            const state = calculatorRef.current.getState()
            const { randomSeed, ...rest } = state
            const serializedState = JSON.stringify(rest)
            setDesmosState(itemId, serializedState)
        })

        return () => {
            calculatorRef.current.unobserveEvent('change')
        }
    }, [itemId])
    useEffect(() => {
        if (desmosState == null) {
            return
        }
        const currentState = calculatorRef.current.getState()
        const { randomSeed, ...rest } = currentState
        const currentJson = JSON.stringify(rest)
        const sameJson = currentJson === desmosState
        if (sameJson) {
            return
        }
        calculatorRef.current.setState(desmosState)
    }, [desmosState])
    return (
        <>
            <DesmosCalculator ref={calculatorRef} />
        </>
    )
}
