import React from "react";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import "../css/login.css";


const Barlogin = () => {
    return (
        <>
            <br/>
            <Authenticator loginMechanisms={['email']} hideSignUp={true}>
            </Authenticator>
        </>
    )
}

export default Barlogin
