import ConvertAPI from 'convertapi';
import { config } from '../../config'

const convertApi = new ConvertAPI(config.convert.secret);

export default async function convertPptxToJpeg(fileUrl) {
  const params = {
    'File': fileUrl,
    'ScaleImage': 'true',
    'ScaleProportions': 'true',
    'ImageWidth': '1080',
    'ImageQuality':'88'
  };
  const result = await convertApi.convert('webp', params)
  return result.files.map(({ fileInfo }) => {
    const { FileId: fileId, Url: url } = fileInfo;
    return { name: fileId, url }});
}
