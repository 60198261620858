import React, { useState, useRef, useEffect } from 'react';
import {API, Auth} from 'aws-amplify';
import {useParams,useHistory} from "react-router-dom";
import DesmosCalculator from "./desmosCalculator";
import {createDesmomath, updateDesmomath} from "../../graphql/mutations";


const  Desmos = ()  =>{
    const { id } = useParams();
    const aRef = useRef(null)
    useEffect(() => {
        (async() => {
            const input = {
                id
            }
            await API.graphql({
                query: createDesmomath,
                variables: {input}
            });
        })();
        aRef.current.observeEvent('change', async () => {
            const aState = aRef.current.getState();
            const thisstate = JSON.stringify(aState);
            const input = {
                id,
                mathstate:thisstate
            }
            await API.graphql({
                query: updateDesmomath,
                variables: {input}
            });
        })

        return () => {
            aRef.current.unobserveEvent('change')
        }
    })
    return (
        <>
            <div>
                <DesmosCalculator ref={aRef} />
            </div>
        </>
    );
}

export default Desmos;
