import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {createOptionsdrawlist, updatePlayslide} from '../../../graphql/mutations';
import { useForm, useFieldArray } from "react-hook-form";
import {Form,Table} from "react-bootstrap";
import {getPlayslide} from "../../../graphql/queries";
import Navbars from "../../Navbar";
import Foot from "../../Foot";
import {useParams,useHistory} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../../css/poll.css";
import DeleteIcon from "../../../img/dash/Delete-Button-X.png";
import Add from "../../../img/dash/Add-Button.png";
import {Helmet} from "react-helmet";
import  Draws  from "./draw";
import Leftarrow from "../../../img/left-arrow.png";
import Grid from "@material-ui/core/Grid";


function Drawoptions() {
	const { id } = useParams();
	const history = useHistory();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [pchecked, setPchecked] = useState();
	const [imgdata, setImgdata] = useState("")
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, []);

	const {register, control, handleSubmit} = useForm({
		defaultValues: {
			ptest: [{panswer: ""}]
		}
	});

	const {fields, append, remove} = useFieldArray(
		{
			control,
			name: "ptest"
		}
	);
	const reorderurl = `https://demo.reachum.com/drawoptionshowroom/${id}/${d}`;
	const erurl = `https://demo.reachum.com/drawoptionsedit/${id}/${d}`;
	const ccurl = `https://demo.reachum.com/drawcorrctanswer/${id}/${d}`
	const ileaderboardurl = `https://demo.reachum.com/leaderboard/${id}`;
	const tt = `${d}1`;
	const ccu = `${d}333`;
	const handleChildData = (data) => {
		setImgdata(data)
	};
	const onSubmit = async (data) => {
		if(pchecked) {
			try {
				const input = {
					id: d,
					pfname: data.pfname,
					ptimer: data.ptimer,
					pquestion: data.pquestion,
					ptest: data.ptest,
					drawques:imgdata,
					oanswer: pchecked
				}
				await API.graphql({
					query: createOptionsdrawlist,
					variables: {input}
				});
				const urldata = {
					rid: d,
					title: data.pfname,
					rurl: reorderurl,
					eurl:erurl
				};
				const leaderboard = {
					rid:tt,
					title:"leaderboard",
					rurl:ileaderboardurl
				}
				const ccorct = {
					rid:ccu,
					title:"correct answer",
					rurl:ccurl
				}
				const allImages = [...images, urldata, ccorct, leaderboard]
				const inputs = {
					id,
					playlistlinks: allImages
				}
				await API.graphql({
					query: updatePlayslide,
					variables: {input: inputs}
				})
				setTimeout(() => {
					setUploadedmessage("The file added to the content list.")
					history.push(`/editroom/${id}`);
				}, 500)
			} catch (err) {
				console.log(err)
			}
		}else{
			const thism = "Please check the correct one. "
			setUploadedmessage(thism)
		}
	}

	function scp(){
		history.push(`/editroom/${id}`);
	}
	return (
		isOwner === isUser ?
		<>
			<Helmet>
				<title>Draw Quiz</title>
			</Helmet>
			<Navbars />
			<div className="orderlist">
				<h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
				<br/>
				<div className="thiscenter">
					<h3>Draw quiz and choose one answer option</h3>
				</div>
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Game Name : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<input
					name="pfname"
					defaultValue=""
					ref={register({ required: true, maxLength:100 })}
					className="ques"
				/>
				</Grid>
			</Grid>
<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Timer (Seconds) : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<input
					name="ptimer"
					defaultValue=""
					ref={register({valueAsNumber: true, maxLength:3})}
					className="timers"
					type="number"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Question : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<textarea
					name="pquestion"
					defaultValue=""
					ref={register({ required: true, maxLength:200 })}
					className="ques"
				/>
				</Grid>
			</Grid>
			<br/>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<div className="centerthis">
				<span className="redthis">After clicking the submit button, the whiteboard cannot be changed.</span>
				<Draws onChildData={handleChildData} />
				</div>
			</Form.Group>
			<Form.Group className="mb-3" controlId="formBasicPassword">
				<Form.Label>Answers and check the correct one</Form.Label>
			<ol>
				{fields.map((item, index) => {
					return (
						<>
							<Table borderless>
								<tr>
						<li key={item.id}>
							<td>
								<input
									name={`ptest[${index}].id`}
									defaultValue={`${index}`} // make sure to set up defaultValue
									ref={register()}
									type="hidden"
								/>
								<input
									name="options"
									defaultValue={`${index}`} // make sure to set up defaultValue
									ref={register()}
									type="radio"
									onChange={()=> {
										setPchecked(`${index}`)
									}}
									className="singleradio"
								/>
							<input
								name={`ptest[${index}].panswer`}
								defaultValue={`${item.panswer}`} // make sure to set up defaultValue
								ref={register({maxLength:100})}
								className="orderlistinput"
							/>
							</td>
							<td className="polldelete">
								<img src={DeleteIcon} onClick={() => remove(index)} width="30" height="30" />
							</td>
						</li>
								</tr>
							</Table>
						</>
					);
				})}
			</ol>
				<div>
				<img src={Add} id="polladdsb" onClick={() => {
					append({ panswer: "" });
				}} />
				</div>
			</Form.Group>
			<hr/>
			<br/>
			<Table>
				<tr>
					<td>
					</td>
				<td className="orderright">
					{uploadedmessage} <input type="submit" value="Submit" id="summitb" />
				</td>
				</tr>
			</Table>
		</form>
			</div>
			<Foot />
			</>
			:""
	);
}
export default Drawoptions;
