import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../../graphql/mutations';
import { getPlayslide } from "../../../graphql/queries";
import {useParams,useHistory} from "react-router-dom";
import { UploadFiles } from './FileUpload';
import { processFile } from './fileService';
import Navbars from "../../Navbar";
import Foot from "../../Foot";
import "../../css/uploadimg.css";
import {Card} from "react-bootstrap";
import {v4 as uuid} from "uuid";
import {Helmet} from "react-helmet";
import Leftarrow from "../../../img/left-arrow.png";

const allowedMediaSourceTypes = ['.jpeg','.png','.jpg','.svg','.webp','.bmp','.tif','.tiff','.eps'];

function Uploadimg() {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [uploadedmessage, setUploadedmessage] = useState("");
    const { id } = useParams();
    const history = useHistory();
    const [images, setImages] = useState([]);
    const [error, setError] = useState('');
    const rid = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);

    const upload = async (files) => {
        setProcessing(true);
        setError('');
        let arrays = [];
        for (var j = 0; j < files.length; j++) {
            try {
                const MAX_FILE_SIZE = 5120;
                const file = files[j];
                const fileSizeKiloBytes = file.size / 1024;
                if(fileSizeKiloBytes > MAX_FILE_SIZE){
                    setError("File size is greater than maximum limit");
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
                const processedFile = await processFile(file);
                const results = processedFile.images;
                const pptxtoimage = results.map(({name, location}) => {
                    return {
                        title: "image",
                        lurl: `https://demoreachumv1153535-dev.s3.amazonaws.com/public/img/${name}`
                    };
                })
                const newpptx = pptxtoimage.map((r, i) => ({
                    ...r,
                    rid: `img${rid}${j}`,
                    rurl: `https://demo.reachum.com/imgshowroom/${id}/img${rid}${j}`,
                    eurl: `https://demo.reachum.com/imgedit/${id}/img${rid}${j}`
                }));
                arrays.push(newpptx[0]);
            } catch (err) {
                console.error(err);
                setError(`Unable to process file`)
            }
        }
        setProcessing(false);
        const allImages = [...images].concat(arrays);
        const input = {
            id,
            playlistlinks: allImages
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setTimeout(() => {
            setUploadedmessage("The file added to the content list.")
            history.push(`/editroom/${id}`);
        }, 1000)
    }

    function scp(){
        history.push(`/editroom/${id}`);
    }
    return (
        isOwner === isUser ?
        <>
            <Helmet>
            <title>UPLOAD IMAGE</title>
        </Helmet>
            <Navbars />
            <div className="uploadpptx">
                <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
                <br/>
                <br/>
                <Card className="text-center">
                    <Card.Header as="h6">Upload Image (5MB limit) <br/> You can upload multiple images at once.</Card.Header>
                    <Card.Body>
                    {error && <h5>{error}</h5>}
                    <UploadFiles processing={processing} allowedFileTypes={allowedMediaSourceTypes} onUpload={upload} />
                        <p className="redwar">{uploadedmessage}</p>
                    </Card.Body>
                </Card>
            </div>
            <Foot />
        </>
            :""
    );
}

export default Uploadimg;
