import { version } from '../../package.json';

export const config = {
  app: {
    env: process.env.REACT_APP_ENVIRONMENT || 'development',
    port: parseInt(process.env.PORT || '3000', 10),
    version,
  },
  convert: {
    secret: 'xnTvXl2dNspHn4k4'
  },
  giphy: {
    apiKey: 'fsjDw2hGzjhhEJJV73DPKgfqWCVxr5Wm'
  }
}
