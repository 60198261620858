import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {createOptionslist, updatePlayslide} from '../../../graphql/mutations';
import { useForm, useFieldArray } from "react-hook-form";
import {Form,Table} from "react-bootstrap";
import {getPlayslide} from "../../../graphql/queries";
import Navbars from "../../Navbar";
import Foot from "../../Foot";
import {useParams,useHistory} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../../css/poll.css";
import DeleteIcon from "../../../img/dash/Delete-Button-X.png";
import Add from "../../../img/dash/Add-Button.png";
import {Helmet} from "react-helmet";
import Leftarrow from "../../../img/left-arrow.png";
import Grid from "@material-ui/core/Grid";
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			250,
			250,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
function Createoptions() {
	const { id } = useParams();
	const history = useHistory();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [pchecked, setPchecked] = useState();
	const [thisqueimage, setThisqueimage] = useState((""))
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, []);
	const {register, control, handleSubmit, setValue, getValues} = useForm({
		defaultValues: {
			ptest: [{panswer: "",image:""}]
		}
	});
	const {fields, append, remove} = useFieldArray(
		{
			control,
			name: "ptest"
		}
	);
	const reorderurl = `https://demo.reachum.com/optionshowroom/${id}/${d}`;
	const erurl = `https://demo.reachum.com/optionsedit/${id}/${d}`;
	const ccurl = `https://demo.reachum.com/onecorrctanswer/${id}/${d}`;
	const ileaderboardurl = `https://demo.reachum.com/leaderboard/${id}`;
	const tt = `${d}1`;
	const ccu = `${d}333`;
	const onSubmit = async (data) => {
		if(pchecked) {
			try {
				const input = {
					id: d,
					pfname: data.pfname,
					ptimer: data.ptimer,
					pquestion: data.pquestion,
					image: thisqueimage,
					ptest: data.ptest,
					oanswer: pchecked
				}
				console.log("input", input)
				/*await API.graphql({
					query: createOptionslist,
					variables: {input}
				});
				const urldata = {
					rid: d,
					title: data.pfname,
					rurl: reorderurl,
					eurl: erurl
				};
				const leaderboard = {
					rid:tt,
					title:"leaderboard",
					rurl:ileaderboardurl
				}
				const correcta = {
					rid:ccu,
					title:"correct answer",
					rurl: ccurl
				}
				const allImages = [...images, urldata, correcta,leaderboard]
				const inputs = {
					id,
					playlistlinks: allImages
				}
				await API.graphql({
					query: updatePlayslide,
					variables: {input: inputs}
				})
				setTimeout(() => {
					setUploadedmessage("The file added to the content list.")
					history.push(`/editroom/${id}`);
				}, 500)*/
			} catch (err) {
				console.log(err)
			}
		}else{
			const thism = "Please check the correct one. "
			setUploadedmessage(thism)
		}
	}

	function scp(){
		history.push(`/editroom/${id}`);
	}


	async function handleFileChange(e, index) {
		if (e.target.files && e.target.files.length > 0) {
			const image = await resizeFile(e.target.files[0]);
			console.log("image",`ptest[${index}].image`, image)
			setValue(`ptest[${index}].image`, image)
		}
	}
	async function handleFilequesChange(e) {
		if (e.target.files && e.target.files.length > 0) {
			const image = await resizeFile(e.target.files[0]);
			setThisqueimage(image)
		}
	}
	return (
		isOwner === isUser ?
		<>
			<Helmet>
				<title>Select one answer option</title>
			</Helmet>
			<Navbars />
			<div className="orderlist">
				<h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
				<br/>
				<div className="thiscenter">
					<h3>Single select</h3>
				</div>
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={3}>
			Game Name : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<input
					name="pfname"
					defaultValue=""
					ref={register({ required: true, maxLength: 200 })}
					className="ques"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Timer (Seconds) : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<input
					name="ptimer"
					defaultValue=""
					ref={register({valueAsNumber: true, maxLength:3})}
					className="timers"
					type="number"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
			<Grid item xs={3}>
				Question : &nbsp;
			</Grid>
				<Grid item xs={9}>
				<textarea
					name="pquestion"
					defaultValue=""
					ref={register({ required: true, maxLength: 200 })}
					className="ques"
				/>
				</Grid>
			</Grid>
			<div>
				<input
					type="file"
					accept="image/*"
					onChange={handleFilequesChange} />
				<img src={thisqueimage} style={{ width: '400px' }}/>
			</div>
			<br/>
			<Form.Group className="mb-3" controlId="formBasicPassword">
				<Form.Label>Answers and check the correct one</Form.Label>
			<ol>
				{fields.map((item, index) => {
					return (
						<>
							<Table borderless>
								<tr>
						<li key={item.id}>
							<td>
								<input
									name={`ptest[${index}].id`}
									defaultValue={`${index}`} // make sure to set up defaultValue
									ref={register()}
									type="hidden"
								/>
								<input
									name="options"
									defaultValue={`${index}`} // make sure to set up defaultValue
									ref={register()}
									type="radio"
									onChange={()=> {
										setPchecked(`${index}`)
									}}
									className="singleradio"
								/>
							<input
								name={`ptest[${index}].panswer`}
								defaultValue={`${item.panswer}`} // make sure to set up defaultValue
								ref={register({maxLength: 200})}
								className="orderlistinput"
							/>
							</td>
							<td>
								<p>Upload image:</p>
								<input type="file" {...register("image")} />
							</td>
							<td className="polldelete">
								<img src={DeleteIcon} onClick={() => remove(index)} width="30" height="30" />
							</td>
						</li>
								</tr>
							</Table>
						</>
					);
				})}
			</ol>
				<div>
				<img src={Add} id="polladdsb" onClick={() => {
					append({ panswer: "", image:null });
				}} />
				</div>
			</Form.Group>
			<hr/>
			<br/>
			<Table>
				<tr>
					<td>
					</td>
				<td className="orderright">
					{uploadedmessage} <input type="submit" value="Submit" id="summitb" />
				</td>
				</tr>
			</Table>
		</form>
			</div>
			<Foot />
			</>
			:""
	);
}
export default Createoptions;
