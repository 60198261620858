import React, {useEffect,useState} from "react";
import { API } from 'aws-amplify';
import {getPlayslide} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Image} from "react-bootstrap";
import "../css/imgshowroom.css";
function Gifshowroom() {
    const [jvfile, setVjfile] = useState();
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = response.data.getPlayslide.playlistlinks;
        const newdatas = datas.filter(data => data.rid === rid);
        const gurl = newdatas[0].lurl;
        setVjfile(gurl);
    }

    return (
        <>
            <div className="imgshows">
                <Image src={jvfile} fluid className="imgviewsite" width="480"/>
            </div>
        </>
    );
}
export default Gifshowroom;
