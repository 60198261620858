import React, {useEffect, useState, useRef} from "react";
import {API, Auth} from 'aws-amplify';
import {createMultioptionsdesmoslist, updatePlayslide} from '../../../../graphql/mutations';
import { useForm } from "react-hook-form";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {getPlayslide} from "../../../../graphql/queries";
import Navbars from "../../../Navbar";
import Foot from "../../../Foot";
import {useParams,useHistory} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../../../css/poll.css";
import DeleteIcon from "../../../../img/dash/Delete-Button-X.png";
import Add from "../../../../img/dash/Add-Button.png";
import {Helmet} from "react-helmet";
import Leftarrow from "../../../../img/left-arrow.png";
import Grid from "@material-ui/core/Grid";
import DesmosCalculator from '../DesmosCalculator';
import Resizer from "react-image-file-resizer";
import Mathfield from "../../../mathslide/Mathfield";

const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			400,
			400,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});

function Createdesmosmultioptions() {
	const { id } = useParams();
	const history = useHistory();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [options, setOptions] = useState([]);
	const [images, setImages] = useState([]);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [newOption, setNewOption] = useState(''); // Input field for adding new options
	const addRef = useRef(null);
	const [selectedFile, setSelectedFile] = useState("");
	const [mathInput, setMathInput] = useState('')
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, []);
	const {register, control, handleSubmit} = useForm({
		defaultValues: {
			ptest: [{panswer: ""}]
		}
	});
	const handleAddOption = async () => {
		// Add a new option to the options state
		if (newOption.trim() !== '') {
			const screenshot = addRef.current.screenshot()
			const response = await fetch(screenshot)
			const blob = await response.blob()
			const resized = await resizeFile(blob)
			setOptions([...options, { panswer: newOption, checkans: false, mathstate: resized }]);
			setNewOption('');
		}
	};
	const handleOptionChange = (index) => {
		// Toggle the checkbox state for the selected option
		const updatedOptions = [...options];
		updatedOptions[index].checkans = !updatedOptions[index].checkans;
		setOptions(updatedOptions);
	};
	function handleMathInputChange (value) {
		setMathInput(value);
	}
	const handleFileChange = async (event) => {
		const img = event.target.files[0];
		const resized = await resizeFile(img);
		setSelectedFile(resized)
	};
	const handleDeleteOption = (index) => {
		// Remove the selected option from the options state
		const updatedOptions = [...options];
		updatedOptions.splice(index, 1);
		setOptions(updatedOptions);
	};
	const reorderurl = `https://demo.reachum.com/multidesmosshowroom/${id}/${d}`;
	const erurl = `https://demo.reachum.com/multidesmosedit/${id}/${d}`;
	const ccurl = `https://demo.reachum.com/multidesmoscorrctanswer/${id}/${d}`;
	const ileaderboardurl = `https://demo.reachum.com/leaderboard/${id}`;
	const tt = `${d}1`;
	const ccu = `${d}333`;

	const onSubmit = async (data) => {
		const arrayOfObjectsWithId = options.map((obj, index) => ({ id: index, ...obj }));
		const arrayOfObjectsWithoutcheckbox = arrayOfObjectsWithId.map(({ checkans, ...rest }) => rest);
		const arrayOfObjects = options.map(({ panswer,mathstate, ...rest }) => rest);
		if(options.length !== 0) {
			try {
				const input = {
					id: d,
					mfname: data.pfname,
					mtimer: data.ptimer,
					mtest: arrayOfObjectsWithoutcheckbox,
					mquestion:data.pquestion,
					manswer: arrayOfObjects,
					mathstate:mathInput,
					image:selectedFile
				}
		        await API.graphql({
					query: createMultioptionsdesmoslist,
					variables: {input}
				});
				const urldata = {
					rid: d,
					title: data.pquestion,
					rurl: reorderurl,
					eurl: erurl
				};
				const leaderboard = {
					rid:tt,
					title:"leaderboard",
					rurl:ileaderboardurl
				}
				const correcta = {
					rid:ccu,
					title:"correct answer",
					rurl: ccurl
				}
				const allImages = [...images, urldata, correcta, leaderboard]
				const inputs = {
					id,
					playlistlinks: allImages
				}
				await API.graphql({
					query: updatePlayslide,
					variables: {input: inputs}
				})
				setTimeout(() => {
					history.push(`/editroom/${id}`);
				}, 800)
			} catch (err) {
				console.log(err)
			}
		}else{
			const thism = "Please check the correct ones. "
			setUploadedmessage(thism)
		}
	}

	function scp(){
		history.push(`/editroom/${id}`);
	}
	return (
		isOwner === isUser ?
		<>
			<Helmet>
				<title>Single Selection or Multiple Selection</title>
			</Helmet>
			<Navbars />
			<div className="dragdroppage">
				<br/>
				<h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
				<br/>
				<div className="thiscenter">
					<h3>Multi Select</h3>
				</div>
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={3}>
					Game Name: &nbsp;
				</Grid>
				<Grid item xs={9}>
					<input
						name="pfname"
						defaultValue=""
						ref={register({ required: true, maxLength:100 })}
						className="quesmath"
					/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
			Timer (Seconds) : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<input
					name="ptimer"
					defaultValue=""
					ref={register({valueAsNumber: true, maxLength:3})}
					className="timers"
					type="number"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
			Question : &nbsp;
				</Grid>
				<Grid item xs={9}>
				<textarea
					name="pquestion"
					defaultValue=""
					ref={register({ required: true, maxLength:200 })}
					className="quesmathinput"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2}>
					<p>Upload image:(option) </p>
				</Grid>
				<Grid item xs={10} className="leftside">
					<input type="file" onChange={handleFileChange}/>
				</Grid>
			</Grid>
			<div className="mathcccenter">
				<img src={selectedFile} className="pximages400"/>
			</div>
			<br/>
			<Grid container spacing={0}>
				<Grid container spacing={0}>
					<Grid item xs={3}>
						<p>Type math formulas: &nbsp; &nbsp; &nbsp;</p>
					</Grid>
					<Grid item xs={9} className="leftside">
						<div className="pxsize500">
							<Mathfield value={mathInput} onChange={handleMathInputChange}/>
						</div>
					</Grid>
				</Grid>
			</Grid>
			<br/>
			<br/>
			<div className="centerside">
				<p className="redthis">Once submitted, you won't be able to make changes to the graphics.
					<br/>Please review and verify the correct selections<br/> before clicking the 'Submit' button.
					</p>
				<h4>Options:</h4>
			</div>
				{options.map((option, index) => (
					<div key={index} className="centerside">
						<Grid container spacing={0}>
							<Grid item xs={10}>
						<label>
							<Grid container spacing={0}>
								<Grid item xs={2} className="rightside">
							<input
								type="checkbox"
								checked={option.checkans}
								onChange={() => handleOptionChange(index)}
								className="multicheckbox"
							/>
								</Grid>
								<Grid item xs={5}>
									<p>{option.panswer}</p>
								</Grid>
								<Grid item xs={5}>
							<img src={option.mathstate} className="borderthis" alt="graphics"/>
								</Grid>
							</Grid>
						</label>
							</Grid>
							<Grid item xs={2} className="leftside">
								<img src={DeleteIcon} onClick={() => handleDeleteOption(index)} alt="delete"/>
							</Grid>
						</Grid>
						<hr/>
					</div>
				))}
			<hr/>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={11} className="rightside">
					{uploadedmessage} <input type="submit" value="Submit" id="summitb" />
				</Grid>
				<Grid item xs={1}>
				</Grid>
			</Grid>

		</form>
				<div>
					<h4>Add Options:</h4>
					<input
						type="text"
						placeholder="Text required"
						value={newOption}
						onChange={(e) => setNewOption(e.target.value)}
						className="quesmath"
					/>
					<br/>
					<br/>
					<DesmosCalculator ref={addRef} />
					<br/>
					<Grid container spacing={0}>
						<Grid item xs={1} className="rightside">
						</Grid>
						<Grid item xs={11}>
							<div className="upgradenew3" id="3">
								<img src={Add} onClick={handleAddOption} width="40px"/>
								<span className="tooltiptext3">Click here to add options.</span>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
			<Foot />
			</>
			:""
	);
}
export default Createdesmosmultioptions;
