import React, { useState} from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './logo.png';
import "./css/Navbar.css"

function Navbars() {
    return (
        <>
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="https://reachum.com"><img src={logo} alt="Reachum" className="logos"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav activeKey="https://demo.reachum.com" className="navmenus">
                        <Nav className="me-auto">
                            <Nav.Link href="https://demo.reachum.com"><p className="navthissub">Dashboard</p></Nav.Link>
                            <NavDropdown title="Help" id="basic-nav-dropdown">
                                <NavDropdown.Item href="https://demo.reachum.com/support"><p className="navthissub">Support</p></NavDropdown.Item>
                                <NavDropdown.Item href="https://demo.reachum.com/tutorials">
                                    <p className="navthissub">Tutorials</p>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/profile"><p className="navthissub">My Account</p></Nav.Link>
                            <Nav.Link href="https://demo.reachum.com/pricing"><p className="greenthissub">Subscribe</p></Nav.Link>
                        </Nav>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
    );
}

export default Navbars;
