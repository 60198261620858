import React from "react";
import { Excalidraw } from "@excalidraw/excalidraw";

function Draws() {
    return (
        <>
                <div style={{ height: "100vh" }}>
                    <Excalidraw />
                </div>
        </>
    );
}

export default Draws
