import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {listEngagements} from '../../../graphql/queries';
import { Doughnut } from 'react-chartjs-2';
import "../style.css";
import Grid from "@material-ui/core/Grid";


function Responserate(props) {
    const  id  = props.data;
    const [engagenumber, setEngagenumber] = useState("");
    const [engagenumberno, setEngagenumberno] = useState("No");
    const [engagenumbera, setEngagenumbera] = useState("");
    const [engagenumbernoa, setEngagenumbernoa] = useState("");
    useEffect(() => {
        fetchengagedata()
    }, []);
    async function fetchengagedata() {
        const peoples = [];
        const engages = [];
        try {
            const response = await API.graphql({
                query: listEngagements,
                variables: {
                    filter: {
                        "questionid": {
                            eq: id
                        }
                    },
                    limit: 10000
                }
            })
            const datas = response.data.listEngagements.items;
            if (datas.length > 0) {
                datas.forEach((data) => {
                    const listdata = data.people;
                    peoples.push(listdata)
                })
                const s = peoples.length;
                datas.forEach((data) => {
                    const listdata = data.engage;
                    engages.push(listdata)
                })
                const sum = engages.reduce((partialSum, a) => partialSum + a, 0);
                const numObj = (sum / s) * 100;
                const eas = Math.floor(Number.parseFloat(numObj));
                setEngagenumbera(eas);
                const ea = Number.parseFloat(numObj).toFixed(1) + "%";
                setEngagenumber(ea);
                const ss = (100 - Number.parseFloat(numObj).toFixed(1)) + "%";
                if(ss !== "0%") {
                    setEngagenumberno(ss)
                }
                const sss = 100 - eas;
                setEngagenumbernoa(sss)
            } else {
                const tt = "100%";
                setEngagenumber(tt);
                setEngagenumbera(100)
            }

        } catch (err) {
            console.log(err)
        }
    }
    const data = {
        labels: ['Response','No Response'],
        datasets: [
            {
                data: [ engagenumbera, engagenumbernoa ],
                backgroundColor: [
                    '#269b7b',
                    'white'
                ],
                borderColor: 'black',
            },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: true,
    };
    function goweb(){
       window.open("https://reachum.com/contact/", "_blank")
    }
    return (
        <>
                        <div className="doughhutsize">
                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <div className="engageheightright">
                                        <h3>{engagenumberno} <br/> students <br/> did not <br/> respond.</h3>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                            <Doughnut data={data} options={options} />
                                </Grid>
                                <Grid item xs={4}>
                                   <div className="engageheight">
                                    <h3>{engagenumber}</h3>
                                   </div>
                                </Grid>
                            </Grid>
                            <br/>
                            <br/>
                            <br/>
                            <h5 className="underline" onClick={goweb}> How can I improve this? </h5>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </>
    )
}

export default Responserate;
