import { useDroppable } from "@dnd-kit/core";

export default function DesktopView({ children }) {
    const {
        setNodeRef,
    } = useDroppable({
        id: 'desktop'
    });
    return (
        <div
            ref={setNodeRef}
            style={{
                background: '#F5F5F5',
                minHeight: '50px',
                marginBottom: '10px'
            }}
        >
            {/* <SortableContext items={sortableItems}> */}
            {children}
            {/* </SortableContext> */}
        </div>
    )
}
