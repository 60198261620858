import React, {useState, useEffect} from 'react'
import Showitemlist from './showitemlist'
import {API, Auth} from "aws-amplify";
import {getPlayslide, getSequencinggame} from "../../../graphql/queries";
import {Form, Input} from "element-react";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../css/taptap.css";
import Cheers from "../../../img/tontonfriends-cheers.gif";
import Playsequcing from "../../../img/menu/dis/sequrncing.gif"

export default function Showsequencing() {
  const [isOpen, setIsOpen] = useState(true);
  const [isgameopen, setIsgameopen] = useState(false)
  const [quest, setQuest] = useState();
  const { id, rid } = useParams();
  const [thiscate, setThiscate] = useState([]);
  const [counter, setCounter] = useState();
  const [stateData, setStateData] = useState({
    meetname:""
  })

  useEffect(() => {
    fetchDas();
  }, []);
  async function fetchDas() {
    try {
      const response = await API.graphql({
        query: getSequencinggame,
        variables: {id:rid}
      })
      const datas = response.data.getSequencinggame;
      const timers = datas.dtimer;
      setCounter(timers)
      const ques = datas.dquestion;
      setQuest(ques);
      const caes = datas.orders;
      setThiscate(caes);
    }catch(err){
      console.log(err)
    }
  }
  function savethisdata(){
    sessionStorage.setItem("sequencinggames",JSON.stringify(thiscate));
    sessionStorage.setItem("sequencinggamescounter", counter);
    sessionStorage.setItem("sequencinggamesques", quest);
    setIsgameopen(true);
  }

  return (
      <div>
              {isgameopen ?
                  <>
                 <Showitemlist />
                  </>
                  :
                  <div className="centerthis">
                    <br/>
                    <h3> Sequencing </h3>
                    <img src={Playsequcing} className="playsquencingborder"/>
                    <br/>
                    <br/>
                    <p>Tap to select an item,</p>
                      <p>then tap again to place the item
                      in the proper slot in the sequence.</p>

                      <p>Tapping an occupied slot replaces the
                      earlier selection.</p>

                     <p> To clear all slots, click the “Reset” button.
                    </p>
                    <br/>
                    <Button onClick={savethisdata}>CLICK TO START</Button>
                  </div>
              }
      </div>

  )
}
