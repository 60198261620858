import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getPlayslide, listEngagements, listLeaderboards} from '../../../graphql/queries';
import "../../css/leaderboard.css";
import {deleteEngagement, deleteLeaderboard} from "../../../graphql/mutations";


function Cdtimetracking(props) {
    const  id  = props.data;
    const [roomname, setRoomname] = useState("");
    const [thisdatas, setThisdatas] = useState([]);
    useEffect(() => {
        (async () => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname)
        })()
        fetchDas();
    }, []);

    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "roomid": {
                            eq: id
                        }
                    },
                    limit: 10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            setThisdatas(datas);
        } catch (err) {
            console.log(err)
        }
    }

    async function downloadtimerdata() {
        const response1 = await API.graphql({
            query: listLeaderboards,
            variables: {
                filter: {
                    "roomid": {
                        eq: id
                    }
                },
                limit: 10000
            }
        })
        const datas = response1.data.listLeaderboards;
        const d = datas.items;
        if (d.length > 0) {
            const transformedArray = d.map(obj => ({
                ...obj
            }));
            const header = 'Name,Email,Total Duration(min), TimeIn, TimeOut\n'; // Replace with your desired header row
            const csv = transformedArray.reduce((accumulator, item) => {
                return `${accumulator}${item.name},${item.meetemail},${item.duration},${item.timerin},${item.timerout}\n`;
            }, header);
            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${roomname}-timeTracking.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            alert("No Data.")
        }
    }

    const clearresults = async () => {
        let text = "Are you sure you want to delete all players? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                let cl = thisdatas.length;
                for (let i = 0; i < cl; i++) {
                    const input = {
                        id: thisdatas[i].id
                    };
                    await API.graphql({
                        query: deleteLeaderboard,
                        variables: {input}
                    });
                }
            } catch (err) {
                console.log(err)
            }
            try {
                const response = await API.graphql({
                    query: listEngagements,
                    variables: {
                        filter: {
                            "questionid": {
                                eq: id
                            }
                        },
                        limit: 10000
                    }
                })
                const datas = response.data.listEngagements.items;
                const datalength = datas.length;
                for (var j = 0; j < datalength; j++) {
                    const input = {
                        id: datas[j].id
                    };
                    await API.graphql({
                        query: deleteEngagement,
                        variables: {input}
                    });
                }
            } catch (err) {
                console.log(err)
            }
            window.location.reload();
        }
    }
    return (
        <>
                            <div className="leaderpage">
                                    <div>
                                        <button onClick={downloadtimerdata} className="savespread">Save as CSV File
                                        </button>
                                        <br/>
                                        <br/>
                                        <button onClick={clearresults} className="clearresub">Clear Results</button>
                                    </div>
                            </div>

        </>
    )
}

export default Cdtimetracking;
