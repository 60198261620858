import React, {useState} from "react";
import ReactCountdownClock from "react-countdown-clock";
import Timesout from "./timer.gif";
import "./css/timer.css";

function Timer() {
    const [isOpen, setIsOpen] = useState(true);
   const thistimeout =()=>{
    setIsOpen(false);
   }
    return (
                <div className="timerpage">
                    {isOpen ?
                        <div className="timerg">
                            <ReactCountdownClock seconds={60}
                                                 color="#2BBECE"
                                                 alpha={0.9}
                                                 size={240}
                                                 weight={40}
                                                 onComplete={thistimeout}/>
                        </div>
                        :
                            <img src={Timesout} className="timerimg"/>
                    }

        </div>
    )
}

export default Timer;
