import { useDroppable } from '@dnd-kit/core';
import React, { useState } from 'react'
import { SortableItem } from './SortableItem';
import Subfolders from './Subfolders';
import Grid from "@material-ui/core/Grid";
import Editbs from "./icons/Edit.svg";
import DeleteIcon from './icons/Delete.svg';
import Rightarrow from "./icons/Polygonmini.svg";
import Downarrow from "./icons/Polygon.svg";
import Foldericon from "./icons/Folder.svg";
import Subfoldericon from "./icons/Sub-FolderIcon.svg";

export default function Folder({
  folder,
  changeFolderName,
  deleteFolder,
  toggleSelect,
  selectedIds,
  createSubfolder,
  folders,
  subfolder,
 handleDelete,
                                 onIdClick
}) {
  const [newName, setNewName] = useState(folder.name);
  const [expanditems, setExpanditems] = useState(true)
  const {
    setNodeRef,
  } = useDroppable({
    id: folder.id,
    data: {
      type: 'container',
      children: folder.items
    }
  });

  function handleChangeNameClick(event) {
    changeFolderName(event.target.value)
    setNewName(event.target.value)
  }
  function handleDeleteFolder () {
    deleteFolder(folder.id)
  }
  const itemViews = folder.items.map(item => {
    const selected = selectedIds.includes(item.id);
    console.log("selected", selected)
    return (
      <SortableItem
        key={item.id}
        item={item}
        selected={selected}
        toggleSelect={toggleSelect}
        handleDelete={handleDelete}
        onIdClick={onIdClick}
      />
    )
  })
  const subfoldersView = !subfolder && (
    <Subfolders
      folder={folder}
      changeFolderName={changeFolderName}
      deleteFolder={deleteFolder}
      toggleSelect={toggleSelect}
      selectedIds={selectedIds}
      createSubfolder={createSubfolder}
      folders={folders}
      handleDelete={handleDelete}
      onIdClick={onIdClick}
    />
  )
  return (
   <>
     {subfolder ?
         <>
           <div style={{
             backgroundColor: "#ffffff",
             margin: '0px',
             width: '98%',
             borderRadius: "8px"
           }}>
             <div
                 ref={setNodeRef}
                 style={{
                   minHeight: expanditems ? 'auto' : '60px',
                   marginTop: "1px",
                   marginBottom: "2px",
                   transition: 'height 0.3s ease-in-out',
                 }}
             >
               <div style={{display: 'flex', gap: '5px', backgroundColor: "#ffffff"}}>
                 <Grid container spacing={0} style={{marginBottom: "8px"}}>
                   <Grid item xs={1} className="centerthis">
                     {expanditems ?
                         <>
                           &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                         <img src={Rightarrow} width="17px" onClick={() => setExpanditems(false)}
                              className="middlemoveicons" alt="expand icon"/>
                              </>
                         :
                         <>
                         &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                         <img src={Downarrow} width="22px" onClick={() => setExpanditems(true)}
                              className="middlemoveicons" alt="minimize icon"/>
                              </>
                     }
                   </Grid>
                   <Grid item xs={10} className="leftside">
                     <img src={Subfoldericon} width="24px" alt="folder icon"/> &nbsp;
                     <input type="text" value={newName} placeholder={folder.name} onChange={handleChangeNameClick}
                            className="dasheditroomnameinput1"/>
                     <img src={Editbs} width="25px" alt="edit icon" className="editfoldernameicon"/>
                   </Grid>
                   <Grid item xs={1} className="rightside">
                     <img src={DeleteIcon} onClick={handleDeleteFolder} width="30px" alt="delete icon"
                          className="middlemoveicons"/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                   </Grid>
                 </Grid>
               </div>
               {!expanditems ?
                   <>
                     <br/>
                     {itemViews}
                   </>
                   : ""
               }
             </div>
             {!expanditems ?
                 <>
                   {subfoldersView}
                 </>
                 : ""
             }
           </div>
         </> :
         <>
           <div
               style={{
                 backgroundColor: "#F5F5F5",
                 margin: '0px',
                 width: '99%',
                 borderRadius: "8px"
               }}
           >
             <div
                 ref={setNodeRef}
                 style={{
                   minHeight: expanditems ? 'auto' : '60px',
                   marginTop: "1px",
                   marginBottom: "2px",
                   transition: 'height 0.3s ease-in-out',
                 }}
             >
               <div style={{display: 'flex', gap: '5px', backgroundColor: "#ffffff"}}>
                 <Grid container spacing={0} style={{marginBottom: "8px"}}>
                   <Grid item xs={1} className="centerthis">
                     {expanditems ?
                         <>
                         &nbsp; &nbsp;
                         <img src={Rightarrow} width="17px" onClick={() => setExpanditems(false)}
                              className="middlemoveicons" alt="expand icon"/>
                              </>
                         :
                         <>
                         &nbsp; &nbsp;
                         <img src={Downarrow} width="22px" onClick={() => setExpanditems(true)}
                              className="middlemoveicons" alt="minimize icon"/>
                              </>
                     }
                   </Grid>
                   <Grid item xs={10} className="leftside">
                     <img src={Foldericon} width="24px" alt="folder icon"/> &nbsp;
                     <input type="text" value={newName} placeholder={folder.name} onChange={handleChangeNameClick}
                            className="dasheditroomnameinput"/>
                     <img src={Editbs} width="24px" alt="edit icon" className="editfoldernameicon"/>
                   </Grid>
                   <Grid item xs={1} className="rightside">
                     <img src={DeleteIcon} onClick={handleDeleteFolder} width="30px" alt="delete icon"
                          className="middlemoveicons"/>
                   </Grid>
                 </Grid>
               </div>
               {!expanditems ?
                   <>
                     <br/>
                     {itemViews}
                   </>
                   : ""
               }
             </div>
             {!expanditems ?
                 <>
                   {subfoldersView}
                 </>
                 : ""
             }
           </div>
         </>
     }
    </>
  )
}
