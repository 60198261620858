import React, {useState, useMemo, useEffect} from 'react'
import Item from './Item'
import {API} from "aws-amplify";
import {listLeaderboards} from "../../../graphql/queries";
import {createEngagement, createLeaderboard, updateLeaderboard} from "../../../graphql/mutations";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../css/taptap.css";

const placeholderCategories = [
  {
    name: '',
    items: [
      { text: '', image: '' }
    ]
  },
  {
    name: '',
    items: [
      { text: '', image: '' },
      { text: '', image: '' }
    ]
  }
]

function shuffle(items) {
  const entries = items.map((item) => [Math.random(), item])
  entries.sort((a, b) => a[0] - b[0])
  return entries.map((entry) => entry[1])
}

export default function Showcategory() {
  const [quest, setQuest] = useState();
  const [timeval, setTimeval] = useState(0);
  const { id, rid } = useParams();
  const [messages, setMessages] = useState();
  const [thissaved, setThissaved] = useState([]);
  const [isShows, setIsShows] = useState(true);
  const [playpause, setPlaypause] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [counter, setCounter] = useState();

  const [active, setActive] = useState(null);
  function selectItem (text) {
    setActive(text)
  }
  useEffect(() => {
    const newCards=sessionStorage.getItem("dragdropcategoriesques");
    setQuest(newCards);
    const newcounter=Number(sessionStorage.getItem("dragdropcategoriescounter"));
    setCounter(newcounter);
    const submitrids = sessionStorage.getItem("submitrid");
    if(submitrids){
      setThissaved(submitrids)
      if(submitrids.includes(rid)){
        setDisabled(true);
      }
    }
  }, []);
   const categories = useMemo(() => {
    const storedCategories = sessionStorage.getItem('dragdropcategories')
    if (storedCategories == null) {
      return placeholderCategories
    }
    const parsed = JSON.parse(storedCategories).map((category) => {
      const items = category.items.map((item) => {
        return { ...item, category: category.name }
      })
      return { ...category, items }
    })
    return parsed
  }, [])
  const [categoryItems, setCategoryItems] = useState(() => {
    return categories.reduce((acc, category) => {
      acc[category.name] = []
      return acc
    }, {})
  })

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setTimeval(0)
      return <div className="timer">0</div>;
    }else{
      setTimeval(remainingTime)
    }
    return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
    );
  }
  const [answers,setAnswers] = useState({})
  const flatItems = categories.flatMap(category => category.items)
  const [generalItems, setGeneralItems] = useState(() => {
    const shuffledItems = shuffle(flatItems)
    return shuffledItems
  })
  function handleGeneralClick () {
    if (active == null || generalItems.some(item => item.text === active.text)) return
    setGeneralItems(currentGeneralItems => [...currentGeneralItems, active])
    setCategoryItems(currentCategoryItems => {
      const newCategoryItems = {...currentCategoryItems}
      categories.forEach(category => {
        newCategoryItems[category.name] = newCategoryItems[category.name].filter(
            item => item.text !== active.text
        )
      })
      return newCategoryItems
    })
    setActive(null)
  }
  const generalItemsViews = generalItems.map((item, index) => {
    return (
        <Item
            key={index}
            item={item}
            categories={categories}
            setCategoryItems={setCategoryItems}
            setAllItems={setGeneralItems}
            active={active}
            setActive={setActive}
        />
    )
  })
  const generalView = (
      <div
          style={{ display: 'flex', flexWrap:"wrap",alignItems: "center", justifyContent: "center", background: '#ececec', margin: '10px', minHeight: '80px',borderRadius:"5px" }}
          onClick={handleGeneralClick}
      >
        {generalItemsViews}
      </div>
  )

  const [missing, setMissing] = useState(() => {
    return categories.reduce((acc, category) => {
      acc[category.name] = []
      return acc
    }, {})
  })
  console.log('missing', missing)
  const categoryViews = categories.map((category, index) => {
    const categoryAnswers = answers[category.name]
    const categoryItemViews = categoryItems[category.name].map((item, index) => {
      const answer = categoryAnswers && categoryAnswers[index]
      return (
          <Item
              key={index}
              answer={answer}
              item={item}
              categories={categories}
              setCategoryItems={setCategoryItems}
              setAllItems={setGeneralItems}
              active={active}
              setActive={setActive}
          />
      )
    })
    const missingItems = missing[category.name]
    console.log('missingIitems', missingItems)
    const missingItemViews = missingItems.map(missingItem=> {
      return <div style={{ padding: '10px', margin: '20px', color:"#e82c82"}}>{missingItem.text} <br/> <img src={missingItem.image} width="220px"/></div>
    })
    function handleClick () {
      if (active == null) return
      if (categoryItems[category.name].some(item => item.text === active.text)) return
      setGeneralItems(
          currentGeneralItems => currentGeneralItems.filter(item => item.text !== active.text)
      )
      setCategoryItems(currentCategoryItems => {
        const newCategoryItems = { ...currentCategoryItems }
        categories.forEach(otherCategory => {
          const otherItems = newCategoryItems[otherCategory.name]
          const categoryMatch = otherCategory.name === category.name
          if (categoryMatch) {
            const mergedItems = [...otherItems, active]
            newCategoryItems[otherCategory.name] = mergedItems
          } else {
            newCategoryItems[otherCategory.name] = otherItems.filter(
                otherItem => otherItem.text !== active.text
            )
          }
        })
        return newCategoryItems
      })
      setActive(null)
    }
    return (
        <div
            key={index}
           className="catelists"
            onClick={handleClick}
        >
          <h5 style={{color:"#ffffff"}}>{category.name}</h5>
          <hr/>
          <div style={{ display: 'flex' ,flexWrap:"wrap",alignItems: "center", justifyContent: "center"}}>
            {categoryItemViews}
            {missingItemViews}
          </div>
        </div>
    )
  })
  const [percent, setPercent] = useState()
  console.log("categories", categories)
  function handleSubmit() {
    const itemEntries = Object.entries(categoryItems)
    const answerEntries = itemEntries.map(entry => {
      const [name, items] = entry
      const placeholderCategory = categories.find(category => category.name === name)
      const itemAnswers = items.map((item, index) => {
        const correct = placeholderCategory.items.some(placeholderItem => {
          return placeholderItem.text === item.text
        })
        return correct
      })
      return [name, itemAnswers]
    })
    const newAnswers = Object.fromEntries(answerEntries)
    setAnswers(newAnswers)
    const missingEntries = itemEntries.map(entry => {
      const [name, items] = entry
      const placeholderCategory = categories.find(category => category.name === name)
      console.log('placeholderCategory', placeholderCategory)
      const missing = placeholderCategory.items.filter(placeholderItem => {
        const something = items.some(item => item.text === placeholderItem.text)
        return !something
      })
      return [name, missing]
    })
    const newMissing = Object.fromEntries(missingEntries)
    setMissing(newMissing)
    const correctItems = flatItems.filter((item) => {
      const selectedCategory = categories.find((category) => {
        const items = categoryItems[category.name]
        const selected = items.some((otherItem) => otherItem.text === item.text)
        return selected
      })
      if (selectedCategory == null) {
        return false
      }
      return selectedCategory.name === item.category
    })
    const scres = correctItems.length;
    const totalscore = flatItems.length;

    setPercent(scre);
    let tt = [];
    if (thissaved){
      tt = [thissaved, rid]
    }else{
      tt = [rid]
    }
    sessionStorage.setItem("submitrid", tt);
    setPlaypause(false);
    let mymeetnames = localStorage.getItem("vreachumnewername");
    let mymeetemails = localStorage.getItem("vreachumneweremail");
    const ttr = timeval * scres;
    const cm = ttr/totalscore;
    const scre = Math.floor(cm);
    if (scre > 0) {
      try {
        API.graphql({
          query: listLeaderboards,
          variables: {
            filter: {
              "meetemail": {
                eq: mymeetemails
              },
              "answerid": {
                eq: id
              }
            }
          }
        }).then(datas => {
          const plays = datas.data.listLeaderboards.items;
          const playsl = plays.length;
          if (playsl > 0) {
            const myscore = plays[0].score;
            const myid = plays[0].id;
            const myscores = scre + myscore;
            try {
              const input = {
                id: myid,
                score: myscores,
                thisgamescore: scre
              }
              API.graphql({
                query: updateLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          } else {
            try {
              const input = {
                answerid: id,
                name: mymeetnames,
                meetemail:mymeetemails,
                score: scre,
                thisgamescore: scre
              }
              API.graphql({
                query: createLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }
          setMessages(`You scored ${scre} points.`)
        })
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        API.graphql({
          query: listLeaderboards,
          variables: {
            filter: {
              "meetemail": {
                eq: mymeetemails
              },
              "answerid": {
                eq: id
              }
            }
          }
        }).then(datas => {
          const plays = datas.data.listLeaderboards.items;
          const playsl = plays.length;
          if (playsl === 0) {
            try {
              const input = {
                answerid: id,
                name: mymeetnames,
                meetemail:mymeetemails,
                score: 0,
                thisgamescore: 0
              }
              API.graphql({
                query: createLeaderboard,
                variables: {input}
              });

            } catch (err) {
              console.log(err)
            }
          }else{
            const myid = plays[0].id;
            try {
              const input = {
                id: myid,
                thisgamescore:0
              }
              API.graphql({
                query: updateLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
      setMessages(`You scored 0 out of ${totalscore}`)
    }
  }
  const score = percent != null && <div style={{ background: '#ecebeb', padding: '20px' }}>{percent}%</div>
  const getdatas = async () =>{
    const mymeetnams = localStorage.getItem("vreachumnewername");
    const mymeetemals = localStorage.getItem("vreachumneweremail");
    if(mymeetemals) {
      try {
        API.graphql({
          query: listLeaderboards,
          variables: {
            filter: {
              "meetemail": {
                eq: mymeetemals
              },
              "answerid": {
                eq: id
              }
            },
            limit:10000
          }
        }).then(datas => {
          const plays = datas.data.listLeaderboards.items;
          const playsl = plays.length;
          if (playsl === 0) {
            try {
              const input = {
                answerid: id,
                name: mymeetnams,
                meetemail: mymeetemals,
                score: 0,
                thisgamescore: 0
              }
              API.graphql({
                query: createLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }else{
            const myid = plays[0].id;
            try {
              const input = {
                id: myid,
                thisgamescore:0
              }
              API.graphql({
                query: updateLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }
          setMessages("You scored 0 point")
          setIsShows(false);
          try {
            const input = {
              questionid: id,
              engage: 0,
              people: 1
            }
            API.graphql({
              query: createEngagement,
              variables: {input}
            });
          } catch (err) {
            console.log(err)
          }
        })
      } catch (err) {
        console.log(err)
      }
    }else{
      setMessages("Time is up.")
      setIsShows(false);
    }
  }

  return (
      <div className="drawoptionpage2">
            <div className="greenback">
              <Grid container spacing={0}>
                <Grid item xs={2}>
                  <div className="lpollheadclock2">
                              <CountdownCircleTimer
                                  isPlaying={playpause}
                                  duration={counter}
                                  size={50}
                                  colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                  colorsTime={[10, 6, 3, 0]}
                                  onComplete={getdatas}
                              >
                                {renderTime}
                              </CountdownCircleTimer>
                  </div>
                </Grid>
                <Grid item xs={10}>
                  <div className="fastanswes">
                    <h4>{quest}</h4>
                  </div>
                </Grid>
              </Grid>
            </div>

                              <>
                                <div>
                                  {generalView}
                                  <div style={{
                                    display: 'flex',
                                    flexWrap: "wrap",
                                    width: "98%",
                                    margin: "5px auto",
                                      alignItems: "center",
                                      justifyContent: "center"
                                  }}>
                                    {categoryViews}
                                  </div>
                                  <br/>
                                  <Grid container spacing={0}>
                                    <Grid item xs={11} className="rightside">
                                      <Button onClick={handleSubmit} variant="success" size="xs" disabled={isDisabled}>Submit</Button>
                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                              <>
                                <div>
                                  <br/>
                                  <h4 className="pollmaeeage">{messages}</h4>
                                </div>
                              </>


      </div>

  )
}
