import React, { useState, useEffect, useRef } from "react";
import {useParams} from "react-router-dom";
import {API} from "aws-amplify";
import {getPlayslide} from "../graphql/queries";
import {Helmet} from "react-helmet";
import "../pages/css/exportpdf.css"
import Noi from "../img/no.png";
import {Modal} from "react-bootstrap";

const PublicLessonPlan = ()  => {
    const { id } = useParams();
    const [playcontents, setPlaycontents] = useState([]);
    const [ymeeting, setYmeeting] = useState("");
    const [purls, setPurls] = useState([]);
    const [indexs, setIndexs] = useState(false);
    const [titless, setTitless] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        fetchData();
    }, []);
    async function fetchData() {
        const response =  await API.graphql({
            query: getPlayslide,
            variables: { id }
        });
        const datas = response.data.getPlayslide;
        const emeeting = datas.meetingname;
        setYmeeting(emeeting);
        const edatas = datas.playlistlinks;
        if(edatas === null){
            console.log("hello");
        }else {
            setPlaycontents(edatas);
        }
    }
    return(
        <>
            <Helmet>
                <title>Lesson Plan</title>
            </Helmet>
                    <div className="pdfpage">
                        <h2>{ymeeting}</h2>
                        <p>(View only)</p>
                        <br/>
                        {playcontents.map((page, index) => (
                            <div key={index} className="pdfiframe">
                                <p>{index+1} - {page.title}</p>
                                <img src={Noi} className="publiclessonpreview" onClick={() => {
                                    setPurls(page.rurl);
                                    setIndexs(index);
                                    setTitless(page.title);
                                    setShow(true);
                                }} />
                                <iframe src={page.rurl} allowFullScreen={true} className="pdfiframes"/>
                            </div>
                        ))}
                    </div>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{indexs+1}. {titless}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={Noi} className="publiclessonpreview1" />
                    {purls !== "" && (
                        <iframe
                            src={purls}
                            width="100%"
                            height="650px"
                            title="maincontent"
                            frameBorder="0"
                            allow="fullscreen,autoplay"
                        />
                    )}
                </Modal.Body>
            </Modal>
                        <br/>
                        <br/>
                        <br/>
        </>

    )
}


export default PublicLessonPlan;
