import {Auth, API, Hub} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {useHistory} from "react-router-dom";
import {listPlayslides,  listDesktopitems} from "../../graphql/queries";
import {
    newUpdatePlayslide,
    newDeletePlayslide,
    newUpdateDesktopitems,
} from "../../graphql/subscriptions";
import {
    createFolders,
    deletePlayslide,
    updatePlayslide,
    createDesktopitems,
    updateFolders,
    updateDesktopitems,
    createPlayslide
} from "../../graphql/mutations";
import "./../css/dashboard.css";
import Navbarconvoze from "../../components/Navbarconvoze";
import Foot from "../../components/Foot";
import Foott from "../../components/Foott";
import Convozelogin from "./convozelogin";
import {Helmet} from "react-helmet";
import {Button, Modal} from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import "react-datepicker/dist/react-datepicker.css";
import Grid from "@material-ui/core/Grid";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Newroom from "../../img/New-Room-Button.png";
import Deleteroom from "../../img/dash/delete.png";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {v4 as uuid} from "uuid";
import Inviteform from "./../invitepeople/inviteform";
import Invitecoform from "./../invitepeople/invitecoform";
import Inviteglform from "./../invitepeople/inviteglform";
import {Form, Input} from "element-react";
import moment from "moment-timezone";

function Convozedashboard() {
    const [myData, setMyData] = useState([])
    const [user, setUser] = useState();
    const [username, setUsername] = useState();
    const [icopied, setCiopied] = useState("");
    const [thisid,setThisid] = useState("");
    const [thismid,setThismid] = useState("");
    const [thisname,setThisname] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {setShow2(false);setShow(true)};
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => {setShow3(false);setShow(true)};
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => {setShow4(false);setShow(true)};
    const [show6, setShow6] = useState(false);
    const handleClose6 = () => {setShow6(false);setShow(true)};
    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const [show7, setShow7] = useState(false);
    const [showprocess, setShowprocess] = useState("");
    const [myroomData, setMyroomData] = useState([]);
    const [mymeeing, setMymeeting] = useState();
    const [extime, setExtime] = useState();
    const [openthis, setOpenthis] = useState(true);
    let history = useHistory();
    const [stateData, setStateData] = useState({
        meetingname:"",
        fields: ["hostRoomUrl"]
    });
    const d = uuid();
    useEffect(() => {
        getUserData();
        Hub.listen('auth', (data) => {
            const { payload } = data;
            onAuthEvent(payload);
        });
        fetchData();
        const deletePlayslide = API.graphql(
            {
                query:newDeletePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const updatePlayslide = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        return()=>{
            deletePlayslide.unsubscribe();
            updatePlayslide.unsubscribe()
        }
    }, []);
    const  getUserData = async () => {
        const user = await Auth.currentAuthenticatedUser();
        if(user){
            setUsername(user.attributes.email);
            setUser(user);
        }else{
            setUser(null)
        }
    }
        function onAuthEvent(payload)  {
        switch (payload.event) {
            case "signIn":
                getUserData();
                break;
            case "signUp":
                break;
            default:
                return
        }
    }
    async function fetchData() {
        const user = await Auth.currentAuthenticatedUser();
        const thisuser = user.attributes.email;
        const response = await API.graphql({
            query: listPlayslides,
            variables: {
                filter: {
                    "owner": {
                        eq: thisuser
                    }
                },
                limit:10000
            }
        });
        const datas = response.data.listPlayslides.items;
        const scending = [...datas].sort((a, b) => a.meetingname.toLowerCase() < b.meetingname.toLowerCase() ? -1 : 1);
        setMyData(scending);
    }
    function sharelist() {
        setShow5(true)
    }
    var mid = Math.random() * 1000000000000000000;
    const  gobackdash = async () => {
        try {
            const input = {
                ctime:extime,
                mid: mid,
                myrlink: myroomData.myrlinks,
                clientrlink: myroomData.clientrlinks,
                meetingname: mymeeing,
                fulls:false,
                counts:0,
                exiton:false,
                vplaypause:false,
                aplaypause:false,
                owner:username
            };
            const response = await API.graphql({
                query: createPlayslide,
                variables: {input}
            });
            const newitems = response.data.createPlayslide;
            var ids = newitems.id;
            const newitem = {
                id: ids,
                meetingname: mymeeing,
                mid:mid
            }
            const response1 = await API.graphql({
                query: listDesktopitems,
                variables: {
                    filter: {
                        "owner": {
                            eq: username
                        }
                    },
                    limit:10000
                }
            })
            const datas1 = response1.data.listDesktopitems.items;
            if(datas1.length !== 0) {
                const id = datas1[0].id;
                const desktopitems = JSON.parse(datas1[0].deskitems)
                const deskitemss = [...desktopitems, newitem]
                const input ={
                    id,
                    deskitems: JSON.stringify(deskitemss)
                }
                await API.graphql({
                    query: updateDesktopitems,
                    variables: {input}
                });
            }else{
                const input1 = {
                    deskitems:JSON.stringify(newitem),
                    owner:username
                }
                await API.graphql({
                    query: createDesktopitems,
                    variables: {input:input1}
                });
            }
        }catch(err){
            console.log(err)
        }
    }
    const renderData = () => {
        return myData.map((item,index) => (
            item.owner === username ?
                <ListGroup.Item eventKey={index} key={index} className="d-flex justify-content-between align-items-start" action variant="light">
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>
                                <span> {item.meetingname}</span>
                                    </Grid>
                                    <Grid item xs={2} className="centerside">
                                <button onClick={() => {
                                    setShow(true);
                                    setThisname(item.meetingname);
                                    setThisid(item.id);
                                    setThismid(item.mid);
                                }} className="golivebutton">Meeting Links</button>
                                    </Grid>
                                    <Grid item xs={2} className="centerside">
                                        <p className="disabledimages"> Copy</p>
                                    </Grid>
                                    <Grid item xs={2} className="centerside">
                                    <p className="disabledimages">Manage Leaderboard</p>
                                    </Grid>
                                    <Grid item xs={2} className="centerside">
                                <button onClick={()=> {
                                    const idf = item.id;
                                    history.push(`/convozeeditroom/${idf}`);
                                }} className="managecontentbtn">
                       Manage Content
                    </button>
                                    </Grid>
                                    <Grid item xs={1} className="rightside">
                                        <img src={Deleteroom} onClick={()=> {handleDeleteUrls(item.id)}} width="24" height="24" alt="delete room" />
                                    </Grid>
                                </Grid>
                </ListGroup.Item>
                :""
            ))
    }
    const renderDatalinks = () => {

        return (
                <ListGroup.Item >
                    <Tabs
                        defaultActiveKey="share"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                            <Tab eventKey="full" title="Video Call with Content" disabled>
                            </Tab>
                        <Tab eventKey="share" title="Content Only (for in-person engagement)">

<br/>
<h3>For Convoze</h3>
                                        <button onClick={() =>{
                                            history.push(`/ihost/${thisid}/${thismid}`);
                                        }} className="startbuttoncolor">
                                            Start Meeting</button>

<br/>
<br/>
                            <span className="darkgreen">GUEST LINK (click to copy):</span>
                            <br/>
                            <div>
                                    <CopyToClipboard text={`https://demo.reachum.com/iview/${thisid}`} onCopy={() => {
                                        setCiopied(true);
                                        setTimeout(() => {
                                            setCiopied(false);
                                        }, 5000);
                                    }}>
                                            <span className="buttoncolor">{`https://demo.reachum.com/iview/${thisid}`}</span>

                                    </CopyToClipboard>
                                <br/>
                                    {icopied ? <span style={{color: 'red'}}>Copied</span> : null}
                            </div>
<br/>
<br/>
                            <div className="rightside">
                                    <button  onClick={() => {
                                        history.push(`/inviteform1/${thisid}`)
                                    }} className="invitebuttoncolor">Invite Guests</button>
                            </div>
<br/>
<br/>

                        </Tab>
                        <Tab eventKey="selfstudy" title="Self-Study" disabled>
                        </Tab>
                    </Tabs>
                </ListGroup.Item>

        )
    }
    const handleDeleteUrls = async (itemId) => {
        let text = "Are you sure you want to delete this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            const input = {
                id: itemId
            };
            await API.graphql({
                query: deletePlayslide,
                variables: {input}
            });
            window.location.reload();
        }
    };
    const handleChange = e => {
        const sval = e.target.value;
        const thisdats =  myData.filter(mData => mData.meetingname.toLowerCase().includes(sval.toLowerCase()));
        if(sval!=="") {
            setMyData(thisdats)
        }else{
            window.location.reload()
        }
    }
    const goCreate = () => {
        setShow7(true);
        if(myData.length === 3){
            history.push(`/pricing`);
        }
    };
    const handleSubmit2 = async event => {
        event.preventDefault();
        setShowprocess("Creating...");
        try {
            var thedate = moment();
            var newDate = thedate.add(1, "y");
            const utcendDate = newDate.utc().format();
            const data = {
                body: {
                    endDate: utcendDate,
                    roomMode: "group",
                    fields: ["hostRoomUrl"]
                }
            }
            const exptime = newDate.format('MM/DD/YYYY');
            return await API.post("demowherebyv1","/demowherebyv1",data)
                .then(datas => {
                    const myrlinks = datas.hostRoomUrl;
                    const clientrlinks = datas.roomUrl;
                    const daas = {
                        myrlinks,
                        clientrlinks,
                        meetingname: stateData.meetingname,
                    };
                    setMyroomData(daas)
                    setMymeeting(daas.meetingname)
                    setExtime(exptime)
                    setShowprocess("")
                    setOpenthis(false)
                })
        }catch(err){
            console.log(err)
        }
    }
    return (
                        <>
                            <Helmet>
                                <title>REACHUM | DASHBOARD</title>
                            </Helmet>
                            <Navbarconvoze />
                            {!user ?
                                <Convozelogin />
                                :(
                                <>
                                                <div className="homepage">
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={5}>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <h2> Room List </h2>
                                                        </Grid>
                                                    </Grid>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={6}>
                                                                <input onChange={handleChange} name="search" placeholder="Search By Room Name" className="searchform"/>
                                                            </Grid>
                                                            <Grid item xs={6} className="rightside">
                                                                <img src={Newroom} onClick={goCreate} className="createroombutton"/>
                                                                <p>3 rooms limit.<br/> If you'd like to access unlimited rooms and unlock all features, subscribe now for full access. </p>
                                                            </Grid>
                                                        </Grid>

    <ListGroup as="ol" numbered>
                                                        {renderData()}
        </ListGroup>

                                                </div>

                                    <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                                        <Modal.Header closeButton>
                                            {thisname} - Meeting Links
                                        </Modal.Header>
                                        <Modal.Body>
                                            {renderDatalinks()}
                                        </Modal.Body>
                                    </Modal>
                                    <Modal show={show2} onHide={handleClose2} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                                        <Modal.Header closeButton>
                                           Calendar Invite
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Inviteform data={thisid}/>
                                        </Modal.Body>
                                    </Modal>
                                    <Modal show={show3} onHide={handleClose3} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                                        <Modal.Header closeButton>
                                            Calendar Invite
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Inviteglform data={thisid}/>
                                        </Modal.Body>
                                    </Modal>
                                    <Modal show={show4} onHide={handleClose4} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                                        <Modal.Header closeButton>
                                            Calendar Invite
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Invitecoform data={thisid}/>
                                        </Modal.Body>
                                    </Modal>
                                    <Modal show={show7} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                                        <Modal.Body>
                                            <Form className="market-header">
                                                <div className="cccenter">
                                                    <div className="createheadnote">
                                                        <span className="redthis">{showprocess}</span>
                                                    </div>
                                                    <br/>
                                                    <br/>
                                                    <h4>Create a Room</h4>
                                                    <p>Name for room (up to 200 people)</p>
                                                    <Form.Item>
                                                        <Input
                                                            type="text"
                                                            value={stateData.meetingname}
                                                            onChange={meetingname => setStateData({
                                                                ...stateData,
                                                                meetingname: meetingname
                                                            })}
                                                            placeholder="Room Name"
                                                            className="newcrroominput"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {openthis ?
                                                            <>
                                                                <Button onClick={()=>setShow7(false)} className="foldersavechanges"> &nbsp; &nbsp; Cancel &nbsp; &nbsp;</Button> &nbsp; &nbsp; &nbsp;
                                                                <Button
                                                                    disabled={!stateData.meetingname}
                                                                    onClick={handleSubmit2}
                                                                    className="foldersavechanges"
                                                                >
                                                                    &nbsp; &nbsp; &nbsp; Next &nbsp; &nbsp; &nbsp;
                                                                </Button>
                                                            </>
                                                            :
                                                            <Button onClick={()=>{
                                                                gobackdash();
                                                                setShow7(false)
                                                            }
                                                            } className="newdashboardsave">Create a item</Button>
                                                        }
                                                    </Form.Item>
                                                    <br/>
                                                    <br/>
                                                    <br/>
                                                    <br/>
                                                </div>
                                            </Form>
                                        </Modal.Body>
                                    </Modal>
                                    </>
                                )
                                    }
                            <Foot/>
                            <Foott />
                                </>

    );
}
export default Convozedashboard;

