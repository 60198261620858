import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import 'bootstrap/dist/css/bootstrap.min.css';

import "element-theme-default";

Amplify.configure(aws_exports);

ReactDOM.render( <App />, document.getElementById("root"));

