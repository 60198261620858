import {Auth,API} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide, listPlayslides} from "../../graphql/queries";
import {useHistory, useParams} from "react-router-dom";
import {newUpdatePlayslide,newDeletePlayslide} from "../../graphql/subscriptions";
import {updatePlayslide} from "../../graphql/mutations";
import {Modal} from 'react-bootstrap';
import {Form} from "element-react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '../../img/delete.png';
import Copys from '../../img/copy.png';
import Navbarconvoze from "../../components/Navbarconvoze";
import Foot from "../../components/Foot";
import "./../css/editroom.css";
import {v4 as uuid} from "uuid";
import Leftarrow from "../../img/left-arrow.png";
import Noi from "../../img/no.png";
import {Helmet} from "react-helmet";
import Editb from "../../img/dash/edit.png";
import Editbs from "../../img/host/editing.png";
import Allmove from "../../img/all-directions.png";
import Zoomin from "../../img/zoom-in.png";
import Zoomout from "../../img/zoom-out.png";
import ListGroup from "react-bootstrap/ListGroup";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Inviteform from "./../invitepeople/inviteform";
import Inviteglform from "./../invitepeople/inviteglform";
import Invitecoform from "./../invitepeople/invitecoform";
import Info from "../../img/dash/pop-up-icon.svg";
import Slide from "../../img/menu/text-slide.svg";
import Image from "../../img/menu/image.svg";
import Video from "../../img/menu/video.svg";
import Powerponit from "../../img/menu/powerpoint.svg";
import Pdf from "../../img/menu/pdf.svg";
import Gif from "../../img/menu/gif.svg";
import Link from "../../img/menu/show-webpage.svg";
import Videolink from "../../img/menu/video-link.svg";
import Draw from "../../img/menu/draw.svg";
import Record from "../../img/menu/record-a-video.svg";
import Giphy from "../../img/menu/giphy-library.svg";
import Polls from "../../img/menu/polls.svg";
import Open from "../../img/menu/convoz.svg";
import Single from "../../img/menu/single-select.svg";
import Multi from "../../img/menu/multi-select.svg";
import Fill from "../../img/menu/fill-in-the-blank.svg";
import Drawquiz from "../../img/menu/draw-quiz.svg";
import Videoquiz from "../../img/menu/video-quiz.svg";
import Math from "../../img/menu/math-quiz.svg";
import Memory from "../../img/menu/memory-matching-game.svg";
import Drag from "../../img/menu/tap-tap.svg";
import Textslidedis from "../../img/menu/dis/1-text-slide.png";
import Imagedis from "../../img/menu/dis/2-image.png";
import Videodis from "../../img/menu/dis/3-video.png";
import Pptxdis from "../../img/menu/dis/4-pptx.png";
import Pdfdis from "../../img/menu/dis/5-pdf.png";
import Gifdis from "../../img/menu/dis/6-gif.png";
import Linktodis from "../../img/menu/dis/7-link-to-webpage.png";
import Videolinkdis from "../../img/menu/dis/8-video-link.png";
import Drawdis from "../../img/menu/dis/9-draw.png";
import Recordvideo1dis from "../../img/menu/dis/10-1-record-video.png";
import Recordvideo2dis from "../../img/menu/dis/10-2-record-video.png";
import Giphydis from "../../img/menu/dis/gif.gif";
import Pollsdis from "../../img/menu/dis/Polls.gif";
import Openended1dis from "../../img/menu/dis/Teacher-view.gif";
import Openended2dis from "../../img/menu/dis/Highlight-a-Response.gif";
import Openended3dis from "../../img/menu/dis/Student-View.gif";
import Singleselectdis from "../../img/menu/dis/Single-Select.gif";
import Multiselectdis from "../../img/menu/dis/Multi-Select.gif";
import Fillindis from "../../img/menu/dis/Fill-in-the-Blank.gif";
import Drawquizedis from "../../img/menu/dis/Draw-Quiz.gif";
import Videoquiz1dis from "../../img/menu/dis/Video-Quiz.gif";
import Sequencingdis from "../../img/menu/dis/sequrncing.gif";
import Mathquizedis from "../../img/menu/dis/Math-Quiz.gif";
import Mmgdis from "../../img/menu/dis/Memory-Matching-Game.gif";
import Taptapdis from "../../img/menu/dis/Tap-Tap.gif";
import Rankingpoll from "../../img/menu/dis/Ranking-Poll.gif";
import Sequecing from "../../img/menu/sequencing.svg";
import Adobeexpress from "../../img/menu/adobe-express.svg";
import Rankingmenu from "../../img/menu/ranking-poll.svg";
import Adobeoverview from "../../img/menu/dis/overview-express.jpg";
import "./../css/contenttypes.css";
import "./style.css";

function Convozeeditroom() {
    const { id } = useParams();
    const history = useHistory();
    const [isUser, setIsUser] = useState(false);
    const [isUsere, setIsUsere] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [purls, setPurls] = useState([]);
    const [playcontents, setPlaycontents] = useState([]);
    const [ymeeting, setYmeeting] = useState("");
    const [copied, setCopied] = useState("");
    const [icopied, setCiopied] = useState("");
    const [ccopied, setCcopied] = useState("");
    const [cccopied, setCccopied] = useState("");
    const [ymeetingid, setYmeetingid] = useState();
    const [duplicateopen, setDuplicateopen] = useState(true);
    const [indexs, setIndexs] = useState(false);
    const [titless, setTitless] = useState(false);
    const [show2, setShow2] = useState(false);
    const [condata, setCondata] = useState([]);
    const [eurls, setEurls] = useState("");
    const [changesavedmessage, setChangesavedmessage] = useState("");
    const [show, setShow] = useState(false);
    const [isopen, setIsopen] = useState(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => {setShow1(false)};
    const handleClose2 = () => setShow2(false);
    const handleClose = () => setShow(false);
    const handleClose5 = () => {setShow5(false);setShow3(true)};
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const handleClose6 = () => {setShow6(false);setShow3(true)};
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => {setShow3(false);};
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => {setShow4(false);setShow3(true)};
    const d = uuid();
    const [qrcodemessage, setqrcodemessage] = useState("")
    const [show31, setShow31] = useState(false);
    const handleClose31 = () => {setShow31(false);setShow1(true)};
    const [show32, setShow32] = useState(false);
    const handleClose32 = () => {setShow32(false);setShow1(true)};
    const [show33, setShow33] = useState(false);
    const handleClose33 = () => {setShow33(false);setShow1(true)};
    const [show34, setShow34] = useState(false);
    const handleClose34 = () => {setShow34(false);setShow1(true)};
    const [show35, setShow35] = useState(false);
    const handleClose35 = () => {setShow35(false);setShow1(true)};
    const [show36, setShow36] = useState(false);
    const handleClose36 = () => {setShow36(false);setShow1(true)};
    const [show37, setShow37] = useState(false);
    const handleClose37 = () => {setShow37(false);setShow1(true)};
    const [show38, setShow38] = useState(false);
    const handleClose38 = () => {setShow38(false);setShow1(true)};
    const [show39, setShow39] = useState(false);
    const handleClose39 = () => {setShow39(false);setShow1(true)};
    const [show40, setShow40] = useState(false);
    const handleClose40 = () => {setShow40(false);setShow1(true)};
    const [show10, setShow10] = useState(false);
    const handleClose10 = () => {setShow10(false);setShow1(true)};
    const [show11, setShow11] = useState(false);
    const handleClose11 = () => {setShow11(false);setShow1(true)};
    const [show12, setShow12] = useState(false);
    const handleClose12 = () => {setShow12(false);setShow1(true)};
    const [show13, setShow13] = useState(false);
    const handleClose13 = () => {setShow13(false);setShow1(true)};
    const [show14, setShow14] = useState(false);
    const handleClose14 = () => {setShow14(false);setShow1(true)};
    const [show15, setShow15] = useState(false);
    const handleClose15 = () => {setShow15(false);setShow1(true)};
    const [show16, setShow16] = useState(false);
    const handleClose16 = () => {setShow16(false);setShow1(true)};
    const [show17, setShow17] = useState(false);
    const handleClose17 = () => {setShow17(false);setShow1(true)};
    const [show18, setShow18] = useState(false);
    const handleClose18 = () => {setShow18(false);setShow1(true)};
    const [show19, setShow19] = useState(false);
    const handleClose19 = () => {setShow19(false);setShow1(true)};
    const [show20, setShow20] = useState(false);
    const handleClose20 = () => {setShow20(false);setShow1(true)};
    const [show21, setShow21] = useState(false);
    const handleClose21 = () => {setShow21(false);setShow1(true)};
    const [show22, setShow22] = useState(false);
    const handleClose22 = () => {setShow22(false);setShow1(true)};
    const [show23, setShow23] = useState(false);
    const handleClose23 = () => {setShow23(false);setShow1(true)};
    useEffect(() => {
   const thisshowed = localStorage.getItem("convozeplayed");
   if(!thisshowed){
       setShow1(true);
       localStorage.setItem("convozeplayed", "You got it");
   }
    }, []);
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setIsUser(user.attributes.email);
                setIsUsere(user.attributes.email);
            })
            .catch(err => console.log(err));
        fetchData();
        fetchcata();
        const deletePlayslide = API.graphql(
            {
                query:newDeletePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        const updatePlayslide = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        return()=>{
            deletePlayslide.unsubscribe();
            updatePlayslide.unsubscribe()
        }
    }, []);
    async function fetchData() {
        const response =  await API.graphql({
            query: getPlayslide,
            variables: { id }
        });
        const datas = response.data.getPlayslide;
        const iowner = datas.owner;
        setIsOwner(iowner);
        const emeeting = datas.meetingname;
        setYmeeting(emeeting);
        const emeetingid = datas.mid;
        setYmeetingid(emeetingid);
        const edatas = datas.playlistlinks;
        if(edatas === null){
            console.log("hello");
        }else {
            setPlaycontents(edatas);
            setDuplicateopen(false)
        }
    }
    async function fetchcata() {
        const response = await API.graphql({
            query: listPlayslides
        });
        const datas = response.data.listPlayslides.items;
        setCondata(datas);
    }
    const handleChange = (e, id) => {
        e.preventDefault();
        const newContentIndex = playcontents.findIndex(content => content.rid === id);
        const newContent = playcontents[newContentIndex];
        newContent.title = e.target.value;
        const clonedPlaycontents = [...playcontents];
        clonedPlaycontents.splice(newContentIndex, 1, newContent);
        setPlaycontents(clonedPlaycontents);
    }
    const sid = id;
    const handleDelete = async (id) => {
        const newPlayContents = playcontents.filter(content => content.rid !== id);
        try {
            const input = {
                id: sid,
                playlistlinks: newPlayContents
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }catch(err){
            console.log(err)
        }
        setPlaycontents(newPlayContents);
    }
    const rendercondata = () => {
        return condata.map(item => (
            item.owner === isUser ?
                <option key={item.id} value={item.id}>{item.meetingname}</option>
                : ""
        ))
    }
    const myFunction = () => {
        var x = document.getElementById("mySelect").value;
        API.graphql({
            query: getPlayslide,
            variables: { id: x }
        }).then(datas => {
            const cdata = datas.data.getPlayslide;
            const condata = cdata.playlistlinks;
            const replaced = condata.map((element) => {
                const urlElements = element.rurl.split('/');
                const ee = element.eurl;
                let eurlElements;
                let neweurl;
                if(ee) {
                    eurlElements = ee.split('/');
                    eurlElements[4] = id;
                    neweurl = eurlElements.join('/');
                }
                urlElements[4] = id;
                const newUrl = urlElements.join('/');
                const newElements = {...element, rurl: newUrl, eurl: neweurl};
                return newElements;
            });
            try {
                const input = {
                    id,
                    playlistlinks: replaced
                };
                API.graphql({
                    query: updatePlayslide,
                    variables: {input}
                });
            }catch(err){
                console.log(err)
            }
        })
    }
    const  handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const input = {
                id,
                playlistlinks: playcontents
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        }catch(err){
            console.log(err)
        }
        setChangesavedmessage("Saved")
        setTimeout(() => {
            setChangesavedmessage("")
        }, 1000);
    }
     async function changeroomname(event){
        event.preventDefault();
         setYmeeting(event.target.value);
    }

    useEffect(() => {
        const delay = 2000;
        const timeoutId = setTimeout(() => {
            saveInputValue(ymeeting);
        }, delay);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [ymeeting]);

    const saveInputValue = async (value) => {
        try {
            const input = {
                id,
                meetingname: value
            };
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        } catch (err) {
            console.log(err)
        }
    };
    const onSortEnd = (oldIndex, newIndex) => {
        setPlaycontents((array) => arrayMove(array, oldIndex, newIndex));
    };
    function gobackthis(){
        history.push(`/convozedashboard`);
    }
    const addqrcode = async () =>{
        const d = uuid();
        const qyrl = `https://demo.reachum.com/qrcodepage/${id}`;
        try {
        const vv = {
            title: "Viewer QR code",
            rid: d,
            rurl: qyrl
        }
        const allImages = [...playcontents].concat(vv);
        const input = {
            id,
            playlistlinks: allImages
        };
        await API.graphql({
            query: updatePlayslide,
            variables: {input}
        });
        setqrcodemessage("Content's QR code added to the content list.")
        setTimeout(() => {
            setqrcodemessage("")
        }, 1000);
        }catch(err){
            console.log(err)
        }
    }
    function answeraquestionfunctions(){
        history.push(`/createanswer/${id}`);
    }
    const renderDatalinks = () => {
        return (
            <ListGroup.Item >
                <Tabs
                    defaultActiveKey="share"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="full" title="Video Call with Content">
                    </Tab>
                    <Tab eventKey="share" title="Content Only (for in-person engagement)">
                        <br/>
                        <button onClick={() =>{
                            history.push(`/ihost/${id}/${ymeetingid}`);
                        }} className="startbuttoncolor">
                            Start Meeting</button>

                        <br/>
                        <br/>
                        <span className="darkgreen">GUEST LINK (Click to Copy):</span>
                        <br/>
                        <div>
                            <CopyToClipboard text={`https://demo.reachum.com/iview/${id}`} onCopy={() => {
                                setCiopied(true);
                                setTimeout(() => {
                                    setCiopied(false);
                                }, 5000);
                            }}>
                                <span className="buttoncolor">{`https://demo.reachum.com/iview/${id}`}</span>

                            </CopyToClipboard>
                            <br/>
                            {icopied ? <span style={{color: 'red'}}>Copied</span> : null}
                        </div>
                        <br/>
                        <br/>
                        <div className="rightside">
                            <button  onClick={() => {
                                history.push(`/inviteform1/${id}`)
                            }} className="invitebuttoncolor">Invite Guests</button>
                        </div>
                        <br/>
                        <br/>

                    </Tab>
                </Tabs>
            </ListGroup.Item>

        )
    }
    function thiszoomin(){
        setIsopen(false);
        const allWithClass = Array.from(
            document.querySelectorAll('div.lialist')
        );
        const allWithClass1 = Array.from(
            document.querySelectorAll('img.editnoi')
        );
        const allWithClass2 = Array.from(
            document.querySelectorAll('iframe.editsframelook')
        );
        allWithClass.forEach(element => {
            element.style.width = "141px";
            element.style.height = "120px";
        });
        allWithClass1.forEach(element => {
            element.style.width = "137px";
            element.style.height = "51px";
        });
        allWithClass2.forEach(element => {
            element.style.width = "137px";
            element.style.height = "51px";
        });
    }
    function thiszoomout(){
        setIsopen(true);
        const allWithClass = Array.from(
            document.querySelectorAll('div.lialist')
        );
        const allWithClass1 = Array.from(
            document.querySelectorAll('img.editnoi')
        );
        const allWithClass2 = Array.from(
            document.querySelectorAll('iframe.editsframelook')
        );
        allWithClass.forEach(element => {
            element.style.width = "235px";
            element.style.height = "200px";
        });
        allWithClass1.forEach(element => {
            element.style.width = "229px";
            element.style.height = "130px";
        });
        allWithClass2.forEach(element => {
            element.style.width = "229px";
            element.style.height = "130px";
        });
    }
    return (
        isOwner === isUser ?
        <>
            <Helmet>
                <title>EDIT</title>
            </Helmet>
            <Navbarconvoze />
            <div className="editroom">
                <div className="input-container">
                     <input type="text" value={ymeeting} onChange={changeroomname} className="editroomnamebig" /><img src={Editbs} className="inputicon"/>
                </div>
                <br/>
                <div className="savesheader">
                        <Grid container spacing={0} className="topspace1">
                            <Grid item xs={3}>
                                <h5 className="sharedropbtn" onClick={gobackthis}><img src={Leftarrow} width="13" height="13" /> <span className="roomlistbutton">Room List</span> </h5>
                            </Grid>
                            <Grid item xs={6} className="cccenter">
                                <span className="editcontents">Content Manager</span>
                            </Grid>
                            <Grid item xs={3} className="cccenter">
                            </Grid>
                        </Grid>
                </div>
                    <div className="saves">
                        <Grid container spacing={0} className="topspace">
                            <Grid item xs={3}>
                                <div className="upgradenew2">
                                <button
                                     onClick={()=>{
                                         history.push(`/convozerehearse/${id}`);
                                     }} className="rehearsebutton">Rehearse</button>
                                    <span className="tooltiptext2">Rehearse any meeting with full privacy.</span>
                                </div>
                            </Grid>
                            <Grid item xs={6} className="cccenter">
                            </Grid>
                            <Grid item xs={3} className="cccenter">
                                <button className="addcontentbtn" onClick={()=>setShow1(true)}> Add Content</button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} className="topspace">
                            <Grid item xs={3} className="cccenter">
                                <button onClick={() => {
                                    setShow3(true);
                                }} className="golivebutton">Meeting Links</button>
                            </Grid>
                            <Grid item xs={6} className="centerside">
                                <p className="redthis">{qrcodemessage}</p>
                            </Grid>
                            <Grid item xs={3} className="cccenter">
                                <button onClick={addqrcode} className="addqrbtn">Add QR code</button>
                            </Grid>
                        </Grid>
                    </div>
                        <Form onSubmit={handleSubmit}>
                            <div className="saveseditchage">
                            <Grid container spacing={0} className="topspace">
                                <Grid item xs={3} className="cccenter">
                                </Grid>
                                <Grid item xs={6} className="cccenter">
                                    <span className="redthis">{changesavedmessage} </span><input type="submit" value="Save Changes" className="inputsavechanges" />
                                </Grid>
                                <Grid item xs={3} className="cccenter">
                            <div className="zooms">
                          {isopen ?
                              <img src={Zoomin} onClick={thiszoomin} width="32" height="32"/>
                              :
                              <img src={Zoomout} onClick={thiszoomout} width="32" height="32"/>
                          }
                             </div>
                                </Grid>
                            </Grid>
                            </div>
                    <SortableList
                        onSortEnd={onSortEnd}
                        draggedItemClassName="dragged"
                        className="characters"
                    >
                        {
                            playcontents.map((itm, index) => {
                                return (
                                    <SortableItem key={index}>
                                        <div className="lialist">
                                            <Grid container spacing={0} className="topedit">
                                                <Grid item xs={3} className="leftside">
                                                    <img src={Allmove} width="12" height="12" className="movethis"/>
                                                    <span className="editspan">{Number(index) + Number(1)}</span>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <div className="upgradenew2">
                                                        <img src={Editb} onClick={() => {
                                                            setEurls(itm.eurl);
                                                            setShow(true);
                                                        }} width="18" height="18" />
                                                        <span className="tooltiptext2">Click here to edit the content.</span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4} className="leftside">
                                                    <CopyToClipboard text={itm.rurl}>
                                                        <div className="upgradenew1">
                                                            <img src={Copys} width="18" height="18" />
                                                            <span className="tooltiptext1">Click to copy link</span>
                                                        </div>
                                                    </CopyToClipboard>
                                                </Grid>
                                                <Grid item xs={2} className="rightside">
                                                    <div className="upgradenew1">
                                                        <img src={DeleteIcon} width="18" height="18" onClick={() => {
                                                            handleDelete(itm.rid);
                                                        }} />
                                                    <span className="tooltiptext1">Click to delete</span>
                                                 </div>
                                                </Grid>
                                            </Grid>
                                            <div className="imgslist">
                                                <div className="upgradenew1">
                                                    <div className="imgslist">
                                                        <img src={Noi} className="editnoi" onClick={() => {
                                                            setPurls(itm.rurl);
                                                            setIndexs(index);
                                                            setTitless(itm.title);
                                                            setShow2(true);
                                                        }} />
                                                        <iframe src={itm.rurl} scrolling="no" className="editsframelook"></iframe>
                                                    </div>
                                                    <span className="tooltiptext1">Click to preview</span>
                                                </div>
                                            </div>
                                            <input
                                                value={itm.title}
                                                onChange={(e) => {
                                                    handleChange(e, itm.rid);
                                                    if (e.keyCode === 13){
                                                        handleSubmit();
                                                    }
                                                }}
                                                name="title"
                                                placeholder="title"
                                                className="editroomlist"
                                            />
                                        </div>
                                    </SortableItem>
                                )}
                            )
                        }
                    </SortableList>
                    <br/>
            </Form>
            </div>
            <Modal show={show1} onHide={handleClose1} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Tabs
                            defaultActiveKey="discussion"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="resources" title="Media">
                                <br/>
                                <Grid container spacing={0} className="topspace">
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Adobeexpress} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow23(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Slide} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow31(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Image} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow32(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Video} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow33(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Powerponit} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow34(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Pdf} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow35(true)}} width="30px"/>
                                    </Grid>
                                </Grid>
                                <br/>
                                <Grid container spacing={0} className="topspace">
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Gif} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow36(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Link} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow37(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Videolink} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow38(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Draw} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow39(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Record} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow10(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={2} className="cccenter">
                                        <img src={Giphy} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow11(true)}} width="30px"/>
                                    </Grid>
                                </Grid>
                                <br/>
                            </Tab>
                            <Tab eventKey="discussion" title="Discussion Questions">
                                <br/>
                                <div className="leftpadding">
                                    <p>Discussion questions are meant to generate conversation. These activities have no correct answers and responses will not be reflected on your leaderboard.</p>
                                </div>
                                <Grid container spacing={0} className="topspace">
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Polls} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow12(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Rankingmenu} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow40(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Open} onClick={answeraquestionfunctions} width="80px"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow13(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                    </Grid>
                                </Grid>
                                <br/>
                            </Tab>
                            <Tab eventKey="leaderboard" title="Leaderboard Games">
                                <br/>
                                <div className="leftpadding">
                                    <p>Leaderboard games are designed to engage your learners in friendly, time-based competition. The quicker a learner guesses the correct answer, the more points they earn on the leaderboard.</p>
                                </div>
                                <Grid container spacing={0} className="topspace">
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Single} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow14(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Multi} width="80px" className="disabledimages"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow15(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Fill} width="80px"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow16(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Drawquiz} width="80px"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow17(true)}} width="30px"/>
                                    </Grid>
                                </Grid>
                                <br/>
                                <Grid container spacing={0} className="topspace">
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Memory} width="80px"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow20(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Drag} width="80px"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow21(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Sequecing} width="80px"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow22(true)}} width="30px"/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Videoquiz} width="80px"/>
                                        <br/>
                                        <img src={Info} onClick={()=>{setShow1(false);setShow18(true)}} width="30px"/>
                                    </Grid>
                                </Grid>
                                <br/>
                                <div className="leftpadding">
                                <h4>Math</h4>
                                </div>
                                <br/>
                                <Grid container spacing={0} className="topspace">
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Fill} width="80px"/>
                                        <br/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Multi} width="80px"/>
                                        <br/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                        <img src={Sequecing} width="80px"/>
                                        <br/>
                                    </Grid>
                                    <Grid item xs={3} className="cccenter">
                                    </Grid>
                                </Grid>
                                <br/>
                            </Tab>
                        </Tabs>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{indexs+1}. {titless}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {purls !== "" && (
                        <iframe
                            src={purls}
                            width="100%"
                            height="700px"
                            title="maincontent"
                            frameBorder="0"
                            allow="fullscreen,autoplay"
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {eurls !== "" && (
                        <iframe
                            src={eurls}
                            width="100%"
                            height="700px"
                            title="maincontents"
                            frameBorder="0"
                            allow="fullscreen"
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={show3} onHide={handleClose3} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    {ymeeting} - Meeting Links
                </Modal.Header>
                <Modal.Body>
                    {renderDatalinks()}
                </Modal.Body>
            </Modal>
            <Modal show={show4} onHide={handleClose4} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    Calendar Invite
                </Modal.Header>
                <Modal.Body>
                    <Inviteform data={id}/>
                </Modal.Body>
            </Modal>
            <Modal show={show5} onHide={handleClose5} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    Calendar Invite
                </Modal.Header>
                <Modal.Body>
                    <Inviteglform data={id}/>
                </Modal.Body>
            </Modal>
            <Modal show={show6} onHide={handleClose6} aria-labelledby="contained-modal-title-vcenter" size="xl" centered>
                <Modal.Header closeButton>
                    Calendar Invite
                </Modal.Header>
                <Modal.Body>
                    <Invitecoform data={id}/>
                </Modal.Body>
            </Modal>
            <Modal show={show31} onHide={handleClose31} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Text slide</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Textslidedis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Create an editable text slide with our rich text editor. Use this tool to create a simple slide or a complex navigation menu.</p>
                </div>
                    <br/>
                <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show32} onHide={handleClose32} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Image</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Imagedis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Upload a jpeg or png image.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show33} onHide={handleClose33} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Video</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Videodis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Upload a video for precise playback control across all student devices.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show34} onHide={handleClose34} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    PowerPoint
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Pptxdis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Upload a PowerPoint deck. PowerPoint files are converted to individual slides that can be reordered or deleted within the REACHUM platform.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show35} onHide={handleClose35} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">PDF</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Pdfdis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Upload a PDF. PDF pages are converted to individual slides that can be reordered or deleted within the REACHUM platform.</p>
                </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show36} onHide={handleClose36} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">GIF</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Gifdis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Upload your own animated gifs in REACHUM.</p>
                </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show37} onHide={handleClose37} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Show webpage</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Linktodis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Anything available by link is shareable in REACHUM, with full collaboration possible. Work together on documents and spreadsheets, or send a 3rd party quiz, a poll, or a shopping cart for secure, in-meeting transactions!</p>
                </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show38} onHide={handleClose38} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Video Link</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Videolinkdis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>YouTube and Vimeo links are shareable in REACHUM.</p>
                </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show39} onHide={handleClose39} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Draw</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Drawdis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Teachers can share freehand drawings and include poll questions.</p>
                </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show10} onHide={handleClose10} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Record a video</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <Tabs
                            defaultActiveKey="videoss"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="videoss" title="Record video">
                                <img src={Recordvideo1dis}  className="disfull"/>
                            </Tab>
                            <Tab eventKey="displayvideos" title="Play video">
                                <img src={Recordvideo2dis}  className="disfull"/>
                            </Tab>
                        </Tabs>

                    <br/>
                    <br/>
                    <p>REACHUM hosts can record a video up to 2 minutes long.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show11} onHide={handleClose11} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Giphy library</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="centerthisgiphy">
                        <img src={Giphydis}  width="360px"/>
                    <br/>
                    <br/>
                    <p>Import a gif to set the  mood using GIPHY, the largest library of animated gifs on the internet.</p>
                </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show12} onHide={handleClose12} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Polls</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Pollsdis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Measure your audience’s attitude, sentiment, or opinion with a poll.</p>
                    <p> This discussion activity is for auditory and visual learners.</p>
                </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show13} onHide={handleClose13} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Convoz</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <Tabs
                            defaultActiveKey="teacher"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="teacher" title="Teacher view">
                                <img src={Openended1dis}  className="disfull"/>
                            </Tab>
                            <Tab eventKey="highlight" title="Spotlight a response">
                                <img src={Openended2dis}  className="disfull"/>
                            </Tab>
                            <Tab eventKey="student" title="Student view">
                                <img src={Openended3dis}  className="disfull"/>
                            </Tab>
                        </Tabs>
                    <br/>
                    <br/>
                    <p>Ask your class an open-ended question and receive identified or anonymous responses. Click on a response to display it on student screens to stimulate conversation.</p>
                    <p> This discussion activity is for auditory and visual learners.</p>
                </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show14} onHide={handleClose14} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Single select</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Singleselectdis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Teachers ask a question and learners select the single correct answer.</p>
                    <p>This game is for read/write learners.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show15} onHide={handleClose15} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Multi select</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Multiselectdis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Use for questions with more than one correct answer. Scores are weighted by the number of correct answers.</p>
                    <p>This game is for read/write learners.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show16} onHide={handleClose16} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Fill in the blank</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Fillindis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Students type their answer in a single input field. Perfect matches score points.</p>
                    <p>This game is for read/write and kinesthetic learners.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show17} onHide={handleClose17} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Draw quiz</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Drawquizedis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Teachers draw a picture and ask learners a single select question based on their freehand drawing. Learners select the single correct answer.</p> <p> This game is for read/write and visual learners.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show18} onHide={handleClose18} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Video quiz</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Videoquiz1dis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Record or upload a video and insert  a timed quiz at a specified time.</p>
                    <p>This game is for auditory and visual learners.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show19} onHide={handleClose19} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Math quiz</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Mathquizedis}  className="disfull1"/>
                    <br/>
                    <br/>
                    <p>Single select question using math symbols and notation.</p>
                    <p>This game is for read/write and kinesthetic learners.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show20} onHide={handleClose20} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Memory matching game</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Mmgdis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Click one of 12 cards to flip it over and reveal its value. Click another card to try and find a match. When a match is revealed, the pair disappears. The game is successfully completed when all pairs are matched.</p>
                    <p>This game is for visual and kinesthetic learners.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show21} onHide={handleClose21} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Tap tap</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Taptapdis}  className="disfull"/>
                    <br/>
                    <br/>
                    <p>Users tap a list item to select it and tap the  corresponding category to add. Scores are weighted by the number of correct answers.</p>
                    <p>This game is for kinesthetic learners.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show22} onHide={handleClose22} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Sequecing</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Sequencingdis}  className="disfull1"/>
                    <br/>
                    <br/>
                    <p>Sequencing is useful for assessing timeline knowledge, number sense, process steps, paragraph structures, and more.</p>
                    <p>Use this game for activities that require putting items in a particular order.</p>
                    </div>
                        <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show23} onHide={handleClose23} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Adobe Express</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Adobeoverview}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>Adobe Express enables you to create designs more efficiently with access to a vast library of professionally designed templates, Adobe Stock photos, videos, music, and more.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Modal show={show40} onHide={handleClose40} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
                <Modal.Header closeButton>
                    <span className="redthis">Ranking Poll</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="disfullcenter">
                        <img src={Rankingpoll}  className="disfull1"/>
                        <br/>
                        <br/>
                        <p>A ranking poll is a survey where people order a list of items by preference. You can use it for elections, product preferences, event planning, decisions, or market research.</p>
                    </div>
                    <br/>
                    <br/>
                </Modal.Body>
            </Modal>
            <Foot />
        </>
            :""
    );
}
export default Convozeeditroom;

