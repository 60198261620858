import React, { useState} from "react";
import {API} from 'aws-amplify';
import {updateTextslide} from '../../graphql/mutations';
import {useParams} from "react-router-dom";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import katex from 'katex'
import "../css/poll.css";
import "./style.css";
import Resizer from "react-image-file-resizer";
const resizeFile = (file,width,height) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			width,
			height,
			"JPEG",
			85,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
const buttonList = [
	['undo', 'redo'],
	['font', 'fontSize', 'formatBlock'],
	['paragraphStyle', 'blockquote'],
	['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
	['fontColor', 'hiliteColor', 'textStyle'],
	['removeFormat'],
	['outdent', 'indent'],
	['align', 'horizontalRule', 'list', 'lineHeight'],
	['table', 'link', 'image', 'video', 'math'],
	[ 'showBlocks', 'codeView'],
	['preview', 'print'],
	['save']
]
function Newteslideedit() {
	const { id,rid } = useParams();
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [defaultValue, setDefaultValue] = useState(() => {
		const value = sessionStorage.getItem('newertesxtaditor')
		return value || ''
	})

	function handleSave (content) {
		try {
			const input = {
				id: rid,
				bgcolor:content
			}
		API.graphql({
				query: updateTextslide,
				variables: {input}
			});
			setUploadedmessage("Updated!")

		} catch (err) {
			console.log(err)
		}
	}
	const options = { buttonList, katex, callBackSave: handleSave}
	async function handleImageUpload(target, index, state, imageInfo) {
		const response = await fetch(target.src);
		const blob = await response.blob()
		const resized = await resizeFile(blob, target.width, target.height)
		target.src = resized
	}
	return (
		<>
			<div className="newtextslidepagebottom">
				<span className="redthis">{uploadedmessage}</span>
			</div>
		<div>
			<SunEditor setOptions={options} defaultValue={defaultValue} onImageUpload={handleImageUpload} height="540px"/>
		</div>
			</>
	);
}
export default Newteslideedit;
