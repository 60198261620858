import React, {useEffect, useRef, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {createDesmosfillin,updatePlayslide} from '../../../../graphql/mutations';
import { useForm } from "react-hook-form";
import {Form,Table} from "react-bootstrap";
import {getPlayslide} from "../../../../graphql/queries";
import Navbars from "../../../Navbar";
import Foot from "../../../Foot";
import {useParams,useHistory} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../../../css/math.css";
import {Helmet} from "react-helmet";
import Leftarrow from "../../../../img/left-arrow.png";
import Grid from "@material-ui/core/Grid";
import DesmosCalculator from "../DesmosCalculator";
import Resizer from "react-image-file-resizer";
import Mathfield from "../../../mathslide/Mathfield";

const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			400,
			400,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
function Mathfastanswer() {
	const { id } = useParams();
	const history = useHistory();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const addRef = useRef(null);
	const [selectedFile, setSelectedFile] = useState("");
	const [mathInput, setMathInput] = useState('')
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, [id]);
	function handleMathInputChange (value) {
		setMathInput(value);
	}
	const handleFileChange = async (event) => {
		const img = event.target.files[0];
		const resized = await resizeFile(img);
		setSelectedFile(resized)
	};
	const {register, handleSubmit} = useForm({});
	const reorderurl = `https://demo.reachum.com/mathfasteranswerroom/${id}/${d}`;
	const erurl = `https://demo.reachum.com/mathfastansweredit/${id}/${d}`;
	const ileaderboardurl = `https://demo.reachum.com/leaderboard/${id}`;
	const canser = `https://demo.reachum.com/mathfastercorrctanswer/${id}/${d}`;
	const tt = `${d}1`;
	const cc = `${d}333`;
	const onSubmit = async (data) => {
		const screenshot = addRef.current.screenshot()
		const response = await fetch(screenshot)
		const blob = await response.blob()
		const resized = await resizeFile(blob)
		try {
			const input = {
				id:d,
				ffname:data.ffname,
				ftimer:data.ftimer,
				fquestion:data.fquestion,
				fanswer:mathInput,
				image: selectedFile,
				mathstate:resized
			}
			console.log("input", input)
			await API.graphql({
				query: createDesmosfillin,
				variables: {input}
			});
			const urldata = {
				rid:d,
				title:data.ffname,
				rurl: reorderurl,
				eurl:erurl
			};
			const leaderboard = {
				rid:tt,
				title:"leaderboard",
				rurl:ileaderboardurl
			}
			const correcrur = {
				rid: cc,
				title:"correct answer",
				rurl: canser
			}
			const allImages = [...images, urldata, correcrur, leaderboard]
			const inputs = {
				id,
				playlistlinks: allImages
			}
			await API.graphql({
				query: updatePlayslide,
				variables: {input:inputs}
			})
			setTimeout(() => {
				history.push(`/editroom/${id}`);
			}, 500)
		} catch (err) {
			console.log(err)
		}
	}
	function scp(){
		history.push(`/editroom/${id}`);
	}
	return (
		<>
			{isOwner === isUser ?
				<>
					<Helmet>
						<title>Fill in the Blank</title>
					</Helmet>
					<Navbars/>
					<div className="mathfaspage">
						<br/>
						<br/>
						<h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13"/> Content
							Manager </h5>
						<br/>
						<div className="thiscenter">
							<h3>Fill in the Blank</h3>
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Grid container spacing={0}>
								<Grid item xs={2}>
									Game name : &nbsp;
								</Grid>
								<Grid item xs={10}>
									<input
										name="ffname"
										defaultValue=""
										ref={register({required: true, maxLength: 200})}
										className="quesmath"
									/>
								</Grid>
							</Grid>
							<br/>
							<Grid container spacing={0}>
								<Grid item xs={2}>
									Timer (Seconds) : &nbsp;
								</Grid>
								<Grid item xs={10}>
									<input
										name="ftimer"
										defaultValue=""
										ref={register({valueAsNumber: true, maxLength: 3})}
										className="timers"
										type="number"
									/>
								</Grid>
							</Grid>
							<br/>
							<Grid container spacing={0}>
								<Grid item xs={2}>
									Question : &nbsp;
								</Grid>
								<Grid item xs={10}>
				<textarea
					name="fquestion"
					defaultValue=""
					ref={register({required: true, maxLength: 200})}
					className="quesmathinput"
				/>
								</Grid>
							</Grid>
							<br/>
							<Grid container spacing={0}>
								<Grid item xs={2}>
									<p>Upload image:(option) </p>
								</Grid>
								<Grid item xs={10} className="leftside">
									<input type="file" onChange={handleFileChange}/>
								</Grid>
							</Grid>
							<div className="mathcccenter">
								<img src={selectedFile} className="pximages400"/>
							</div>
							<br/>
							<span>(Option)</span><span className="redthis"> After submission, you cannot make changes, but you have the option to recreate it. </span>
							<br/>
							<DesmosCalculator ref={addRef}/>
							<br/>
							<br/>
							<br/>
							<h4>Correct answer:</h4>
							<br/>
							<Grid container spacing={0}>
								<Grid container spacing={0}>
									<Grid item xs={3}>
										<p>Type math formulas: &nbsp; &nbsp; &nbsp;</p>
									</Grid>
									<Grid item xs={9} className="leftside">
										<div className="pxsize500">
											<Mathfield value={mathInput} onChange={handleMathInputChange}/>
										</div>
									</Grid>
								</Grid>
							</Grid>
							<br/>
							<br/>
							<Grid container spacing={0}>
								<Grid item xs={11} className="rightside">
									{uploadedmessage} <input type="submit" value="Submit" className="buttoncolor"/>
								</Grid>
								<Grid item xs={1}>
								</Grid>
							</Grid>
						</form>
					</div>
					<br/>
					<br/>
					<br/>
					<Foot/>
				</>
				: ""
			}
			</>
	);
}
export default Mathfastanswer;
