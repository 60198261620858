import React, {useState, useEffect} from 'react'
import Showitemlist from './showitemlist'
import {API, Auth} from "aws-amplify";
import { getRankingpoll} from "../../graphql/queries";
import {Form, Input} from "element-react";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../css/taptap.css";
import {CountdownCircleTimer} from "react-countdown-circle-timer";

export default function Showranking() {
  const [isOpen, setIsOpen] = useState(true);
  const [isgameopen, setIsgameopen] = useState(false);
  const [timeval, setTimeval] = useState(0);
  const [quest, setQuest] = useState();
  const { id, rid } = useParams();
  const [thiscate, setThiscate] = useState([]);
  const [counter, setCounter] = useState();

  useEffect(() => {
    fetchDas();
  }, []);
  async function fetchDas() {
    try {
      const response = await API.graphql({
        query: getRankingpoll,
        variables: {id:rid}
      })
      const datas = response.data.getRankingpoll;
      const timers = datas.dtimer;
      setCounter(timers)
      const ques = datas.dquestion;
      setQuest(ques);
      const caes = datas.orders;
      setThiscate(caes);
    }catch(err){
      console.log(err)
    }
  }
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setTimeval(0)
      return <div className="timer">0</div>;
    }else{
      setTimeval(remainingTime)
    }
    return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
    );
  }
  function savethisdata(){
    sessionStorage.setItem("rankinggames",JSON.stringify(thiscate));
    sessionStorage.setItem("rankinggamescounter", timeval);
    sessionStorage.setItem("rankinggamesques", quest);
    setIsgameopen(true);
  }
  return (
      <div>
        {isgameopen ?
            <>
              <Showitemlist/>
            </>
            :
            <>
            <div className="purpleback">
              <Grid container spacing={0}>
                <Grid item xs={2}>
                  <div className="lpollheadclock2">
                    <CountdownCircleTimer
                        isPlaying={true}
                        duration={counter}
                        size={50}
                        colors={["#bca0dc", "#bca0dc", "#bca0dc", "#bca0dc"]}
                        colorsTime={[10, 6, 3, 0]}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  </div>
                </Grid>
                <Grid item xs={10}>
                  <div className="fastanswes">
                    <h4>{quest}</h4>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="centerthis">
              <br/>
              <h3> Ranking Poll</h3>
              <br/>
              <p>Tap to select an item.<br/> Tap a grey slot to rank the item.</p>
              <p>If the slot is occupied, the new item will appear.</p>
              <p> Reset clears all slots.</p>
              <br/>
              <Button onClick={savethisdata} className="purplebutton">CLICK TO START</Button>
            </div>
            </>
        }
      </div>
  )
}
