import React from "react";
import Bigsmile from "../../img/smile.gif"

function Giphyedit(){
    return (
        <>
            <div className="thiscenter">
                <br/>
                <br/>
                <img src={Bigsmile} />
            </div>
        </>
    );
};

export default Giphyedit
