import React, {useEffect, useState} from 'react'
import {API, Auth} from "aws-amplify";
import {getSequencingdesmos} from "../../../../graphql/queries";
import {useParams} from "react-router-dom";
import MathJax from "react-mathjax";

export default function Desmoscorrectsequencing () {
  const { id, rid } = useParams();
  const [dquestion, setDquestion] = useState('');
  const [categoris, setCategoris] = useState([]);
  const [mathdata, setMathdata] = useState("");
  const [thisimage, setThisimage] = useState("");
  useEffect( () => {
    (async() => {
      const response = await API.graphql({
        query: getSequencingdesmos,
        variables:{id:rid}
      })
      const datas = response.data.getSequencingdesmos;
      const q = datas.dquestion
      setDquestion(q);
      const t1 = datas.orders;
      setCategoris(t1);
      const ma = datas.qmathstate;
      const mimage = datas.image;
      if(mimage !== null) {
        setThisimage(mimage)
      }
      if(ma !== null) {
        setMathdata(ma)
      }
    })()
  }, []);
  const renderData = () => {
    return (
        <div>
          {categoris.map((item, index) => (
              <li key={index} className="selist">
                {item.text} &nbsp; &nbsp;
                <img src={item.mathstate} className="mathscorrcshow"/>
              </li>
          ))}
        </div>
    );
  };

  return (
      <div className="correctanswerpage">
      <div className="corrctcentermath">
        <br/>
        <h2>{dquestion}</h2>
        <MathJax.Provider>
          <div className="mathshowfont">
            <MathJax.Node formula={mathdata} />
          </div>
        </MathJax.Provider>
        <br/>
        <img src={thisimage} className="fillinthimgshow" />
        <br/>
        <p>The correct answer is: </p>
      <ol className="centerthisse">
        {renderData()}
      </ol>
        <br/>
        <br/>
        <br/>
    </div>
     </div>
  )
}
