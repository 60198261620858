import React, {useEffect, useState} from "react";
import { API} from 'aws-amplify';
import {getTextslide} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import "../css/slide.css";


function Newteshowroom() {
    const [clist, setClist] = useState();
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDas();
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getTextslide,
                variables: {id:rid}
            })
            const datas = response.data.getTextslide;
            const tests = datas.bgcolor;
           sessionStorage.setItem("newertesxtaditor", tests)
            if(tests !== null) {
                setClist(tests);
            }
        }catch(err){
            console.log(err)
        }
    }
    const modules = {
        toolbar: false,
    }
    return (
        <>
            <div className="newtextshowpage">
                <div dangerouslySetInnerHTML={{ __html: clist }} />
            </div>
        </>
    )
}

export default Newteshowroom;
