import React, {useEffect, useState} from "react";
import {API, Auth, Storage} from 'aws-amplify';
import awsExports from "../../../aws-exports";
import {updatePlayslide} from '../../../graphql/mutations';
import { getPlayslide } from "../../../graphql/queries";
import {useParams,useHistory} from "react-router-dom";
import { UploadFiles } from './FileUpload';
import Navbars from "../../Navbar";
import Foot from "../../Foot";
import "../../css/uploadimg.css";
import {Card} from "react-bootstrap";
import {v4 as uuid} from "uuid";
import Leftarrow from "../../../img/left-arrow.png";
import Resizer from "react-image-file-resizer";


let vbucket = awsExports.aws_user_files_s3_bucket;
let vregion = awsExports.aws_user_files_s3_bucket_region;
function Uploadmp3() {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [processing, setProcessing] = useState("");
    const { id } = useParams();
    const history = useHistory();
    const [images, setImages] = useState([]);
    const [error, setError] = useState('');
    const [preview, setPreview] = useState(null);
    const [imagesnew, setImagesnew] = useState({});
    const [selectedFile, setSelectedFile] = useState("");
    const d = uuid();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const images = datas.playlistlinks;
            if(images != null) {
                setImages(images);
            }
        })()
    }, []);
    const  addImageTopDB = async (input) => {
        try {
            await API.graphql({
                query: updatePlayslide,
                variables: {input}
            });
        } catch (error) {
            console.log(error)
        }
    }
    const upload = async (files) => {
        setError('');
        const MAX_FILE_SIZE = 10240;
        const file = files[0];
        const fileSizeKiloBytes = file.size / 1024;
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            setError("File size is greater than maximum limit");
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        }
        const ext = file.name;
        const jnfile = `mp3/${d}${ext}`;
        await Storage.put(jnfile, file, {
            contentType: "audio/mpeg",
            progressCallback(progress) {
                const lods = progress.loaded;
                const done = progress.total;
                const loadin = Math.floor((lods/done) * 100);
                const per = loadin + "%";
                setProcessing(per)
                if( loadin === 100){
                        setProcessing("Uploaded")
                }
            },
        }).then(() => {
            const ryrl = `https://demo.reachum.com/mp3room/${id}/${d}`;
            const eryrl = `https://demo.reachum.com/mp3edit/${id}/${d}`;
            const vvv = {
                title: ext,
                file: {
                    bucket: vbucket,
                    region: vregion,
                    key: jnfile
                },
                rid: d,
                rurl: ryrl,
                eurl: eryrl
            }
            setImagesnew(vvv)
            const allImages = [...images, vvv];
            const input = {
                id,
                playlistlinks: allImages
            }
            addImageTopDB(input);
        })
            .catch(err => console.log(err));
    }

    function scp(){
        history.push(`/editroom/${id}`);
    }
    const handleFileChange = (event) => {
        var file = false;
        if (event.target.files[0]) {
            file = true;
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }else {
            setPreview(null);
        }
        if (file) {
            try {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    640,
                    360,
                    "jpg",
                    95,
                    0,
                    (uri) => {
                        setSelectedFile(uri);
                    },
                    "blob",
                    250,
                    250
                );
            } catch (err) {
                console.log(err);
            }
        }
    };
    const psubmit = async () => {
        if (selectedFile) {
            const file = selectedFile;
            const ajnfile = `uimg/${d}img`;
            await Storage.put(ajnfile, file, {
                contentType: "image/*",
                progressCallback(progress) {
                    const lods = progress.loaded;
                    const done = progress.total;
                    const loadin = Math.floor((lods / done) * 100);
                    const per = loadin + "%";
                    setProcessing(per)
                },
            }).then(() => {
                const url = `https://${vbucket}.s3.amazonaws.com/public/${ajnfile}`;
                imagesnew["lurl"] = url;
                console.log("imagesnew2", imagesnew)
                const allImagens = [...images, imagesnew];
                console.log("allImagens", allImagens)
                const input = {
                    id,
                    playlistlinks: allImagens
                }
                addImageTopDB(input);
            })
        }
    }
    return (
        isOwner === isUser ?
        <>
            <Navbars />
            <div className="uploadpptx">
                <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13"/> Content Manager
                </h5>
                <br/>
                <br/>
                <Card className="text-center">
                    <Card.Header as="h6">Upload a mp3 (10MB limit)</Card.Header>
                    <Card.Body>
                        {error && <h5>{error}</h5>}
                        <UploadFiles onUpload={upload}/>
                        <p>{processing}</p>
                    </Card.Body>
                </Card>
                <input type="file" accept="image/*" onChange={handleFileChange}/>
                <button onClick={psubmit} className="asubmit">Upload</button>
                {preview && (
                    <div>
                        <img
                            src={preview}
                            alt="File preview"
                            style={{maxWidth: '640px', maxHeight: '360px'}} // Adjust size here
                        />
                    </div>
                )}
            </div>
            <Foot/>
        </>
            : ""
    );
}

export default Uploadmp3;

