import React, {useState, useEffect} from 'react'
import Showcategory from './showcategory'
import {API, Auth} from "aws-amplify";
import {getDraganddrop,  getPlayslide} from "../../../graphql/queries";
import {Form, Input} from "element-react";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../css/taptap.css";
import Cheers from "../../../img/tontonfriends-cheers.gif";

export default function Showdragdrop() {
  const [isOpen, setIsOpen] = useState(true);
  const [isgameopen, setIsgameopen] = useState(false)
  const [quest, setQuest] = useState();
  const { id, rid } = useParams();
  const [thiscate, setThiscate] = useState([]);
  const [counter, setCounter] = useState();
  useEffect(() => {
    fetchDas();
  }, []);
  async function fetchDas() {
    try {
      const response = await API.graphql({
        query: getDraganddrop,
        variables: {id:rid}
      })
      const datas = response.data.getDraganddrop;
      const timers = datas.dtimer;
      setCounter(timers)
      const ques = datas.dquestion;
      setQuest(ques);
      const caes = datas.categories;
      setThiscate(caes);
    }catch(err){
      console.log(err)
    }
  }
  function savethisdata(){
    sessionStorage.setItem("dragdropcategories",JSON.stringify(thiscate));
    sessionStorage.setItem("dragdropcategoriescounter", counter);
    sessionStorage.setItem("dragdropcategoriesques", quest);
    setIsgameopen(true);
  }

  return (
      <div>
              {isgameopen ?
                  <>
                 <Showcategory />
                  </>
                  :
                  <div className="centerthis">
                    <br/>
                    <br/>
                    <h3> Tap Tap </h3>
                    <br/>
                    <p>Tap to select an item.</p>
                    <p>Then tap the category where it belongs.
                    </p>
                    <br/>
                    <Button onClick={savethisdata}>CLICK TO START</Button>
                  </div>
              }
      </div>

  )
}
