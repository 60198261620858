import React, {useEffect, useRef, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updatePlayslide} from '../../graphql/mutations';
import {getPlayslide} from "../../graphql/queries";
import Navbars from "../Navbar";
import Foot from "../Foot";
import {useParams,useHistory} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../css/poll.css";
import {Helmet} from "react-helmet";
import  Draws  from "./draw";
import Leftarrow from "../../img/left-arrow.png";
import Grid from "@material-ui/core/Grid";


function Blackboard() {
	const { id } = useParams();
	const history = useHistory();
	const [isUser, setIsUser] = useState(false);
	const [isOwner, setIsOwner] = useState(false);
	const [images, setImages] = useState([]);
	const [imgdata, setImgdata] = useState("")
	const d = uuid();
	useEffect( () => {
		Auth.currentAuthenticatedUser()
			.then(user => (setIsUser(user.attributes.email)))
			.catch(err => console.log(err));
		(async() => {
			const response = await API.graphql({
				query: getPlayslide,
				variables:{id}
			})
			const datas = response.data.getPlayslide;
			const iowner = datas.owner;
			setIsOwner(iowner);
			const images = datas.playlistlinks;
			if(images != null) {
				setImages(images);
			}
		})()
	}, []);
	const handleChildData = (data) => {
		setImgdata(data)
	};
	const saveData = async () => {
		const riurl = `https://demo.reachum.com/blackboardshowroom/${id}/${d}`
		const eiurl = `https://demo.reachum.com/blackboardedit/${id}/${d}`
			try {
				const urldata = {
					rid:d,
					title: "whiteboard",
					lurl:imgdata,
					eurl:eiurl,
					rurl: riurl
				}
				const allImages = [...images, urldata]
				const input = {
					id,
					playlistlinks: allImages
				}
				await API.graphql({
					query: updatePlayslide,
					variables: {input}
				});
				setTimeout(()=> {
					history.push(`/editroom/${id}`);
				},500)
			} catch (err) {
				console.log(err)
			}
	}
	function scp(){
		history.push(`/editroom/${id}`);
	}
	return (
		<>
			<Helmet>
				<title>Blackboard</title>
			</Helmet>
			<Navbars />
			{isOwner === isUser ?
				<>
				<div className="orderlist">
					<h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
					<div className="thiscenter">
						<br/>
						<h3>Draw</h3>
						<p>Draw and create visual content, including text, math, diagrams, flowcharts, and illustrations.
						<br/>It cannot be changed after clicking the submit button, but it can be redrawn in the edit field.</p>
					<Draws onChildData={handleChildData} />
					<br/>
						<Grid container spacing={0} className="topspace1">
							<Grid item xs={8}>
							</Grid>
							<Grid item xs={4} className="centerthis">
					<button className="addcontentbtn" onClick={saveData}>Submit</button>
							</Grid>
						</Grid>
					</div>
				</div>
				</>
				:""
			}
			<Foot/>
			</>
	);
}
export default Blackboard;
