import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {getPlayslide, listEngagements, listPlayslides} from '../../../graphql/queries';
import {Table} from 'react-bootstrap';
import "../../css/morereportrooms.css";
import Grid from "@material-ui/core/Grid";
import {Doughnut} from "react-chartjs-2";


function Pmorereponsereports(props) {
    const  uemial  = props.data;
    const [thisdatas, setThisdatas] = useState([]);
    const [thisalldatas, setThisalldatas] = useState([]);
    const [openthisd, setOpenthisd] = useState(true);
    const [openthisfisrt, setOpenthisfisrt] = useState(false);
    const [openthis, setOpenthis] = useState(false);
    const [engagenumber, setEngagenumber] = useState("");
    const [engagenumbera, setEngagenumbera] = useState("");
    const [engagenumbernoa, setEngagenumbernoa] = useState("");
    const [engagenumberno, setEngagenumberno] = useState("No");
    useEffect(() => {
        (async() => {
            const response = await API.graphql({
                query: listPlayslides,
                variables: {
                    filter: {
                        "owner": {
                            eq: uemial
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listPlayslides.items;
            console.log("datas", datas)
                const dataslength = datas.length;
            if(dataslength > 0){
                const scending = [...datas].sort((a, b) => a.meetingname.toLowerCase() < b.meetingname.toLowerCase() ? -1 : 1);
                setThisdatas(scending)
            }
        })()
    }, []);

    const renderrooms =()=>{
        return thisdatas.map((item, index) => (
                <div key={item.id} className="morereportrooms">
                    <label className="morereportform-control">
                    <input
                        name="moreresponserate"
                        value={`${item.id}`} // make sure to set up defaultValue
                        type="checkbox"
                        onChange={()=> {
                            setOpenthisfisrt(true)
                        }}
                        className="morereportcheckbox"
                    />
                        <span className="morereportcheckmark"></span>
                    <span> {item.meetingname} </span>
                    </label>
                </div>
        ))
    }
    async function submitItems (event) {
        event.preventDefault()
        const u=[];
        const tt = [];
        let eas;
        const checkbox = document.getElementsByName( "moreresponserate" );
        for (let i = 0; i < checkbox.length; i++) {
            if ( checkbox[i].checked === true ) {
                const peoples = [];
                const engages = [];
                const thisid = checkbox[i].value;
                const response = await API.graphql({
                    query: listEngagements,
                    variables: {
                        filter: {
                            "questionid": {
                                eq: thisid
                            }
                        },
                        limit: 10000
                    }
                })
                const datas = response.data.listEngagements.items;
                if (datas.length > 0) {
                    datas.forEach((data) => {
                        const listdata = data.people;
                        peoples.push(listdata)
                    })
                     const s = peoples.length;
                    datas.forEach((data) => {
                        const listdata = data.engage;
                        engages.push(listdata)
                    })
                    const sum = engages.reduce((partialSum, a) => partialSum + a, 0);
                    const numObj = (sum / s) * 100;
                    eas = Number.parseFloat(numObj).toFixed(1) + "%";
                } else {
                    eas = "100%";
                }
                const responseid = await API.graphql({
                    query: getPlayslide,
                    variables: {id: thisid}
                })
                const roomnames = responseid.data.getPlayslide;
                const roomname = roomnames.meetingname;
                const newArray = {roomName:roomname,rate:eas};
                tt.push(newArray)
                }
        };
        setThisalldatas(tt)
        const flatArray = tt.flat();
// Extract the rates from the objects
        const rates = flatArray.map((obj) => parseFloat(obj.rate));
// Calculate the average rate
        const averageRate = rates.reduce((sum, rate) => sum + rate, 0) / rates.length;
        const aas = averageRate.toFixed(1) + "%";
        const oaas = (100 - averageRate).toFixed(1) + "%";
        setEngagenumber(aas);
        setEngagenumberno(oaas);
        const daas = Math.floor(averageRate);
        const doaas = 100 - daas;
        setEngagenumbera(daas);
        setEngagenumbernoa(doaas);
        setOpenthisd(false);
        setOpenthis(true)
    }
    const data = {
        labels: ['Average Response Rate','Average No Response Rate'],
        datasets: [
            {
                data: [ engagenumbera, engagenumbernoa ],
                backgroundColor: [
                    '#269b7b',
                    'white'
                ],
                borderColor: 'black',
            },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: true,
    };

    const rendermorerates =()=>{
        return thisalldatas.map((item, index) => (
                <tr key={index}>
                    <td>{item.roomName}</td>
                    <td>{item.rate}</td>
                </tr>
        ))
    }
    function downloadFile() {
        const csvContent = "data:text/csv;charset=utf-8," + `Average Response Rate - ${engagenumber} \n` +
            "Lesson , Response Rate\n" +
            thisalldatas.map((room) => {
                return `${room.roomName},${room.rate}\n`;
            }).join('');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "REACHUM-responseRate.csv");
        document.body.appendChild(link);
        link.click();
    }
    return (
        <>
                            <div className="leaderpage">
                                <div className="reportpageheadview">
                                    <form onSubmit={submitItems}>
                                    <div className="wrapmorereport">{renderrooms()}</div>
                                        <br/>
                                        <br/>
                                        {openthisfisrt ?
                                            <div className="cccenter">
                                                {openthisd ?
                                                    <input type="submit" value="Make Report"
                                                           className="makereportbutton"/>
                                                    :
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={6} className="leftsidemake">
                                                            <input type="submit" value="Make New Report"
                                                                   className="makereportbutton"/>
                                                        </Grid>
                                                        <Grid item xs={6} className="rightside">
                                                    <button onClick={downloadFile} className="savesnewpread">Save as CSV
                                                        File</button>
                                                        </Grid>
                                                    </Grid>
                                                }

                                            </div>
                                            : ""
                                        }
                                    </form>
                                    {openthis ?
                                        <div className="cccenter">
                                            <br/>
                                            <Grid container spacing={0}>
                                                <Grid item xs={4}>
                                                    <div className="engageheightright">
                                                        <h3>{engagenumberno} <br/> students <br/> did not <br/> respond.</h3>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Doughnut data={data} options={options} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className="engageheight">
                                                        <h3>{engagenumber}</h3>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <br/>
                                        <Table bordered hover size="sm">
                                            <thead>
                                            <tr>
                                                <th>Lesson</th>
                                                <th>Response Rate</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {rendermorerates()}
                                            </tbody>
                                        </Table>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>

        </>
    )
}

export default Pmorereponsereports;
