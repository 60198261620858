import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {getPlayslide, listLeaderboards, listPlayslides} from '../../../graphql/queries';
import {Table} from 'react-bootstrap';
import "../../css/morereportrooms.css";
import Grid from "@material-ui/core/Grid";



function Pmorereports(props) {
    const  uemial  = props.data;
    const [isOwner, setIsOwner] = useState("");
    const [isUser, setIsUser] = useState("");
    const [thisdatas, setThisdatas] = useState([]);
    const [thisalldatas, setThisalldatas] = useState([]);
    const [openthisd, setOpenthisd] = useState(true);
    const [openthis, setOpenthis] = useState(false);
    const [openthisfisrt, setOpenthisfisrt] = useState(false);
    useEffect(() => {
        (async() => {
            const response = await API.graphql({
                query: listPlayslides,
                variables: {
                    filter: {
                        "owner": {
                            eq: uemial
                        }
                    },
                    limit:10000
                }
            })
            const datas = response.data.listPlayslides.items;
            console.log("datas", datas)
                const dataslength = datas.length;
            if(dataslength > 0){
                const scending = [...datas].sort((a, b) => a.meetingname.toLowerCase() < b.meetingname.toLowerCase() ? -1 : 1);
                setThisdatas(scending)
            }
        })()
    }, []);

    const renderrooms =()=>{
        return thisdatas.map((item, index) => (
                <div key={item.id} className="morereportrooms">
                    <label className="morereportform-control">
                    <input
                        name="moreleader"
                        value={`${item.id}`} // make sure to set up defaultValue
                        type="checkbox"
                        onChange={()=> {
                            setOpenthisfisrt(true)
                        }}
                        className="morereportcheckbox"
                    />
                        <span className="morereportcheckmark"></span>
                    <span> {item.meetingname} </span>
                    </label>
                </div>
        ))
    }
    async function submitItems (event) {
        event.preventDefault()
        const u=[];
        const tt = [];
        const checkbox = document.getElementsByName( "moreleader" );
        for (let i = 0; i < checkbox.length; i++) {
            if ( checkbox[i].checked === true ){
                const thisid = checkbox[i].value;
                const response = await API.graphql({
                    query: listLeaderboards,
                    variables: {
                        filter: {
                            "answerid": {
                                eq: thisid
                            }
                        },
                        limit:10000
                    }
                })
                const datas = response.data.listLeaderboards.items;
                u.push(datas);
                const responseid = await API.graphql({
                    query: getPlayslide,
                    variables: {id: thisid}
                })
                const roomnames = responseid.data.getPlayslide;
                const roomname = roomnames.meetingname;
                const newArray = datas.map(obj => ({
                    ...obj,
                    roomName: roomname,
                    score: obj.score,
                }));
                tt.push(newArray)
            }
        };
        const groupedByEmail = {};

// Iterate through the array and organize objects by email
        tt.forEach(subArray => {
            subArray.forEach(obj => {
                const { meetemail, name, ...rest } = obj;
                if (!groupedByEmail[meetemail]) {
                    groupedByEmail[meetemail] = [{ name, meetemail, data: [{ roomName: obj.roomName, score: obj.score }] }];
                } else {
                    const existingGroup = groupedByEmail[meetemail][0];
                    const existingData = existingGroup.data;
                    const existingRoom = existingData.find(item => item.roomName === obj.roomName);

                    if (existingRoom) {
                        existingRoom.status = obj.status;
                    } else {
                        existingData.push({ roomName: obj.roomName, score: obj.score });
                    }
                }
            });
        });
        const resultArrays = Object.values(groupedByEmail).map(emailGroup => {
            return {
                name: emailGroup[0].name,
                meetemail: emailGroup[0].meetemail,
                rooms: emailGroup[0].data
            };
        });
        const aggregatedData = {};
        u.forEach(nestedArray => {
            nestedArray.forEach(item => {
                const { meetemail, score, name } = item;
                if (!aggregatedData[meetemail]) {
                    aggregatedData[meetemail] = { score, name };
                } else {
                    aggregatedData[meetemail].score += score;
                }
            });
        });
        const resultArray = Object.keys(aggregatedData).map(meetemail => ({
            meetemail,
            name: aggregatedData[meetemail].name,
            score: aggregatedData[meetemail].score
        }));
       const scounts = [];
        const dcounts=[];
        resultArray.forEach((data) => {
            const listdata = data;
            scounts.push(listdata)
        })
        scounts.sort((a, b) => {
            return b.score - a.score;
        });
        scounts.forEach((e) => {
            dcounts.push(e)
        });
        const mergedByEmail = {};

// Merge data from array1
        resultArrays.forEach(obj => {
            const { meetemail, name, rooms } = obj;
            if (!mergedByEmail[meetemail]) {
                mergedByEmail[meetemail] = { name, email: meetemail, rooms };
            } else {
                mergedByEmail[meetemail].rooms = mergedByEmail[meetemail].rooms.concat(rooms);
            }
        });

// Merge data from array2
        dcounts.forEach(obj => {
            const { meetemail, name, score } = obj;
            if (mergedByEmail[meetemail]) {
                mergedByEmail[meetemail].score = score;
            }
        });

// Convert the merged data back to an array
        const mergedArray = Object.values(mergedByEmail);

        setThisalldatas(mergedArray);
        setOpenthis(true)
        setOpenthisd(false)
    }
    const renderleaderboard =()=>{
        return thisalldatas.map((item, index) => (
            isOwner === isUser ?
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.score}</td>
                    <td>{item.rooms.map((item, index) => (
                        <div key={index}>
                            <p>{item.roomName} - {item.score}</p>
                        </div>
                    ))}
                    </td>
                </tr>:""
        ))
    }
    function downloadFile() {
        const csvContent = "data:text/csv;charset=utf-8," +
            "Email,Name,Total Score ,Lesson ,Score\n" +
            thisalldatas.map((entry) => {
                const roomData = entry.rooms.map((room) => {
                    return `${entry.email},${entry.name},${entry.score},${room.roomName},${room.score}\n`;
                }).join('');
                return roomData;
            }).join('');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "REACHUM-Leaderboard.csv");
        document.body.appendChild(link);
        link.click();
    }

    return (
        <>
                            <div className="leaderpage">
                                <div className="reportpageheadview">
                                    <form onSubmit={submitItems}>
                                    <div className="wrapmorereport">{renderrooms()}</div>
                                        <br/>
                                        <br/>
                                        {openthisfisrt ?
                                            <div className="cccenter">
                                                {openthisd ?
                                                    <input type="submit" value="Make Report"
                                                           className="makereportbutton"/>
                                                    :
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={6} className="leftsidemake">
                                                            <input type="submit" value="Make New Report"
                                                                   className="makereportbutton"/>
                                                        </Grid>
                                                        <Grid item xs={6} className="rightside">
                                                            <button onClick={downloadFile} className="savesnewpread">Save as CSV
                                                                File</button>
                                                        </Grid>
                                                    </Grid>
                                                }

                                            </div>
                                            : ""
                                        }
                                    </form>
                                    {openthis ?
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Player Name</th>
                                                <th>Player Email</th>
                                                <th>Total Score</th>
                                                <th>Lesson - Score</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderleaderboard()}
                                            </tbody>
                                        </Table>
                                        : ""
                                    }
                                </div>
                            </div>

        </>
    )
}

export default Pmorereports;
