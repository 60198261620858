import React, {useState, useEffect} from 'react'
import Showitemlist from './showitemlist'
import {API, Auth} from "aws-amplify";
import {getPlayslide, getSequencingdesmos} from "../../../../graphql/queries";
import {Form, Input} from "element-react";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../../css/taptap.css";
import Playsequcing from "../../../../img/menu/dis/sequrncing.gif"

export default function Showdesmossequencing() {
  const [isOpen, setIsOpen] = useState(true);
  const [isgameopen, setIsgameopen] = useState(false)
  const [quest, setQuest] = useState("");
  const [mathin, setMathin] = useState("");
  const { id, rid } = useParams();
  const [thiscate, setThiscate] = useState([]);
  const [counter, setCounter] = useState();
  const [mathdata, setMathdata] = useState("");
  const [thisimage, setThisimage] = useState("");
  const [stateData, setStateData] = useState({
    meetname:""
  })

  useEffect(() => {
    fetchDas();
    Auth.currentAuthenticatedUser()
        .then(user => {
          const thisuser = user.attributes.email;
          (async () => {
            const response = await API.graphql({
              query: getPlayslide,
              variables: {id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            if (thisuser === iowner) {
              setIsOpen(true)
            }else{
              const reachumnname = localStorage.getItem("vreachumnewername");
              if(!reachumnname){
                setIsOpen(false)
              }
            }
          })()
        })
  }, []);
  async function fetchDas() {
    try {
      const response = await API.graphql({
        query: getSequencingdesmos,
        variables: {id:rid}
      })
      const datas = response.data.getSequencingdesmos;
      const timers = datas.dtimer;
      setCounter(timers)
      const ques = datas.dquestion;
      setQuest(ques);
      const caes = datas.orders;
      setThiscate(caes);
      const ma = datas.mathstate;
      const mimage = datas.image;
      if(mimage !== null) {
        setThisimage(mimage)
      }
      if(ma !== null) {
        setMathdata(ma)
      }
    }catch(err){
      console.log(err)
    }
  }
  function savethisdata(){
    sessionStorage.setItem("desmosequencinggamesarray",JSON.stringify(thiscate));
    sessionStorage.setItem("desmosequencinggamescounter", counter);
    sessionStorage.setItem("desmosequencinggames", mathin);
    sessionStorage.setItem("desmosequencinggamesques", quest);
    sessionStorage.setItem("desmosequencinggamesmathinput", mathdata);
    sessionStorage.setItem("desmosequencinggamesqueimage", thisimage);
    setIsgameopen(true);
  }

  const handleSubmit2 = async event => {
    event.preventDefault();
    try {
      const ssname = stateData.meetname;
      const ssemail = stateData.meetemail;
      localStorage.setItem("vreachumnewername", ssname);
      localStorage.setItem("vreachumneweremail", ssemail);
      setIsOpen(true)

    }catch(err){
      console.log(err)
    }
  }
  return (
      <div>
        {isOpen ?
            <>
              {isgameopen ?
                  <>
                 <Showitemlist />
                  </>
                  :
                  <div className="centerthis">
                    <br/>
                    <h3> Sequencing </h3>
                    <img src={Playsequcing} className="playsquencingborder"/>
                    <br/>
                    <br/>
                    <p>Tap to select an item,</p>
                      <p>then tap again to place the item
                      in the proper slot in the sequence.</p>

                      <p>Tapping an occupied slot replaces the
                      earlier selection.</p>

                     <p> To clear all slots, click the “Reset” button.
                    </p>
                    <br/>
                    <Button onClick={savethisdata}>CLICK TO START</Button>
                  </div>
              }
            </>
              :
            <div className="enteryournameform">
              <Form className="market-header">
                <h5> Create Display Name</h5>
                <Form.Item>
                  <Input
                      type="text"
                      value={stateData.meetname}
                      onChange={meetname => setStateData({
                        ...stateData,
                        meetname: meetname
                      })}
                      maxLength={80}
                      placeholder="Username"
                      className="craeteroominput"
                  />
                </Form.Item>
                <Form.Item>
                  <h5> Enter Email</h5>
                  <Input
                      type="email"
                      value={stateData.meetemail}
                      onChange={meetemail => setStateData({
                        ...stateData,
                        meetemail: meetemail
                      })}
                      maxLength={100}
                      placeholder="Your Email"
                      className="craeteroominput"
                  />
                </Form.Item>
                <Form.Item>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6} className="rightside">
                      <Button
                          disabled={!stateData.meetemail || !stateData.meetname}
                          onClick={handleSubmit2}
                          size="lg"
                          className="ibuttns"
                      >
                        Enter
                      </Button>
                    </Grid>
                  </Grid>
                </Form.Item>
                <p className="privacytext">
                  We respect your privacy. Any personal data you provide to REACHUM in our meeting platform
                  will only be stored for game platform and progress tracking purposes. REACHUM does not share information
                  with third parties.</p>
              </Form>
            </div>
        }
      </div>

  )
}
