import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {updateMultioptionslist} from '../../../graphql/mutations';
import {Form,Table} from "react-bootstrap";
import {getMultioptionslist} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/optionshowroom.css";
import DeleteIcon from "../../../img/dash/Delete-Button-X.png";
import Add from "../../../img/dash/Add-Button.png";

function Multioptionsedit() {
	const { id,rid } = useParams();
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [dname, setDname] = useState('')
	const [polltimer, setPolltimer] = useState('')
	const [dquestion, setDquestion] = useState('')
	const [items1, setItems1] = useState([{ panswer: '' }])
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getMultioptionslist,
				variables:{id:rid}
			})
			const datas = response.data.getMultioptionslist;
			const dn = datas.mfname
			setDname(dn)
			const tm = datas.mtimer
			setPolltimer(tm)
			const q = datas.mquestion
			setDquestion(q)
			const i1 = datas.mtest
			setItems1(i1)
			const num = datas.manswer;
			for (var i=0; i< num.length; i++) {
				const t = Number(num[i].checkans);
				document.getElementsByName('options')[t].checked=true
			}
		})()
	}, []);

	function changedtimer(event){
		setPolltimer(event.target.value)
	}
	function changedquestion(event){
		setDquestion(event.target.value)
	}
	function addItem1 () {
		setItems1(state => [...state, ''])
	}

	const items1View = items1.map((item, index) => {
		function deleteItem () {
			setItems1(state => {
				return state.filter((item, stateIndex) => index !== stateIndex)
			})
		}
		function changeItem (event) {
			event.persist()
			setItems1(state => {
				return state.map((item, stateIndex) => {
					const match = index === stateIndex
					if (match) {
						const newItem = { ...item, panswer: event.target.value, id: stateIndex }
						return newItem
					}
					return item
				})
			})
		}
		return (
			<li key={index} className="optionslistdistanse">
				<input
					name="options"
					value={`${index}`} // make sure to set up defaultValue
					type="checkbox"
					onChange={()=> {
						console.log(`${index}`)
					}}
				/>
				<input value={item.panswer} onChange={changeItem} placeholder=""  className="optionsullwidth"/>
				<img src={DeleteIcon} onClick={deleteItem} className="dragdropdele" />
			</li>
		)
	})

	async function submitItems (event) {
		event.preventDefault()
		const u=[];
		const checkbox = document.getElementsByName( "options" );
		for (let i = 0; i < checkbox.length; i++) {
					if ( checkbox[i].checked === true ){
                       u.push({"checkans": i})
					}
			};
		if(u.length !== 0) {
			try {
				const input = {
					id: rid,
					mtimer: polltimer,
					mquestion: dquestion,
					mtest: items1,
					manswer: u
				}
				await API.graphql({
					query: updateMultioptionslist,
					variables: {input}
				});
				setUploadedmessage("Updated!")

			} catch (err) {
				console.log(err)
			}
		}else{
			setUploadedmessage("Please check the correct ones.")
		}
	}

	return (
		<div className="orderlist">
			<form onSubmit={submitItems}>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<Form.Label>Game name: {dname}</Form.Label>
				</Form.Group>
				<Form.Group className="mb-3" >
					<Form.Label>Update Timer (Seconds) : </Form.Label>
					<br/>
					<input
						value={polltimer}
						type="number"
						onChange={changedtimer}
						className="dragdroptimer"
					/>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Update Question : </Form.Label>
					<br/>
					<textarea type="text" value={dquestion} onChange={changedquestion} className="thiseditque" />
				</Form.Group>
				<Form.Group className="mb-3" >
					<Form.Label>Update Answers : </Form.Label>
					<ol>
						{items1View}
					</ol>
					<div>
						<img src={Add} onClick={addItem1} />
					</div>
				</Form.Group>
				<hr/>
				<br/>
				<Table>
					<tr>
						<td>
						</td>
						<td className="orderright">
							{uploadedmessage} <input type="submit" value="Submit" id="summitb" />
						</td>
					</tr>
				</Table>
			</form>
		</div>
	);
}
export default Multioptionsedit;
