import React, { useState, useEffect, useRef } from "react";
import {useHistory, useParams} from "react-router-dom";
import {API, Auth} from "aws-amplify";
import {getPlayslide} from "../graphql/queries";
import Login from "./login"
import {Helmet} from "react-helmet";
import "../pages/css/exportpdf.css"
import {CopyToClipboard} from "react-copy-to-clipboard";
import Leftarrow from "../img/left-arrow.png";
import Grid from "@material-ui/core/Grid";
import Navbars from "../components/Navbar";
import Foot from "../components/Foot";

const Exportpdf = ()  => {
    const { id } = useParams();
    const history = useHistory();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [playcontents, setPlaycontents] = useState([]);
    const [ymeeting, setYmeeting] = useState("");
    const [copied, setCopied] = useState("");
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setIsUser(user.attributes.email);
            })
            .catch(err => console.log(err));
        fetchData();
    }, []);
    async function fetchData() {
        const response =  await API.graphql({
            query: getPlayslide,
            variables: { id }
        });
        const datas = response.data.getPlayslide;
        const iowner = datas.owner;
        setIsOwner(iowner);
        const emeeting = datas.meetingname;
        setYmeeting(emeeting);
        const edatas = datas.playlistlinks;
        if(edatas === null){
            console.log("hello");
        }else {
            setPlaycontents(edatas);
        }
    }
    function scp(){
        history.push(`/editroom/${id}`);
    }
    return(
        <>
            <Helmet>
                <title>Lesson Plan</title>
            </Helmet>
            {!isOwner === isUser ?
                <Login />
                :(
                    <>
                        <Navbars />
                    <div className="pdfpage">
                        <Grid container spacing={0}>
                            <Grid item xs={3}>
                        <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
                            </Grid>
                            <Grid item xs={9}>
                            </Grid>
                        </Grid>
                        <br/>
                        <br/>
                        <h2>{ymeeting}</h2>
                        <span>Share the Lesson Plan(View only) - Click the link to share.</span>
                        <div>
                        <CopyToClipboard text={`https://demo.reachum.com/publicLessonPlan/${id}`} onCopy={() => {
                            setCopied(true);
                            setTimeout(() => {
                                setCopied(false);
                            }, 3000);
                        }}>
                            <div className="upgradenew2">
                                <span className="buttoncolor">{`https://demo.reachum.com/publicLessonPlan/${id}`}</span>
                                <span className="tooltiptext2">
                                        Copy the public's link
                                    </span>
                            </div>
                        </CopyToClipboard>
                        <br/>
                        {copied ? <span style={{color: 'red'}}>Copied</span> : null}
                        </div>
                        <br/>
                        {playcontents.map((page, index) => (
                            <div key={index} className="pdfiframe">
                                <p>{index+1} - {page.title}</p>
                                <iframe src={page.rurl} allowFullScreen={true} className="pdfiframes"/>
                            </div>
                        ))}
                    </div>
                        <Foot />
                        <br/>
                        <br/>
                        <br/>
                        </>
                )
            }
        </>

    )
}


export default Exportpdf;
