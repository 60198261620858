import { GiphyFetch } from "@giphy/js-fetch-api";
import { config } from "../config";

const giphyFetch = new GiphyFetch(config.giphy.apiKey);

const LIMIT_PER_FETCH = 100;

export function getTrending(offset) {
  const options = { offset, limit: LIMIT_PER_FETCH };
  return giphyFetch.trending(options);
}

export function performSearch(term) {
  return (offset) => {
    const options = { offset, limit: LIMIT_PER_FETCH };
    return giphyFetch.search(term, options);
  }
}
