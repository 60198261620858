import React, { useEffect, useState } from 'react'
import {useParams} from "react-router-dom";
import {API} from "aws-amplify";
import {getVideooptionslist} from '../../../graphql/queries';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player'
import Showoptions from './showoptions';
import './videoplayer.css';
import Bigplay from '../../../img/bigplay.png';

const Videoplayer = () => {
    const { id, rid } = useParams();
    const [source, setSource] = useState("");
    const [vcutime, setVcutime] = useState(0);
    const [show, setShow] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [playing, setPlaying] = useState(false);
    useEffect(() => {
        fetchDatas();
    }, []);

    async function fetchDatas() {
        try {
            const responses = await API.graphql({
                query: getVideooptionslist,
                variables:{id:rid}
            });
            const datas = responses.data.getVideooptionslist;
            const videopath = datas.videoid;
            const rurls = `https://reachumvideos.s3.amazonaws.com/public/videos/${videopath}/${videopath}.mp4`;
            setSource(rurls);
            const ccurentime = datas.vsettime;
            setVcutime(ccurentime)
        }catch(error){
            console.log("Error", error)
        }
    }
    const handleProgress = (data) => {
        if (Math.floor(data.playedSeconds) === vcutime) {
            setShow(true);
            setPlaying(false)
        }
    }
      const handlePlay = () => {
        setPlaying(true);
       setIsOpen(false)
      }
    return (
        <>
            <div className="fullvideoqesshowpage">
            {isOpen ?
                <div className="videoplayplay">
                <img src={Bigplay} onClick={handlePlay} alt="player" className="playercenter"/>
                </div>
                :
                ""
            }
            <div className="vidshows">
                <div className='playerwrapper'>
                <ReactPlayer
                url={source}
                playing={playing}
                onProgress={handleProgress}
                onPlay={handlePlay}
                className="reactplayer"
                playsinline
                 />
                </div>
            </div>
            <Modal show={show} size="xl">
                <Modal.Header closeButton onClick={() => {
                    setShow(false);
                    setPlaying(true)
                }}>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Showoptions />
                </Modal.Body>
            </Modal>
            </div>
        </>
    )
}

export default Videoplayer
