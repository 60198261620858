import { Storage } from 'aws-amplify';

const config = {
  level: "public",
  contentType: "image/webp",
}

export default function uploadFromUrlToS3(url, name){
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url);
      const imageBlob = await response.blob();
      const filenam = `img/${name}`;
      const { key } = await Storage.put(filenam, imageBlob, config);
      const uploadedFile = await Storage.get(key);

      resolve(uploadedFile);
    } catch (err) {
      console.error('uploadFromUrlToS3', err);
      reject(err);
    }
  });
}
