import React, {useEffect,useState} from "react";
import { API} from 'aws-amplify';
import {useParams} from "react-router-dom";
import {getPlayslide} from '../../../graphql/queries';
import {Image} from "react-bootstrap";
import "../img/style.css";

function Pdfshowroom() {
    const [jvfile, setVjfile] = useState();
    const [itt, setItt] = useState("");
    const [highlighted, setHighlighted] = useState(false);
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDatas();
    }, []);
    async function fetchDatas() {
        const response = await API.graphql({
            query: getPlayslide,
            variables:{id}
        })
        const datas = response.data.getPlayslide.playlistlinks;
        const newdatas = datas.filter(data => data.rid === rid);
        const lurls = newdatas[0].lurl;
        setVjfile(lurls);
        const tt = newdatas[0].title;
        setItt(tt)
    }
    const toggleHighlight = () => {
        setHighlighted(!highlighted);
    };
    return (
        <>
            <div className="container">
            <div className="image-container">
                <Image src={jvfile} fluid className="imgviewsite" alt={itt}/>
                {/* Whiteboard overlay */}
                <div className="whiteboard">
                    <p>This is your whiteboard for writing notes.</p>

                    {/* Button to toggle highlight */}
                    <button onClick={toggleHighlight}>
                        {highlighted ? "Remove Highlight" : "Highlight Text"}
                    </button>

                    {/* Highlighted text box */}
                    {highlighted && (
                        <div className="highlight-box">
                            Highlighted Text Example
                        </div>
                    )}
                </div>
            </div>
            </div>
        </>
    );
}

export default Pdfshowroom;
