import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import { updateFasteranswer} from '../../../graphql/mutations';
import { useForm } from "react-hook-form";
import {Form,Table} from "react-bootstrap";
import {getFasteranswer} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/fastaneditpage.css";
import Grid from "@material-ui/core/Grid";

function Fastansweredit() {
	const { id,rid } = useParams();
	const [fname, setFname] = useState("");
	const [ftimers, setFtimers] = useState();
	const [fques, setFques] = useState("");
	const [fansw, setFansw] = useState("");
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [showtimerform, setShowtimerform] = useState(false)
	useEffect( () => {
		(async() => {
			const responses = await API.graphql({
				query: getFasteranswer,
				variables:{id:rid}
			})
			const datas = responses.data.getFasteranswer;
			const ffname = datas.ffname;
			setFname(ffname)
			const ftimer = datas.ftimer;
			if(ftimer) {
				setFtimers(ftimer);
				setShowtimerform(true)
			}
			const fquestion = datas.fquestion;
			setFques(fquestion)
			const fanswer = datas.fanswer;
			setFansw(fanswer)
		})()
	}, [rid]);
	const {register, handleSubmit} = useForm({});
	const onSubmit = async (data) => {
		try {
			const input = {
				id:rid,
				ftimer:data.ftimer,
				fquestion:data.fquestion,
				fanswer:data.fanswer
			}
			await API.graphql({
				query: updateFasteranswer,
				variables: {input}
			});
			setUploadedmessage(" Updated. ")
		} catch (err) {
			console.log(err)
		}
	}
	return (
		<>
			<div className="fastaneditpage">
		<form onSubmit={handleSubmit(onSubmit)}>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Game name: {fname}</Form.Label>
			</Form.Group>
			<Grid container spacing={0}>
				<Grid item xs={3} className="rightside">
					Timer : &nbsp;
				</Grid>
				<Grid item xs={9}>
					<Grid container spacing={0}>
						<Grid item xs={7} className="cccenter">
							<input type="radio" name="timer" value="AddTimer" onChange={()=>{setShowtimerform(true)}}/> Add Timer (Seconds) &nbsp;
							{showtimerform ?
								<input
									name="ftimer"
									defaultValue=""
									ref={register({valueAsNumber: true, maxLength: 3})}
									className="timers"
									type="number"
								/>
								: ""
							}
						</Grid>
						<Grid item xs={5} className="cccenter">
							<input type="radio" name="timer" value="NoTimer" onChange={()=>{setShowtimerform(false)}} /> No Timer
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Update question</Form.Label>
				<br/>
				<textarea
					name="fquestion"
					defaultValue={fques}
					ref={register({ required: true, maxLength: 200})}
					className="ques"
				/>
			</Form.Group>
			<Form.Group className="mb-3" controlId="formBasicPassword">
				<Form.Label>Update correct answer</Form.Label>
				<br/>
				<input
					name="fanswer"
					defaultValue={fansw}
					ref={register({ required: true, maxLength: 200})}
					className="ques"
				/>
			</Form.Group>
			<Table>
				<tr>
					<td>
					</td>
				<td className="orderright">
					{uploadedmessage} <input type="submit" value="Submit"/>
				</td>
				</tr>
			</Table>
		</form>
			</div>
			</>
	);
}
export default Fastansweredit;
