import React, { useRef, useEffect } from 'react';
import "./style.css"

export default function Mathfield ({ value, onChange }) {
    const ref = useRef(null);
    useEffect(() => {
        function handleInput () {
            if (onChange != null) {
                onChange(ref.current.value)
            }
        }
        if (ref.current != null) {
            ref.current.addEventListener('input', handleInput)
            return () => {
                if (ref.current != null) {
                    ref.current.removeEventListener('input', handleInput)
                }
            }
        }
    }, [ref.current])
    return (
        <math-field ref={ref} value={value} class="thismathfield" />
    );
};
