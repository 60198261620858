import React, { useState} from 'react';
import {API, Auth} from 'aws-amplify';
import {listHosts} from "../graphql/queries";
import { v4 as uuidv4 } from 'uuid';
import {updateHost} from "../graphql/mutations";

const Teamslogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [teamsContext, setTeamsContext] = useState('');

    const handleLogin = async () => {
        try {
            const user = await Auth.signIn(username, password);
            if(user){
                const useremail = user.attributes.email;
                const response = await API.graphql({
                    query: listHosts,
                    variables: {
                        filter: {
                            "owner": {
                                eq: useremail
                            }
                        },
                        limit: 10000
                    }
                })
                const datas = response.data.listHosts.items;
                if(datas.length > 0) {
                    const thisid = datas[0].id;
                    const newString = uuidv4().slice(0, 8);
                    const input = {
                        id:thisid,
                        statustime: newString
                    }
                    const yy = await API.graphql({
                        query: updateHost,
                        variables: {input}
                    });
                    console.log("yy", yy)
                    setTeamsContext(newString)
                }
            }

        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <>
            <br/>
            <br/>
            <br/>
        <div className="centerthis">
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <br/>
            <br/>
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <br/>
            <br/>
            <button onClick={handleLogin}>Login</button>
            <br/>

            {error && <div>{error}</div>}
            <br/>
            <br/>
            <p>{teamsContext}</p>
        </div>
            </>
    );
};

export default Teamslogin;

