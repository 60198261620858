import React, {useEffect, useState, useRef} from "react";
import {API} from 'aws-amplify';
import {updateMultioptionsdesmoslist} from '../../../../graphql/mutations';
import {getMultioptionsdesmoslist} from "../../../../graphql/queries";
import { useForm } from "react-hook-form";
import {useParams,useHistory} from "react-router-dom";
import { v4 as uuid } from 'uuid';
import "../../../css/poll.css";
import DeleteIcon from "../../../../img/dash/Delete-Button-X.png";
import Add from "../../../../img/dash/Add-Button.png";
import Grid from "@material-ui/core/Grid";
import DesmosCalculator from '../DesmosCalculator';
import Resizer from "react-image-file-resizer";
import Mathfield from "../../../mathslide/Mathfield";


const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			400,
			400,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});

function Multidesmosedit() {
	const { id,rid } = useParams();
	const history = useHistory();
	const ref = useRef(null);
	const [options, setOptions] = useState([]);
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [mques, setMques] = useState("");
	const [showques, setShowques]= useState(false);
	const [isActive, setIsActive] = useState(true);
	const [dname, setDname] = useState("");
	const [mtimer, setMtimer] = useState("");
	const [newOption, setNewOption] = useState(''); // Input field for adding new options
	const addRef = useRef(null);
	const [selectedFile, setSelectedFile] = useState("");
	const [mathInput, setMathInput] = useState('')
	const d = uuid();
	const {register, control, handleSubmit} = useForm({
		defaultValues: {
			ptest: [{panswer: ""}]
		}
	});
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getMultioptionsdesmoslist,
				variables:{id:rid}
			})
			const datas = response.data.getMultioptionsdesmoslist;
			const dd = datas.mfname;
			setDname(dd);
			const dc = datas.mquestion
			setMques(dc)
			const tt = datas.mtimer;
			setMtimer(tt);
			const fanswesr = datas.mathstate;
			ref.current.value = fanswesr;
			const mimage = datas.image;
			if(mimage !== null){
				setSelectedFile(mimage)
			}
		})()
	}, []);
	useEffect(() => {
		function handleInput () {
			const prompts = ref.current.getPrompts()
			const numerator = ref.current.getPromptValue('numerator')
		}
		if (ref.current != null) {
			ref.current.addEventListener('input', handleInput)
			return () => {
				if (ref.current != null) {
					ref.current.removeEventListener('input', handleInput)
				}
			}
		}
	}, [ref.current]);

	const handleAddOption = async () => {
		// Add a new option to the options state
		if (newOption.trim() !== '') {
			const screenshot = addRef.current.screenshot()
			const response = await fetch(screenshot)
			const blob = await response.blob()
			const resized = await resizeFile(blob)
			setOptions([...options, { panswer: newOption, checkans: false, mathstate: resized }]);
			setNewOption('');
		}
	};
	const handleOptionChange = (index) => {
		// Toggle the checkbox state for the selected option
		const updatedOptions = [...options];
		updatedOptions[index].checkans = !updatedOptions[index].checkans;
		setOptions(updatedOptions);
	};
	function handleMathInputChange (value) {
		setMathInput(value);
	}
	const handleFileChange = async (event) => {
		const img = event.target.files[0];
		const resized = await resizeFile(img);
		setSelectedFile(resized)
	};
	const handleDeleteOption = (index) => {
		// Remove the selected option from the options state
		const updatedOptions = [...options];
		updatedOptions.splice(index, 1);
		setOptions(updatedOptions);
	};
	const tt = `${d}1`;
	const ccu = `${d}333`;

	const onSubmit = async (data) => {
		const arrayOfObjectsWithId = options.map((obj, index) => ({ id: index, ...obj }));
		const arrayOfObjectsWithoutcheckbox = arrayOfObjectsWithId.map(({ checkans, ...rest }) => rest);
		const arrayOfObjects = options.map(({ panswer,mathstate, ...rest }) => rest);
		if(options.length !== 0) {
			try {
				const input = {
					id: rid,
					mquestion: data.pquestion,
					mtimer: data.ptimer,
					mtest: arrayOfObjectsWithoutcheckbox,
					manswer: arrayOfObjects,
					mathstate:ref.current.value,
					image:selectedFile
				}
				await API.graphql({
					query: updateMultioptionsdesmoslist,
					variables: {input}
				});
				setUploadedmessage("Updated!")
			} catch (err) {
				console.log(err)
			}
		}else{
			const thism = "Please check the correct ones. "
			setUploadedmessage(thism)
		}
	}

	function scp(){
		history.push(`/editroom/${id}`);
	}
	const toggleFunction = () => {
		if (isActive) {
			setShowques(true)
		} else {
			setShowques(false)
		}
		setIsActive(!isActive);
	};
	return (
		<>
			<br/>
			<div className="dragdroppage">
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={0}>
						<Grid item xs={2}>
					<p>Game name : &nbsp;</p>
						</Grid>
						<Grid item xs={10}>
							{dname}
						</Grid>
					</Grid>

			<Grid container spacing={0}>
				<Grid item xs={2}>
			Timer (Seconds) : &nbsp;
				</Grid>
				<Grid item xs={10}>
				<input
					name="ptimer"
					defaultValue={mtimer}
					ref={register({valueAsNumber: true, maxLength:3})}
					className="timers"
					type="number"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={2}>
			Question : &nbsp;
				</Grid>
				<Grid item xs={10}>
				<textarea
					name="pquestion"
					defaultValue={mques}
					ref={register({ required: true, maxLength:200 })}
					className="quesmathinput"
				/>
				</Grid>
			</Grid>
					<br/>
					<Grid container spacing={0}>
						<Grid item xs={2} >
							<p>Update math formulas: &nbsp; &nbsp; &nbsp;</p>
						</Grid>
						<Grid item xs={10} className="leftside">
							<div className="pxsize500">
								<math-field ref={ref} onChange={handleMathInputChange} className="thismathfield"/>
							</div>
						</Grid>
					</Grid>
					<br/>
					<Grid container spacing={0}>
						<Grid item xs={2}>
							<p>Upload image:(option) </p>
						</Grid>
						<Grid item xs={10} className="leftside">
							<input type="file" onChange={handleFileChange}/>
						</Grid>
					</Grid>
					<div className="mathcccenter">
						<img src={selectedFile} className="pximages400"/>
					</div>
			<br/>
			<br/>
			<div className="centerside">
				<h4>Options:</h4>
			</div>
				{options.map((option, index) => (
					<div key={index} className="centerside">
						<Grid container spacing={0}>
							<Grid item xs={10}>
						<label>
							<Grid container spacing={0}>
								<Grid item xs={2} className="rightside">
							<input
								type="checkbox"
								checked={option.checkans}
								onChange={() => handleOptionChange(index)}
								className="multicheckbox"
							/>
								</Grid>
								<Grid item xs={5}>
									<p>{option.panswer}</p>
								</Grid>
								<Grid item xs={5}>
							<img src={option.mathstate} className="borderthis" alt="graphics"/>
								</Grid>
							</Grid>
						</label>
							</Grid>
							<Grid item xs={2} className="leftside">
								<img src={DeleteIcon} onClick={() => handleDeleteOption(index)} alt="delete"/>
							</Grid>
						</Grid>
						<hr/>
					</div>
				))}
			<hr/>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={11} className="rightside">
					<span className="redthis"> {uploadedmessage} </span> <input type="submit" value="Update" id="summitb" />
				</Grid>
				<Grid item xs={1}>
				</Grid>
			</Grid>
		</form>
				<div>
					<h4>Add Options:</h4>
					<input
						type="text"
						placeholder="Text required"
						value={newOption}
						onChange={(e) => setNewOption(e.target.value)}
						className="quesmath"
					/>
					<br/>
					<br/>
					<DesmosCalculator ref={addRef} />
					<br/>
					<Grid container spacing={0}>
						<Grid item xs={1} className="rightside">
						</Grid>
						<Grid item xs={11}>
					<div className="upgradenew3" id="3">
					<img src={Add} onClick={handleAddOption} width="40px"/>
						<span className="tooltiptext3">Click here to add options.</span>
					</div>
						</Grid>
					</Grid>
				</div>
			</div>
			<br/>
			<br/>
			<br/>
			</>
	);
}
export default Multidesmosedit;
