import {API, Auth} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {getPlayslide} from "../../graphql/queries";
import { useHotkeys } from 'react-hotkeys-hook';
import {useHistory,useParams} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Playb from '../../img/host/play-btn.png';
import Pauseb from '../../img/host/pause-btn.png';
import "../css/preview.css";
import Right from "../../img/host/next-btn.png";
import Left from "../../img/host/back-btn.png";
import Full from "../../img/host/Layout.png";
import {Helmet} from "react-helmet";
import Timeron from "../../img/host/watch-btn.png";
import Ending from "../../img/host/Exit-Button.png";
import Realtimewhiteboard from "../../img/host/whiteboard.png";
import Convozelogin from "../convoze/convozelogin";


function Convozerehearse() {
    const history = useHistory();
    const [urls, setUrls] = useState([]);
    const [count, setCount] = useState(0);
    const [isUser, setIsUser] = useState(false);
    const [isShowfulls, setIsShowfulls] = useState(true);
    const [isOpen, setIsOpen] = useState(true);
    const { id } = useParams();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        async function fetchData() {
            const response = await API.graphql({
                query: getPlayslide,
                variables: { id },

            });
            const datas = response.data.getPlayslide;
            const h = datas.playlistlinks;
            let i = h.length - 1;
            if (count <= i) {
                setUrls(h[count].rurl)
            }else {
                setCount(0)
            }
        }
        fetchData();
    }, [count,id]);

    useHotkeys('n',()=>{
        setCount(counts => counts + 1);
    })
    useHotkeys('b',()=>{
        setCount(counts => counts - 1);
    })

    function handlefullscreen(){
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }
        if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        }
    }
    function handleminiscreen(){
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }

    function ends(){
        history.push(`/convozeeditroom/${id}`);
    }


    return (
        isUser ?
        <>
            <Helmet>
                <title>REHEARSE</title>
            </Helmet>
        <div>
                    {urls !== "" && (
                        <iframe
                            className="preiframe"
                            id="theFrame"
                            src={urls}
                            width="100%"
                            height="100vh"
                            title="maincontent"
                            frameBorder="0"
                            allow="fullscreen"
                        />
                    )}
                <Grid container spacing={0} className="bottoms">
                    {isShowfulls ?
                        <Grid item xs={4} className="rightside">
                            <div className="upgradenew3">
                                <img src={Full} onClick={()=>{handlefullscreen();setIsShowfulls(false)}} className="mimg" alt="full screen"/>
                                <span className="tooltiptext3">Screen Layout (or / key)</span>
                            </div>
                        </Grid>
                        :
                        <Grid item xs={4} className="rightside">
                            <div className="upgradenew3">
                                <img src={Full} onClick={() => {handleminiscreen();setIsShowfulls(true)}} className="mimg" alt="small screen"/>
                                <span className="tooltiptext3">Screen Layout (or / key)</span>
                            </div>
                        </Grid>
                    }

                    <Grid item xs={6} className="leftside">
                        {isOpen ?
                            <>

                                    <img src={Timeron} className="convozemimg" alt="timer"/>
                                    <img src={Realtimewhiteboard} className="convozemimg" alt="Whiteboard"/>

                                        <img src={Playb} className="convozemimg" alt="play"/>
                                <div className="upgradenew4" id="5">
                                    <img src={Left} className="mimg" onClick={() => {
                                        setCount(count - 1);
                                        if (count === 0) {
                                            setCount(0)
                                        }
                                    }} alt="left"/>
                                    <span className="tooltiptext4">Back (or b key)</span>
                                </div>
                                <div className="upgradenew4" id="6">
                                    <img src={Right} onClick={() => setCount(count + 1)} className="mimg" alt="right" />
                                    <span className="tooltiptext4">Next (or n key)</span>
                                </div>
                            </> : ""
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <div className="endmeetingb">
                            <img src={Ending} onClick={ends} />
                        </div>
                    </Grid>
                </Grid>
            </div>
            </>
            :<Convozelogin />
    );
}
export default Convozerehearse;
