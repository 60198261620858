import React, {useEffect, useState} from 'react'
import CategoryItem from './categoryItem'
import {API, Auth} from "aws-amplify";
import {getPlayslide} from "../../../graphql/queries";
import { v4 as uuid } from 'uuid';
import {useHistory, useParams} from "react-router-dom";
import {createDraganddrop, updatePlayslide} from "../../../graphql/mutations";
import {Button} from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import {Helmet} from "react-helmet";
import Navbars from "../../Navbar";
import Foot from "../../Foot";
import DeleteIcon from '../../../img/dash/remove.png';
import Leftarrow from "../../../img/left-arrow.png";


const placeholderCategories = [
  {
    name: '',
    items: [
      { text: '', image: ""}
    ]
  },
  {
    name: '',
    items: [
      { text: '', image: "" }
    ]
  }
]


export default function Categorydragdrop () {
  const [error, setError] = useState();
  const { id } = useParams();
  const history = useHistory();
  const [isUser, setIsUser] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [images, setImages] = useState([]);
  const [dname, setDname] = useState('')
  const [dtimer, setDtimer] = useState('')
  const [dquestion, setDquestion] = useState('')
  const d = uuid();
  useEffect( () => {
    Auth.currentAuthenticatedUser()
        .then(user => (setIsUser(user.attributes.email)))
        .catch(err => console.log(err));
    (async() => {
      const response = await API.graphql({
        query: getPlayslide,
        variables:{id}
      })
      const datas = response.data.getPlayslide;
      const iowner = datas.owner;
      setIsOwner(iowner);
      const images = datas.playlistlinks;
      if(images != null) {
        setImages(images);
      }
    })()
  }, []);
  const [categories, setCategories] = useState(() => {
    const storedCategories = localStorage.getItem('categories')
    if (storedCategories == null) {
      return placeholderCategories
    }
    return JSON.parse(storedCategories)
  })
  function changedname(event){
    setDname(event.target.value)
  }
  function changedtimer(event){
    setDtimer(event.target.value)
  }
  function changedquestion(event){
    setDquestion(event.target.value)
  }
  const categoryViews = categories.map((category, categoryIndex) => {
    function handleNameChange (event) {
      setCategories(categories.map((category, i) => {
        if (i === categoryIndex) {
          return {
            ...category,
            name: event.target.value
          }
        }
        return category
      }))
    }
    const itemViews = category.items.map((item, itemIndex) => {
      return (
        <CategoryItem
          key={itemIndex}
          item={item}
          categoryIndex={categoryIndex}
          itemIndex={itemIndex}
          categories={categories}
          setCategories={setCategories}
        />
      )
    })
    function handleAddItem () {
      setCategories(categories.map((category, i) => {
        if (i === categoryIndex) {
          return {
            ...category,
            items: [...category.items, { text: '', image: '' }]
          }
        }
        return category
      }))
    }
    function handleDeleteCategory () {
      setCategories(currentCategories => {
        return currentCategories.filter((currentCategory, currentCategoryIndex) => {
          if (currentCategoryIndex !== categoryIndex) return true
          return false
        })
      })
    }
    return (
      <div key={categoryIndex} className="itemswidth">
        <Grid container spacing={0}>
          <Grid item xs={9}>
        <input placeholder='Category name' value={category.name} onChange={handleNameChange} className="ques"/>
          </Grid>
          <Grid item xs={3} className="rightside">
            <img src={DeleteIcon} onClick={handleDeleteCategory} />
          </Grid>
        </Grid>
        <br/>
        <ol>{itemViews}</ol>
        <br/>
        <button onClick={handleAddItem}>Add Item</button>
      </div>
    )
  })
  function handlePlusCategory () {
    const category = {
      name: '',
      items: [{ text: '', image: '' }]
    }
    setCategories([...categories, category])
  }
  async function handleSave () {
    const invalidItem = categories.some(category => category.items.some(item => item.text == null || item.text === ''))
    if (invalidItem) {
      setError('Item text is required')
      return
    }
    const invalidCategory = categories.some(category => category.name == null || category.name === '')
    if (invalidCategory) {
      setError('Category name is required')
      return
    }
    setError(null)
    const reorderurl = `https://demo.reachum.com/showdragdrop/${id}/${d}`
    const erurl = `https://demo.reachum.com/categorytaptapedit/${id}/${d}`
    const ccurl = `https://demo.reachum.com/correcttaptap/${id}/${d}`
    const ileaderboardurl = `https://demo.reachum.com/leaderboard/${id}`;
    const tt = `${d}1`;
    const ccc = `${d}333`;
    const cce = categories;
    try {
      const input = {
        id: d,
        dname: dname,
        dtimer: dtimer,
        dquestion: dquestion,
        categories: cce
      }
      await API.graphql({
        query: createDraganddrop,
        variables: {input}
      });
      const urldata = {
        rid: d,
        title: dname,
        rurl: reorderurl,
        eurl:erurl
      };
      const correcta = {
        rid:ccc,
        title:"Correct answer",
        rurl:ccurl
      }
      const leaderboard = {
        rid:tt,
        title:"Viewers' leaderboard",
        rurl:ileaderboardurl
      }
      const allImages = [...images, urldata,correcta,leaderboard]
      const inputs = {
        id,
        playlistlinks: allImages
      }
     await API.graphql({
        query: updatePlayslide,
        variables: {input: inputs}
      })
      setTimeout(() => {
        history.push(`/editroom/${id}`);
      }, 500)
    } catch (err) {
      console.log(err)
    }
  }
  const errorView = error != null && (
    <div style={{ background: 'red', color: 'white'}}>
      {error}
    </div>
  )
  function scp(){
    history.push(`/editroom/${id}`);
  }
  return (
      isOwner === isUser ?
          <>
            <Helmet>
              <title>Tap tap</title>
            </Helmet>
            <Navbars />
            <div className="dragdroppage">
              <br/>
              <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
              <br/>
              <div className="centerthis">
                <h3>Tap tap</h3>
              </div>
              <br/>
    <div style={{  width: '100%' }}>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
        Game Name: &nbsp;
        </Grid>
        <Grid item xs={10}>
          <input type="text" value={dname} onChange={changedname} maxLength="100" />
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
         Timer (Seconds): &nbsp;
        </Grid>
        <Grid item xs={10}>
    <input
        value={dtimer}
        type="number"
        onChange={changedtimer}
        className="dragdroptimer"
        maxLength="3"
    />
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
       Question: &nbsp;
        </Grid>
        <Grid item xs={10}>
    <textarea type="text" value={dquestion} onChange={changedquestion} className="dragdropques" maxLength="150" />
        </Grid>
      </Grid>
      <br/>
      <div style={{ display: 'flex', width:"100%", flexWrap: 'wrap' }}>
        {categoryViews}
        <Button style={{ height: 'fit-content'}} onClick={handlePlusCategory} variant="success" size="xs">Add Category</Button>
      </div>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={10}>
      {errorView}
        </Grid>
        <Grid item xs={2}>
      <Button onClick={handleSave} variant="success">Submit</Button>
        </Grid>
      </Grid>
    </div>
            </div>
            <Foot />
          </>
          :""

  )
}
