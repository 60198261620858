import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {listHosts} from '../../graphql/queries';
import "../css/leaderboard.css";
import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Pmorereports from "./leaderboard/pmorereports";
import Pmorereponsereports from "./responserate/pmorereponsereports";
import {Button, Modal} from 'react-bootstrap';


function ShareReports() {
    const [showthis, setShowthis] = useState(true);
    const [selectedOption, setSelectedOption] = useState('');
    const [opentimetracking ,setOpentimetracking] = useState(false);
    const [openleader ,setOpenleader] = useState(false);
    const [openrespnserate, setOpenrespnserate] = useState(false);
    const [val, setVal] = useState("")
    const [openreport, setOpenreport] = useState(true);
    const [thisisowner, setThisisowner] = useState("");
    const [ message, setMessage] = useState("");
    useEffect(() => {
        (async() => {
                const query = new URLSearchParams(window.location.search);
                const q = query.get("p");
                if(q) {
                    const response = await API.graphql({
                        query: listHosts,
                        variables: {
                            filter: {
                                "refer": {
                                    eq: q
                                }
                            },
                            limit: 10000
                        }
                    })
                    const datas = response.data.listHosts.items;
                    if (datas.length !== 0) {
                        const tt = datas[0].usage;
                        const timestamp2 = Math.floor(new Date().getTime() / 1000);
                        const differenceInTime = tt - timestamp2;
                        if (differenceInTime <= 0) {
                            setOpenreport(false)
                        } else {
                            const thisowner = datas[0].owner;
                            setThisisowner(thisowner);
                        }
                    } else {
                        setOpenreport(false)
                    }
                }else{
                    setOpenreport(false)
                }
        })()
    }, []);

    const handleOptionChange = (event) => {
        const cc = event.target.value;
        if(cc === "Response Rate"){
            setOpentimetracking(false);
            setOpenleader(false)
            setOpenrespnserate(true);
            setSelectedOption("Response Rate")
        }else{
            setOpentimetracking(false);
            setOpenrespnserate(false);
            setOpenleader(true);
            setSelectedOption("LessonLog")
        }
    };
    const handleChange = e => {
        const tt = e.target.value
        setVal(tt);
    }
     async function submitthis(){
        if(thisisowner.toLowerCase() === val.toLowerCase()){
            setThisisowner(val);
            setShowthis(false);
        }else{
            setMessage("Please enter correct Email")
        }
    }
    return (
        <>
            <Helmet>
                <title>ShareReports</title>
            </Helmet>
                    {openreport ?
                        <>
                            <div className="leaderpage">
                                <div className="cccenter">
                                    <br/>
                                    <h4> Report By Date </h4>
                                    <br/>
                                    <h5>Choose a report type:</h5>
                                    <br/>
                                    <Grid container spacing={0} className="topspace1">
                                        <Grid item xs={6} className="cccenter">
                                            <input
                                                type="radio"
                                                name="options"
                                                value="Leaderboard"
                                                checked={selectedOption === 'LessonLog'}
                                                onChange={handleOptionChange}
                                                className="newinviteradio"
                                            />
                                            <label className="newinviteradiolabel">
                                                Lesson Log
                                            </label>
                                        </Grid>
                                        <Grid item xs={6} className="cccenter">
                                            <input
                                                type="radio"
                                                name="options"
                                                value="Response Rate"
                                                checked={selectedOption === "Response Rate"}
                                                onChange={handleOptionChange}
                                                className="newinviteradio"
                                            />
                                            <label className="newinviteradiolabel">
                                                Response Rate
                                            </label>
                                        </Grid>
                                    </Grid>
                                    <hr/>
                                    <br/>
                                    {openleader ?
                                        <>
                                        <Pmorereports data={thisisowner}/>
                                        </>
                                        : ""
                                    }
                                    {openrespnserate ?
                                        <>
                                        <Pmorereponsereports data={thisisowner}/>
                                        </>
                                        : ""
                                    }
                                </div>
                            </div>
                            <Modal show={showthis} size="lg" centered>
                                <Modal.Body>
                                    <div className="enteryournameform">
                                        <div className="cccenter">
                                            <h3>Welcome!</h3>
                                            <br/>
                                            <p>Enter the email of the user who shared the link with you below.</p>
                                            <input placeholder="Enter the Email" onChange={handleChange}/>
                                            <p className="redthis">{message}</p>
                                            <br/>
                                            <Button onClick={submitthis} className="copys"> View Reports</Button>
                                            <br/>
                                            <br/>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </>
                        :
                        <div className="cccenter">
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <h1 className="grays">This link is expired!</h1>
                        </div>
                    }

        </>
    )
}

export default ShareReports;
