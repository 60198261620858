import { API } from 'aws-amplify';
import { createGiphyGif } from '../../graphql/mutations';
import { getGiphyGif } from '../../graphql/queries';

export async function createGif({ id, title, url }) {
  const createGif = { id, title, url };
  const { data } = await API.graphql({
    query: createGiphyGif,
    variables: { input: createGif }
  });
  const { createGiphyGif: createdGif } = data;
  return createdGif;
}

export async function getGif({ id }) {
  const { data } = await API.graphql({
    query: getGiphyGif,
    variables: { id }
  });
  const { getGiphyGif: gif } = data;
  return gif;
}
