import React, { useState, useRef, useEffect } from 'react';
import {API, Auth} from 'aws-amplify';
import {useParams,useHistory} from "react-router-dom";
import DesmosCalculator from "./desmosCalculator";
import {
    updateDesmomathslide,
} from "../../graphql/mutations";
import Resizer from "react-image-file-resizer";
import {getDesmomathslide} from "../../graphql/queries";
import {v4 as uuid} from "uuid";
import MathJax from "react-mathjax";
import Mathfield from "./Mathfield";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            960,
            540,
            "JPEG",
            85,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
const resizeFile1 = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            400,
            400,
            "JPEG",
            85,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });
const  Editmathslide = ()  =>{
    const ref = useRef(null);
    const { id,rid } = useParams();
    const addRef = useRef(null);
    const [thisimage, setThisimage] = useState('');
    const [uploadedmessage, setUploadedmessage] = useState("");
    useEffect( () => {
        (async() => {
            const response = await API.graphql({
                query: getDesmomathslide,
                variables:{id:rid}
            })
            const datas = response.data.getDesmomathslide;
            const ma = datas.mathstate;
            ref.current.value = ma;
            const mimage = datas.image;
            if(mimage !== null){
                setThisimage(mimage)
            }
        })()
    }, []);
    useEffect(() => {
        function handleInput () {
            const prompts = ref.current.getPrompts()
            const numerator = ref.current.getPromptValue('numerator')
        }
        if (ref.current != null) {
            ref.current.addEventListener('input', handleInput)
            return () => {
                if (ref.current != null) {
                    ref.current.removeEventListener('input', handleInput)
                }
            }
        }
    }, [ref.current])
    function handleMathInputChange (value) {
        ref.current.value = value
    }
    const handleFileChange = async (event) => {
        const img = event.target.files[0];
        const resized = await resizeFile1(img);
        setThisimage(resized)
    };

    async function savethis(){
        const calculatorState = addRef.current.getState()
        const latexExpressions = calculatorState.expressions.list.filter(expression => {
            return expression.latex != null
        })
        let resized;
        if (latexExpressions.length === 0) {
            resized = "";
        } else {
            const screenshot = addRef.current.screenshot()
            const response = await fetch(screenshot)
            const blob = await response.blob()
            resized = await resizeFile(blob)
        }
        try {
            const input = {
                id:rid,
                mathstate:ref.current.value,
                graphic:resized,
                image:thisimage
            }
            await API.graphql({
                query: updateDesmomathslide,
                variables: {input}
            });
            setUploadedmessage(" Updated. ")
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <>
            <div className="mathslidepage">
                <br/>
                <Grid container spacing={0}>
                    <Grid item xs={3} className="rightside">
                        <p>Update image: &nbsp; &nbsp; &nbsp;</p>
                    </Grid>
                    <Grid item xs={9} className="leftside">
                        <input type="file" onChange={handleFileChange} />
                    </Grid>
                </Grid>
                <br/>
                <div className="mathcccenter">
                    <img src={thisimage} className="pximages400" />
                </div>
                <br/>
                <Grid container spacing={0}>
                    <Grid item xs={3} className="rightside">
                        <p>Update math formulas: &nbsp; &nbsp; &nbsp;</p>
                    </Grid>
                    <Grid item xs={9} className="leftside">
                        <div className="pxsize500">
                            <math-field ref={ref} onChange={handleMathInputChange} className="thismathfield"/>
                        </div>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <p>Graphing Calculator: </p>
                <DesmosCalculator ref={addRef} />
                <br/>
                <Grid container spacing={0}>
                    <Grid item xs={11} className="rightside">
                        {uploadedmessage} <Button onClick={savethis}>Update</Button>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <br/>
            </div>
        </>
    );
}

export default Editmathslide;
