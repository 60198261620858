import React, {useEffect, useState, useRef} from 'react'
import {API} from "aws-amplify";
import {getSequencingdesmos} from "../../../../graphql/queries";
import { v4 as uuid } from 'uuid';
import {useParams} from "react-router-dom";
import {updateSequencingdesmos} from "../../../../graphql/mutations";
import {Button} from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from '../../../../img/dash/Delete-Button-X.png';
import Moveup from "../../../../img/reorder/scroll-up.png";
import Movedown from "../../../../img/reorder/scroll-down.png";
import DesmosExporter from "./DesmosExporter";
import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
          file,
          400,
          400,
          "JPEG",
          95,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
      );
    });

export default function Desmossequencingedit() {
  const { id,rid } = useParams();
  const ref = useRef(null);
  const [dname, setDname] = useState('')
  const [dtimer, setDtimer] = useState('')
  const [dquestion, setDquestion] = useState('')
  const [message, setMessage] = useState('Once updated, you cannot make changes.');
  const [selectedFile, setSelectedFile] = useState("");
  const [mathInput, setMathInput] = useState('')
  const d = uuid();
  useEffect( () => {
    (async() => {
      const response = await API.graphql({
        query: getSequencingdesmos,
        variables:{id:rid}
      })
      const datas = response.data.getSequencingdesmos;
      const dns = datas.dname;
      setDname(dns)
      const t = datas.dtimer
      setDtimer(t)
      const q = datas.dquestion
      setDquestion(q);
      const fanswesr = datas.qmathstate;
      ref.current.value = fanswesr;
      const mimage = datas.image;
      if(mimage !== null){
        setSelectedFile(mimage)
      }
    })()
  }, []);
  const [items, setItems] = useState(() => {
    const stored = localStorage.getItem('desmoreorder')
    if (stored == null) {
      return [
        { id: uuid(), text: '' }
      ]
    }
    const parsed = JSON.parse(stored)
    return parsed
  })
  function changedtimer(event){
    setDtimer(event.target.value)
  }
  function changedquestion(event){
    setDquestion(event.target.value)
  }
  const [resizers, setResizers] = useState({})
  useEffect(() => {
    const elt = document.getElementById('calculator');
    const calculator = window.Desmos.GraphingCalculator(elt);
    calculator.observeEvent('change', function(eventName, event) {
      calculator.screenshot();
      calculator.getState();
      calculator.asyncScreenshot(
          {
            mode: 'preserveX',
            width: 800,
            height: 400
          },
          (data) => console.log(data)
      );
    })
  }, []);
  function move(items, item, afterIndex, targetIndex) {
    const currentIndex = items.findIndex(currentItem => currentItem.id === item.id)
    if (currentIndex === targetIndex) {
      return items
    }
    const before = items.slice(0, afterIndex)
    const beforeFiltered = before.filter(beforeItem => beforeItem.id !== item.id)
    const after = items.slice(afterIndex)
    const afterFiltered = after.filter(afterItem => afterItem.id !== item.id)
    const newItems = [...beforeFiltered, item, ...afterFiltered]
    return newItems
  }
  const itemViews = items.map((item, itemIndex) => {
    function handleDelete () {
      let text = "Are you sure you want to delete this item? \nEither OK or Cancel.";
      if (window.confirm(text) === true) {
        setItems(currentItems => {
          const newItems = currentItems.filter(currentItem => {
            return currentItem.id !== item.id
          })
          return newItems
        })
      }
    }
    function handleMoveUp() {
      setItems(currentItems => {
        const newIndex = Math.max(0, itemIndex - 1)
        return move(currentItems, item, newIndex, newIndex)
      })
    }
    function handleMoveDown() {
      setItems(currentItems => {
        const newIndex = Math.min(currentItems.length, itemIndex + 2)
        return move(currentItems, item, newIndex, newIndex -1)
      })
    }
    function handleItemChange(key, value) {
      setItems(currentItems => {
        const newItems = currentItems.map(currentItem => {
          const match = currentItem.id === item.id
          if (match) {
            return { ...currentItem, [key]: value }
          }
          return currentItem
        })
        return newItems
      })
    }
    function handleItemTextChange(event) {
      handleItemChange('text', event.target.value)
    }
    function handleItemImageChange(image) {
      handleItemChange('image', image)
    }
    async function handleFileChange(e) {
      if (e.target.files && e.target.files.length > 0) {
        const image = await resizeFile(e.target.files[0]);
        handleItemImageChange(image)
      }
    }
    function initializeResizer (resizer, ref) {
      setResizers(current => {
        return {
          ...current, [item.id]: { id: item.id, resizer, ref }
        }
      })
    }
    const resizer = resizers[item.id];
    function setDesmosState(itemId, newState) {
      setResizers(current => {
        const currentResizer = current[itemId]
        const newResizer = { ...currentResizer, state: newState }
        return {
          ...current,
          [itemId]: newResizer
        }
      })
    }
    return (
        <li key={itemIndex}>
          <Grid container spacing={0} className="topspace">
            <Grid item xs={8} className="cccenter">
              <input value={item.text} onChange={handleItemTextChange} placeholder='Item text'  className="sefullwidth"/>
            </Grid>
            <Grid item xs={1} className="centerthis">
              <img src={Moveup} onClick={handleMoveUp} width="30px" />
            </Grid>
            <Grid item xs={1}>
              <img src={Movedown} onClick={handleMoveDown} width="30px" />
            </Grid>
            <Grid item xs={1} className="cccenter">
            </Grid>
            <Grid item xs={1}>
              <img src={DeleteIcon} onClick={handleDelete} width="30px"/>
            </Grid>
          </Grid>

          <DesmosExporter
              initialize={initializeResizer}
              desmosState={resizer?.state}
              setDesmosState={setDesmosState}
              itemId={item.id}
          />
          <br/>
        </li>
    )
  })
  useEffect(() => {
    function handleInput () {
      const prompts = ref.current.getPrompts()
      const numerator = ref.current.getPromptValue('numerator')
    }
    if (ref.current != null) {
      ref.current.addEventListener('input', handleInput)
      return () => {
        if (ref.current != null) {
          ref.current.removeEventListener('input', handleInput)
        }
      }
    }
  }, [ref.current]);

  function handleMathInputChange (value) {
    setMathInput(value);
  }
  const handleFileChange = async (event) => {
    const img = event.target.files[0];
    const resized = await resizeFile(img);
    setSelectedFile(resized)
  };
  function handleAdd() {
    setItems(currentItems => {
      const newItem = {
        id: uuid(),
        text: ''
      }
      return [...currentItems, newItem]
    })
  }
  async function handleSave() {
    const itemdata = []
    for (const item of items) {
      const resizer = resizers[item.id]
      const image = await resizer.resizer()
      itemdata.push({...item, mathstate: image })
    }
    try {
      const input = {
        id: rid,
        dtimer: dtimer,
        dquestion: dquestion,
        orders:itemdata,
        mathstate:ref.current.value,
        image:selectedFile
      }
      await API.graphql({
        query: updateSequencingdesmos,
        variables: {input}
      });
      setMessage("Updated")
    } catch (err) {
      console.log(err)
    }
  }
  return (
          <>
            <div className="dragdroppage">
              <br/>
    <div style={{  width: '100%' }}>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
        Game Name: &nbsp;
        </Grid>
        <Grid item xs={10}>
          {dname}
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
         Timer (Seconds): &nbsp;
        </Grid>
        <Grid item xs={10}>
    <input
        value={dtimer}
        type="number"
        onChange={changedtimer}
        className="dragdroptimer"
        maxLength="3"
    />
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={2}>
       Question: &nbsp;
        </Grid>
        <Grid item xs={10}>
    <textarea type="text" value={dquestion} onChange={changedquestion} className="dragdropques" maxLength="150" />
        </Grid>
      </Grid>
      <br/>
      <div>
        <br/>
        <Grid container spacing={0}>
          <Grid item xs={2} >
            <p>Update math formulas: &nbsp; &nbsp; &nbsp;</p>
          </Grid>
          <Grid item xs={10} className="leftside">
            <div className="pxsize500">
              <math-field ref={ref} onChange={handleMathInputChange} className="thismathfield"/>
            </div>
          </Grid>
        </Grid>
        <br/>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <p>Upload image:(option) </p>
          </Grid>
          <Grid item xs={10} className="leftside">
            <input type="file" onChange={handleFileChange}/>
          </Grid>
        </Grid>
        <div className="mathcccenter">
          <img src={selectedFile} className="pximages400"/>
        </div>
        <br/>
        <br/>
        <h5>Put items in the correct sequence.</h5>
        <br/>
        <ol>{itemViews}</ol>
        <Button onClick={handleAdd} size="sm" variant="secondary">Add item</Button>
      </div>
      <br/>
      <hr/>
      <br/>
      <Grid container spacing={0}>
        <Grid item xs={7}>
        </Grid>
        <Grid item xs={4} className="rightside">
      <span className="redthis"> {message} &nbsp;</span><Button onClick={handleSave} variant="success">Update</Button>
        </Grid>
        <Grid item xs={1}>
        </Grid>
      </Grid>
    </div>
            </div>
            <br/>
            <br/>
            <br/>
            </>
  )
}
