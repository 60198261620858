import React, {useEffect, useState} from "react";
import {API, Auth, Storage} from 'aws-amplify';
import {useParams,useHistory} from "react-router-dom";
import { getPlayslide } from "../../../graphql/queries";
import {Button} from 'react-bootstrap';
import Navbars from "../../Navbar";
import Foot from "../../Foot";
import "../../css/uploadimg.css";
import {Helmet} from "react-helmet";
import Leftarrow from "../../../img/left-arrow.png";

function Videoquizfile() {
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);

    function scp(){
        history.push(`/editroom/${id}`);
    }
    function goupload(){
        history.push(`/uploadvideoques/${id}`);
    }
    function gorecord(){
        history.push(`/videorecord/${id}`);
    }
    return (
        isOwner === isUser ?
            <>
                <Helmet>
                    <title>Video Quiz</title>
                </Helmet>
                <Navbars />
                <div className="uploadpptx">
                    <h5 className="sharedropbtn" onClick={scp}><img src={Leftarrow} width="13" height="13" /> Content Manager </h5>
                    <br/>
                    <br/>
                    <div className="text-center">
                        <div className="thiscenter">
                            <h4>One question for each video</h4>
                        </div>
                    <Button className="mr-4" onClick={goupload}>Upload a video file</Button>
                    <Button className="mr-4" onClick={gorecord}>Record a video</Button>
                </div>
                </div>
                <Foot />
            </>
            :""
    );
}

export default Videoquizfile;
