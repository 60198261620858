import React, {useEffect, useState} from "react";
import {
    useParams,
    useHistory
} from "react-router-dom";
import "./videorecordpager.css";
import { useReactMediaRecorder } from 'react-media-recorder';
import {API, Auth, Storage} from "aws-amplify";
import {getPlayslide} from "../../graphql/queries";
import {Helmet} from "react-helmet";
import Navbars from "../Navbar";
import Goback from "../../img/dash/Go-Back-Button.png";
import {v4 as uuid} from "uuid";
import awsExports from "../../aws-exports";
import {updatePlayslide} from "../../graphql/mutations";
import {Button} from 'react-bootstrap';
import Foot from "../Foot";
let vbucket = awsExports.aws_user_files_s3_bucket;
let vregion = awsExports.aws_user_files_s3_bucket_region;
const Ideorecordr = () => {
    const {
        startRecording,
        mediaBlobUrl,
        status,
    } = useReactMediaRecorder({
        screen: true,
        audio: true, // Enable audio recording
    });
        const [isUser, setIsUser] = useState(false);
        const [isOwner, setIsOwner] = useState(false);
        const [images, setImages] = useState([]);
        const [processing, setProcessing] = useState("");
        const history = useHistory();
        const { id } = useParams();
        const tl = uuid();
        useEffect( () => {
            Auth.currentAuthenticatedUser()
                .then(user => (setIsUser(user.attributes.email)))
                .catch(err => console.log(err));
            (async() => {
                const response = await API.graphql({
                    query: getPlayslide,
                    variables:{id},

                })
                const datas = response.data.getPlayslide;
                const iowner = datas.owner;
                setIsOwner(iowner);
                const images = datas.playlistlinks;
                if(images != null) {
                    setImages(images);
                }
            })()
        }, []);
        const  addImageTopDB = async (input) => {
            try {
                await API.graphql({
                    query: updatePlayslide,
                    variables: {input},

                });
            } catch (error) {
                console.log(error)
            }
        }

        const upload = async () => {
            const nfile = `videos/${tl}.webm`;
            const blob = await fetch(mediaBlobUrl).then((r) => r.blob());
            await Storage.put(nfile, blob, {
                contentType: "video/webm",
                progressCallback(progress) {
                    const lods = progress.loaded;
                    const done = progress.total;
                    const loadin = Math.floor((lods / done) * 100);
                    const per = loadin + "%";
                    setProcessing(per)
                    if (loadin === 100) {
                        setProcessing("Uploading...")
                    }
                },
            }).then(() => {
                const ryrl = `https://demo.reachum.com/vshowroom/${id}/${tl}`;
                const eryrl = `https://demo.reachum.com/ideoedit/${id}/${tl}`;
                const vvv = {
                    title: "record screen",
                    file: {
                        bucket: vbucket,
                        region: vregion,
                        key: nfile
                    },
                    rid: tl,
                    rurl: ryrl,
                    eurl: eryrl
                }
                const allImages = [...images, vvv]
                const input = {
                    id,
                    playlistlinks: allImages
                }
                addImageTopDB(input);
                setTimeout(() => {
                    setProcessing("The file added to the content list.");
                    history.push(`/editroom/${id}`);
                }, 1000)
            }).catch(err => console.log(err));
        }
        function gotorecord(){
            history.push(`/videorecord/${id}`);
        }
        function scp(){
            history.push(`/editroom/${id}`);
        }
    return (
        <div>
            <h2>Screen and Audio Recorder</h2>
            <div>
                {/* Display recorded media */}
                {mediaBlobUrl && (
                    <video
                        src={mediaBlobUrl}
                        controls
                        autoPlay
                        loop
                        style={{width: '640px', height: '360px'}}
                    />
                )}
            </div>
            <p>{processing}</p>
            <button onClick={startRecording}>Start Recording</button>
            <Button onClick={upload} className="mr-5">Use it</Button>
        </div>
    );
};

export default Ideorecordr
