import React, {useEffect, useState} from "react";
import { API} from 'aws-amplify';
import {getTextslide} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import "../css/slide.css";


function Textshowroom() {
    const [clist, setClist] = useState([]);
    const [qslidename, setQslidename] = useState("");
    const { id, rid } = useParams();
    useEffect(() => {
        fetchDas();
    }, []);
    async function fetchDas() {
        try {
            const response = await API.graphql({
                query: getTextslide,
                variables: {id:rid}
            })
            const datas = response.data.getTextslide;
            const ques = datas.pfname;
            setQslidename(ques);
            const tests = datas.ptest;
            if(tests !== null) {
                setClist(tests);
            }
            const thisc = datas.titlecolor;
            document.getElementById("thiscolor").style.color = thisc;
            const thislistc = datas.listcolor;
            const allWithClass = Array.from(
                document.querySelectorAll('.textlists')
            );
            allWithClass.forEach(element => {
                element.style.color = thislistc;
            });
            const thisbgcolor = datas.bgcolor;
            document.getElementById("textslidesshowpage").style.backgroundColor = thisbgcolor;
        }catch(err){
            console.log(err)
        }
    }
    return (
        <>
            <div className="textslidesshowpage" id="textslidesshowpage">
                <div className="vircalcenter">
                <div className="textslidesshowpageheader">
                <p id="thiscolor"> {qslidename} </p>
                </div>
                <div>
                    <ul className="alltextlists">
                        {
                            clist.map((item,  index) => {
                                return (
                                    <li key={item.id}>
                                        <p className="textlists">{item.plist}</p>
                                    </li>
                                )}

                            )
                        }

                    </ul>
                </div>
                </div>
            </div>
        </>
    )
}

export default Textshowroom;
