import React, {useEffect,useState} from "react";
import {API, Auth} from 'aws-amplify';
import {getPlayslide} from '../../../graphql/queries';
import {useParams} from "react-router-dom";
import Openpage from "../../../img/dash/openpage.png";

function Pptshowroom() {
    const [lvfile, setLvfile] = useState();
    const [itt, setItt] = useState("");
    const [isUser, setIsUser] = useState("");
    const { id, rid } = useParams();
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        fetchDatas();
    }, []);
    async function fetchDatas() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const datasn = datas.playlistlinks;
            const iowner = datas.owner;
            const pptfile = datas.qrstring;
            const newdatas = datasn.filter(data => data.rid === rid);
            const vbucket = newdatas[0].lurl;
            if(iowner === isUser){
                setLvfile(pptfile);
            }else {
                setLvfile(vbucket);
            }
            const tt = newdatas[0].title;
            setItt(tt)
        }catch(error){
            console.log("can't display")
        }
    }
    return (
        <>
            <iframe src={lvfile} className="iurlroom" allowFullScreen></iframe>
        </>
    );
}

export default Pptshowroom;
