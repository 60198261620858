import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {API, Auth} from "aws-amplify";
import Foot from "../components/Foot";
import {Form, Row, Col, Button} from 'react-bootstrap';
import Navbars from "../components/Navbar";
import "./css/profile.css";
import Login from "./login";
import {listHosts} from "../graphql/queries";
import {Helmet} from "react-helmet";
import { email } from 'vanilla-sharing';

const Profile = ()  => {
    const history = useHistory();
    const [user, setUser] = useState();
    const [isEmail, setIsemail] = useState();
    const [thisfname, setThisfname] = useState("");
    const [thislname, setThislname] = useState("");
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setUser(user);
                const username = user.attributes.email;
                const firstName = user.attributes.given_name;
                setThisfname(firstName);
                const lastName = user.attributes.family_name;
                setThislname(lastName)
                setIsemail(username);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleSignout = async () => {
        try {
            await Auth.signOut();
            history.replace("/");
            window.location.reload();
        } catch (err) {
            console.log('error signing out: ', err);
        }
    }
    async function deleteUser() {
        let text = "Are you sure you want to delete this account? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            try {
                await Auth.deleteUser();
                history.replace("/");
            } catch (error) {
                console.log('Error deleting user', error);
            }
        }
    }
    function sharethis(){
        email({
            url: "https://reachum.com"
        })
    }
    return(
        <>
            <Helmet>
                <title>MY ACCOUNT</title>
            </Helmet>
            <Navbars />
            {!user ?
                <Login />
                :(
                <div className="profiles">
                    <Form>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="4">
                                Your Name
                            </Form.Label>
                            <Col sm="8">
                                {thisfname} {thislname} <p user={user} variant="warning" size="sm" onClick={handleSignout}>Sign Out</p>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="4">
                                Email
                              <p onClick={sharethis}>Share</p>
                            </Form.Label>
                            <Col sm="8">
                                {isEmail}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="4">

                            </Form.Label>
                            <Col sm="8">

                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="5">
                            </Form.Label>
                            <Col sm="7">
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextPassword">
                            <Form.Label column sm="5">
                            </Form.Label>
                            <Col sm="7">
                                <Button user={user} variant="danger" size="sm" onClick={deleteUser}>Delete Account</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                    </div>
                )
            }
                    <Foot />
                </>

    )
}


export default Profile;
