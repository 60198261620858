import React from "react";
import classnames from "classnames";
import "./card.css";
import Thisback from "./flashcardback.jpg"

const Card = ({ onClick, card, index, isInactive, isFlipped, isDisabled }) => {
    const handleClick = () => {
        !isFlipped && !isDisabled && onClick(index);
    };
    const label = card.show === 'text' ? card.text : card.type
    return (
        <div
            className={classnames("card", {
                "is-flipped": isFlipped,
                "is-inactive": isInactive
            })}
            onClick={handleClick}
        >
            <div className="card-face card-font-face">
                <img src={Thisback} />
            </div>
            <div className="card-face card-back-face">
                <p className="flipcardp">{label}</p>
            </div>
        </div>
    );
};

export default Card;
