import React, {useEffect, useState} from "react";
import {API} from 'aws-amplify';
import {getPlayslide, listLeaderboards} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Table} from 'react-bootstrap';
import "../css/leaderboard.css";
import Correctimg from "../../img/view/check-mark.png";
import Incorrectimh from "../../img/view/close.png";
import Noanswer from "../../img/view/question-mark.png";
import Contents from "../../img/view/content-management.png"
import Accordion from 'react-bootstrap/Accordion';

function LessonLog() {
    const [myData, setMyData] = useState([]);
    const {id} = useParams();
    const [thislength, setThislength] = useState(0);
    const [roomname, setRoomname] = useState("");
    useEffect(() => {
        (async () => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const myroomname = datas.meetingname;
            setRoomname(myroomname);
        })()
        fetchDas();
    }, []);

    async function fetchDas() {
        const dcounts = [];
        const scounts = [];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "roomid": {
                            eq: id
                        }
                    },
                    limit: 10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            if (datas.length !== 0) {
                setThislength(datas.length)
                datas.forEach((data) => {
                    const listdata = data;
                    scounts.push(listdata)
                })
                scounts.sort((a, b) => {
                    return b.score - a.score;
                });
                scounts.forEach((e) => {
                    dcounts.push(e)
                });
                setMyData(dcounts)
                console.log("dcounts", datas)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const renderreports = () => {
        return myData.map((item, index) => (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.meetemail}</td>
                    <td>
                        {Math.floor(parseInt(item.duration) / 60)}
                    </td>
                    <td>
                        <Accordion>
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header>Activities</Accordion.Header>
                                <Accordion.Body>
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>Activity</th>
                                <th> Correct/ Incorrect Answer</th>
                                <th> Score</th>
                                <th>Timer In</th>
                                <th>Timer Out</th>
                                <th>Duration(Seconds)</th>
                            </tr></thead>
                            <tbody>
                            {item.questions.map((item, index) => {
                                const thisas = item.correctanswer;
                                let thisanswer;
             if(thisas === "correct"){
                 thisanswer = `${Correctimg}`
               }else if(thisas === "wrong"){
                 thisanswer = `${Incorrectimh}`
             }else if(thisas === "No answer"){
                 thisanswer = `${Noanswer}`
             }else{
                 thisanswer = `${Contents}`
             }
                              return(
                                  <>
                                  <tr key={index}>
                                      <td>{item.question}</td>
                                    <td><img src={thisanswer} width="24px"/></td>
                                      <td>{item.thisscore}</td>
                                    <td>{item.timerin}</td>
                                    <td>{item.timerout}</td>
                                    <td>{item.duration}</td>
                                </tr>
                                  </>
                              )
                            })}
                            </tbody>
                        </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </td>
                    <td>{item.score}</td>
                    <td>{item.timerin}</td>
                    <td>{item.timerout}</td>
                    <td> Higher </td>
                    <td> Reachum </td>
                </tr>
        ))
    }

    return (
        <>
            <div className="cccenter">
                <br/>
                <p>Lesson Log</p>
                <h3> {roomname} </h3>
                    <h5>Participants: {thislength}</h5>
                <Table bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th> Name</th>
                        <th> Email</th>
                        <th> Total Duration(min)</th>
                        <th> Activity Log</th>
                        <th> Total Score</th>
                        <th> Time In</th>
                        <th> Time Out</th>
                        <th> Education Level</th>
                        <th> Source</th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderreports()}
                    </tbody>
                </Table>
            </div>

        </>
    )
}

export default LessonLog;
