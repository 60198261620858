import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player'
import {API, Auth, Storage} from 'aws-amplify';
import Modal from 'react-bootstrap/Modal';
import {useParams,useHistory} from "react-router-dom";
import Oneoptionques from './oneoptionques';
import {Button} from 'react-bootstrap';
import './videoquessetup.css';
import {getPlayslide,getVideooptionslist} from "../../../graphql/queries";
import {updateVideooptionslist} from "../../../graphql/mutations"
import {Helmet} from "react-helmet";
import Navbars from "../../Navbar";
import Foot from "../../Foot";
import Goback from "../../../img/dash/Go-Back-Button.png";

function Videoquizsetup(){
    const { id,rid } = useParams();
    const history = useHistory();
    const [isUser, setIsUser] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [current, setCurrent] = useState(false);
    const [thisurl, setThisurl] = useState("");
    const mydata = [{id,rid}];
    const [show, setShow] = useState(false);
    useEffect( () => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
        })()
    }, []);

    useEffect( () => {
        (async() => {
            const response = await API.graphql({
                query: getVideooptionslist,
                variables:{id:rid}
            })
            const datas = response.data.getVideooptionslist;
            const thisvideoid = datas.videoid;
            const rurl = `https://reachumvideos.s3.amazonaws.com/public/videos/${thisvideoid}/${thisvideoid}.mp4`;
            setThisurl(rurl)
        })()
    }, []);

    const handleProgress = (data) => {
        const thisp = Math.floor(data.playedSeconds);
        setCurrent(thisp)
    }
    const handlePlay = () => {
        setPlaying(true);
    }
      function pausethis(){
        setPlaying(false);
          const input = {
              id: rid,
              vsettime:current,
          }
        API.graphql({
        query: updateVideooptionslist,
        variables:{input}
    })
          setShow(true)
}
    function scp(){
        history.push(`/videoquizfile/${id}`);
    }
        return (
            isOwner === isUser ?
            <>
                <Helmet>
                    <title>Video Quiz</title>
                </Helmet>
                <Navbars />

            <div className="videoquessetupplage">
                <br/>
                <br/>
                <div className="upgradenew3">
                    <img src={Goback} className="dropbtn" onClick={scp} alt="go back"/>
                    <span className="tooltiptext3">Back to manage content</span>
                </div>
                <br/>
                <br/>
                <div className="centerthis">
                <p>If you can't see the video, please refresh your browser.</p>
                </div>
                <div className='playerwrapper'>
                <ReactPlayer
                    url={thisurl}
                    playing={playing}
                    onProgress={handleProgress}
                    onPlay={handlePlay}
                    className="reactplayer"
                    playsinline
                    controls
                />
                </div>
            </div>
                <div className="py-3">
                    <Button onClick={pausethis} className="mr-3">
                        + add question at {current}
                    </Button>
                </div>
        <Modal show={show} onHide={() => setShow(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Video Quiz</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Oneoptionques data={mydata}/>
            </Modal.Body>
        </Modal>
                <Foot />
                </>:""
        );
    }
export default Videoquizsetup
