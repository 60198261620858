import React, { useState, useRef, useEffect } from 'react';
import {API} from 'aws-amplify';
import {useParams} from "react-router-dom";
import DesmosCalculator from "./desmosCalculator";
import {getDesmomath} from "../../graphql/queries";
import {newUpdateDesmomath} from "../../graphql/subscriptions";


const  Mirrordesmos = ()  =>{
    const { id } = useParams();
    const bRef = useRef(null)
    useEffect(() => {
        fetchData();
        const updateDesmomath = API.graphql(
            {
                query:newUpdateDesmomath
            }
        ).subscribe({
            next: () => {
                fetchData();
            }
        })
        return()=>{
            updateDesmomath.unsubscribe()
        }
    }, []);

   async function fetchData(){
            const response = await API.graphql({
                query: getDesmomath,
                variables:{id}
            })
            const datas = response.data.getDesmomath;
            const tt = datas.mathstate;
            bRef.current.setState(JSON.parse(tt))

}
    return (
        <>
            <div>
                <DesmosCalculator ref={bRef} />
            </div>
        </>
    );
}

export default Mirrordesmos;
