import {API} from "aws-amplify";
import React, { useEffect, useState } from "react";
import {useParams,useHistory} from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';
import {getPlayslide} from "../../graphql/queries";
import { newUpdatePlayslide } from "../../graphql/subscriptions";
import Grid from '@material-ui/core/Grid';
import "../css/room.css";
import Ending from "../../img/host/Exit-Button.png";
import {Helmet} from "react-helmet";
import Modal from "react-bootstrap/Modal";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";

const detectPrivateMode = () => {
    return new Promise((resolve) => {
        // Check localStorage availability
        try {
            localStorage.setItem('test', '1');
            localStorage.removeItem('test');
            resolve(false);  // Regular browsing mode
        } catch (e) {
            // If error occurs, it's likely Private Browsing
            resolve(true);
        }
    });
};

function Iroom() {
    const [curl, setCurl] = useState("");
    const { id } = useParams();
    const [isopen, setIsopen] = useState(true);
    const [yourusername, setYourusername] = useState('');
    const history = useHistory();
    const [selectedEmoji, setSelectedEmoji] = useState(null);
    const [showthis, setShowthis] = useState(true);
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    })
    const [isValid, setIsValid] = useState(true);
    const finalText = selectedEmoji ? `${yourusername} ${selectedEmoji}` : yourusername;
    const createfinalText = selectedEmoji ? `${stateData.meetname} ${selectedEmoji}` : stateData.meetname;
    sessionStorage.setItem("reachumvideosilent", "true");
    const [isPrivate, setIsPrivate] = useState(false);

    useEffect(() => {
        detectPrivateMode().then((isPrivateMode) => {
            setIsPrivate(isPrivateMode);
        });
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        setYourusername(vreachumnewerna)
        if(reachumnname){
            setIsopen(false);
        }
        const thisss = sessionStorage.getItem("updatedusername");
        if(thisss){
            setShowthis(false);
        };
    }, []);
    useEffect(  () => {
        fetchvlick();
        const subscription = API.graphql(
            {
                query:newUpdatePlayslide
            }
        ).subscribe({
            next: () => fetchData()
        })
        return()=>{
            subscription.unsubscribe()
        }
    }, []);
    async function fetchvlick() {
        try{
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            });
            const datas = response.data.getPlayslide;
            setCurl(datas.clink);
        }catch(error){
            console.log("Cannot display")
        }
    }
    async function fetchData() {
        try {
            const response = await API.graphql({
                query: getPlayslide,
                variables:{id}
            })
            const datas = response.data.getPlayslide;
            setCurl(datas.clink);
            const v = datas.aplaypause;
            if(v === true){
                const thetimer = document.getElementById('theFrame');
                thetimer.src =  "/timer";
            }else{
                setCurl(datas.clink);
            }
            const isplay = datas.vplaypause;
            if(isplay === true){
                const theFrame = document.getElementById('theFrame');
                const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                theFrameDocument.getElementById('reactplayer').setAttribute("playing", true);
            }else{
                const theFrame = document.getElementById('theFrame');
                const theFrameDocument = theFrame.contentDocument || theFrame.contentWindow.document;
                theFrameDocument.getElementById('reactplayer').setAttribute("playing", false);
            }
        }catch(error){
            console.log("hi")
        }
    }
    const infobuttons = () => {document.getElementById("myNavs").style.width = "100%";}
    const closeNavs = () => {
        document.getElementById("myNavs").style.width = "0%";
    }
    useHotkeys('/', () => {
        infobuttons()
    })
    function ends(){
        let text = "Leave this room? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            history.push(`/`);
        }
    }
    const handleSubmit2 = async event => {
        event.preventDefault();
        try {
            const ssname = stateData.meetname;
            const createfinalText = selectedEmoji ? `${ssname} ${selectedEmoji}` : ssname;
            const ssemail = stateData.meetemail;
            localStorage.setItem("vreachumnewername",createfinalText);
            localStorage.setItem("vreachumneweremail", ssemail);
            setShowthis(false);
            sessionStorage.setItem("updatedusername", "1")
        }catch(err){
            console.log(err)
        }
    }
    const handleChange222 = event => {
        const ev = event.target.value;
        setYourusername(ev);
    };

    return (
        <>
            <Helmet>
                <title>MEETING</title>
            </Helmet>
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {curl !== "" && (
                                <iframe
                                    className="viframe"
                                    id="theFrame"
                                    src={curl}
                                    width="100%"
                                    height="100vh"
                                    frameBorder="0"
                                    allow="autoplay;fullscreen;payment,camera; microphone;speaker; display-capture"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} className="bottoms">
                        <Grid item xs={7}>
                            <div className="whitethis">
                            <i>Screen blank, please tap.</i>
                            </div>
                        </Grid>
                        <Grid item xs={5}>
                            <img src={Ending} onClick={ends} className="viewbimg"/>
                        </Grid>
                    </Grid>
                </div>
                <Modal
                    show={showthis}
                    centered
                >
                    <Modal.Body>
                        <div className="enteryournameform">
                            {isopen ?
                                <Form className="market-header">
                                    <Form.Item>
                                        <span> Enter Email</span>
                                        <br/>
                                        <Input
                                            type="email"
                                            value={stateData.meetemail}
                                            onChange={meetemail => {
                                                setStateData({
                                                    ...stateData,
                                                    meetemail: meetemail
                                                });
                                                console.log("meetemail", meetemail)
                                                const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                                const isValidemail = emailRegex.test(meetemail);
                                                setIsValid(isValidemail);
                                            }
                                            }
                                            maxLength={100}
                                            placeholder="Your Email"
                                            className="craeteroomgame"
                                        />
                                        {!isValid && <p style={{ color: '#d2196e' }}>Please enter a valid email address.</p>}
                                    </Form.Item>
                                    <Form.Item>
                                        <span> Create Username:</span>
                                        <br/>
                                        <Input
                                            type="text"
                                            value={stateData.meetname}
                                            onChange={meetname => setStateData({
                                                ...stateData,
                                                meetname: meetname
                                            })}
                                            maxLength={50}
                                            placeholder="Text"
                                            className="craeteroomgame"
                                        />
                                        <br/>
                                        Username with Emoji added: <br/> {createfinalText}
                                    </Form.Item>
                                    <Form.Item>
                                        <Grid container spacing={0}>
                                            <Grid item xs={6}>
                                            </Grid>
                                            <Grid item xs={6} className="rightside">
                                                <Button
                                                    disabled={!stateData.meetemail || !stateData.meetname}
                                                    onClick={handleSubmit2}
                                                    size="sm"
                                                    className="ibuttnsform"
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form.Item>
                                </Form>
                                :
                                <div className="viewright">
                                    <span>Current Username:</span>
                                    <br/>
                                    <input type="text" className="updatenameform" value={yourusername} name="message"
                                           onChange={handleChange222}/>
                                    <br/>
                                    <br/>
                                    <p className="thisfontsize">New Username: <br/> {finalText}</p>
                                    <Button className="updateformbutton" onClick={() => {
                                        localStorage.setItem("vreachumnewername", finalText);
                                        setShowthis(false);
                                        sessionStorage.setItem("updatedusername", "1")

                                    }} size="sm">Submit</Button>
                                    <Button variant="secondary" onClick={() => {
                                        setShowthis(false);
                                        sessionStorage.setItem("updatedusername", "1")
                                    }
                                    } className="updateformbutton" size="sm">Cancel</Button>
                                    <br/>
                                    <br/>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
        </>
    )
}
export default Iroom;
