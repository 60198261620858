import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

export function SortableItem({
  children,
  id,
  selected,
  ...restProps
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const selectedClassName = selected ? 'edititemsselected' : ''
  const className = `lialist ${selectedClassName}`
  return (
    <div
      className={className}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      {...restProps}
    >
      {children}
    </div>
  );
}
