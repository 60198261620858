import React from "react";
import "../css/uploadurl.css";
import Navbars from "../Navbar";
import Foot from "../Foot";
import Iframe from "react-iframe";
import {Helmet} from "react-helmet";

function Uploadlive() {
    return (
            <>
                <Helmet>
                    <title>LIVE STREAM</title>
                </Helmet>
                <Navbars />
                <>
                    <div className="myClassname">
                    <Iframe
                        url="https://form.jotform.com/230108059854052"
                        display="initial"
                        position="relative"
                        width="100%"
                        height="1400px"
                        frameBorder="0"
                    />
                    </div>
                </>
                <Foot />
            </>
    );
}

export default Uploadlive;
