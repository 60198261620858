import React, {useEffect, useState} from "react";
import '@aws-amplify/ui-react/styles.css';
import "../css/login.css";
import {Auth, Hub} from "aws-amplify";
import Barlogin from "./barlogin";
import {Button} from "react-bootstrap";


const Bardashbard = () => {
    const [user, setUser] = useState();
    useEffect(() => {
        getUserData();
    }, []);
    const  getUserData = async () => {
        const user = await Auth.currentAuthenticatedUser();
        if(user){
            const thisemail = user.attributes.email
            setUser(thisemail);
        }else{
            setUser(null)
        }
    }
    const handleSignout = async () => {
        try {
            await Auth.signOut();
        } catch (err) {
            console.log('error signing out: ', err);
        }
    }
    return (
        <>
            {!user ?
                <Barlogin/>
                :
                <div className="center">
                    <p>You are ready!</p>
                    <p>Present and share content directly in teams.</p>
                <p>{user}</p>
                <Button onClick={handleSignout} variant="secondary">Sign Out</Button>
                </div>
            }

        </>
    )
}

export default Bardashbard
