import React, {useEffect, useRef, useState} from "react";
import {API} from 'aws-amplify';
import {updateDesmosfillin} from '../../../../graphql/mutations';
import { useForm } from "react-hook-form";
import {Form,Table} from "react-bootstrap";
import {getDesmosfillin} from "../../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../../css/fastaneditpage.css";
import DesmosCalculator from "../DesmosCalculator";
import Resizer from "react-image-file-resizer";
import Grid from "@material-ui/core/Grid";

const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			400,
			400,
			"JPEG",
			95,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
function Mathfastansweredit() {
	const { id,rid } = useParams();
	const ref = useRef(null);
	const [fname, setFname] = useState("");
	const [ftimers, setFtimers] = useState();
	const [fques, setFques] = useState("");
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [thisimage, setThisimage] = useState('')
	const addRef = useRef(null)
	useEffect( () => {
		(async() => {
			const responses = await API.graphql({
				query: getDesmosfillin,
				variables:{id:rid}
			})
			const datas = responses.data.getDesmosfillin;
			const ffname = datas.ffname;
			setFname(ffname)
			const ftimer = datas.ftimer;
			setFtimers(ftimer)
			const fquestion = datas.fquestion;
			setFques(fquestion)
			const fanswesr = datas.fanswer;
			ref.current.value = fanswesr;
			const mimage = datas.image;
			if(mimage !== null){
				setThisimage(mimage)
			}
		})()
	}, [rid]);
	useEffect(() => {
		function handleInput () {
			const prompts = ref.current.getPrompts()
			const numerator = ref.current.getPromptValue('numerator')
		}
		if (ref.current != null) {
			ref.current.addEventListener('input', handleInput)
			return () => {
				if (ref.current != null) {
					ref.current.removeEventListener('input', handleInput)
				}
			}
		}
	}, [ref.current]);

	function handleMathInputChange (value) {
		ref.current.value = value
	}
	const handleFileChange = async (event) => {
		const img = event.target.files[0];
		const resized = await resizeFile(img);
		setThisimage(resized)
	};
	const {register, handleSubmit} = useForm({});
	const onSubmit = async (data) => {
		const screenshot = addRef.current.screenshot()
		const response = await fetch(screenshot)
		const blob = await response.blob()
		const resized = await resizeFile(blob)
		try {
			const input = {
				id:rid,
				ftimer:data.ftimer,
				fquestion:data.fquestion,
				fanswer:ref.current.value,
				mathstate:resized,
				image:thisimage
			}
			await API.graphql({
				query: updateDesmosfillin,
				variables: {input}
			});
			setUploadedmessage(" Updated. ")
		} catch (err) {
			console.log(err)
		}
	}
	return (
		<>
			<div className="mathfaspage">
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={0}>
				<Grid item xs={3}>
				Game name:
				</Grid>
				<Grid item xs={9}>
					{fname}
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
				Timer (Seconds) :
				</Grid>
				<Grid item xs={9}>
				<input
					name="ftimer"
					defaultValue={ftimers}
					ref={register({valueAsNumber: true, maxLength: 3})}
					className="timers"
					type="number"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3}>
				Question :
				</Grid>
				<Grid item xs={9}>
				<textarea
					name="fquestion"
					defaultValue={fques}
					ref={register({ required: true, maxLength: 200})}
					className="quesmathinput"
				/>
				</Grid>
			</Grid>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3} className="rightside">
					<p>Update image: &nbsp; &nbsp; &nbsp;</p>
				</Grid>
				<Grid item xs={9} className="leftside">
					<input type="file" onChange={handleFileChange} />
				</Grid>
			</Grid>
			<br/>
			<div className="mathcccenter">
				<img src={thisimage} className="pximages400" />
			</div>
			<br/>
			<DesmosCalculator ref={addRef} />
			<br/>
			<br/>
			<h4>Correct answer:</h4>
			<br/>
			<Grid container spacing={0}>
				<Grid item xs={3} className="rightside">
					<p>Update math formulas: &nbsp; &nbsp; &nbsp;</p>
				</Grid>
				<Grid item xs={9} className="leftside">
					<div className="pxsize500">
						<math-field ref={ref} onChange={handleMathInputChange} className="thismathfield"/>
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={0}>
				<Grid item xs={11} className="rightside">
					{uploadedmessage} <input type="submit" value="Update" className="buttoncolor"/>
				</Grid>
				<Grid item xs={1}>
				</Grid>
			</Grid>
		</form>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
			</div>
			</>
	);
}
export default Mathfastansweredit;
