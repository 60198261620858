import { Expression, GraphingCalculator } from 'desmos-react'
import { forwardRef } from 'react'
import './style.css'

function DesmosCalculator(props, ref) {
    return (
        <>
            <GraphingCalculator
                attributes={{ className: 'calculator3' }}
                keypad
                projectorMode
                ref={ref}
            >
            </GraphingCalculator>
        </>
    )
}
export default forwardRef(DesmosCalculator)
