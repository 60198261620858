import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {updateFlipcard} from '../../../graphql/mutations';
import {Form,Table} from "react-bootstrap";
import {getFlipcard} from "../../../graphql/queries";
import {useParams} from "react-router-dom";
import "../../css/poll.css";
import Grid from "@material-ui/core/Grid";

function Flipcardsedit() {
	const { id,rid } = useParams();
	const [uploadedmessage, setUploadedmessage] = useState("");
	const [fname, setFname] = useState('')
	const [ftimer, setFtimer] = useState('')
	const [type1, setType1] = useState('')
	const [type2, setType2] = useState('')
	const [type3, setType3] = useState('')
	const [type4, setType4] = useState('')
	const [type5, setType5] = useState('')
	const [type6, setType6] = useState('')
	const [text1, setText1] = useState('')
	const [text2, setText2] = useState('')
	const [text3, setText3] = useState('')
	const [text4, setText4] = useState('')
	const [text5, setText5] = useState('')
	const [text6, setText6] = useState('')
	useEffect( () => {
		(async() => {
			const response = await API.graphql({
				query: getFlipcard,
				variables:{id:rid}
			})
			const datas = response.data.getFlipcard;
            const fnames = datas.pfname;
			setFname(fnames)
			const ftimers = datas.ptimer;
			setFtimer(ftimers)
			const fwordss = datas.fwords;
			setText1(fwordss[0].text)
			setType1(fwordss[0].type)
			setText2(fwordss[1].text)
			setType2(fwordss[1].type)
			setText3(fwordss[2].text)
			setType3(fwordss[2].type)
			setText4(fwordss[3].text)
			setType4(fwordss[3].type)
			setText5(fwordss[4].text)
			setType5(fwordss[4].type)
			setText6(fwordss[5].text)
			setType6(fwordss[5].type)

		})()
	}, [id]);


	async function handleSubmit (event){
		event.preventDefault()
		const card1 = { type: type1, text: text1 }
		const card2 = { type: type2, text: text2 }
		const card3 = { type: type3, text: text3 }
		const card4 = { type: type4, text: text4 }
		const card5 = { type: type5, text: text5 }
		const card6 = { type: type6, text: text6 }
		const newCards = [card1, card2, card3, card4, card5, card6]
		try {
			const input = {
				id:rid,
				ptimer:ftimer,
				fwords:newCards
			}
			await API.graphql({
				query: updateFlipcard,
				variables: {input}
			});
				setUploadedmessage("Updated!")

		} catch (err) {
			console.log(err)
		}
	}

	function changedtimer(event){
		setFtimer(event.target.value)
	}
	function changeType1 (event) {
		setType1(event.target.value)
	}

	function changeText1 (event) {
		setText1(event.target.value)
	}

	function changeType2 (event) {
		setType2(event.target.value)
	}

	function changeText2 (event) {
		setText2(event.target.value)
	}

	function changeType3 (event) {
		setType3(event.target.value)
	}

	function changeText3 (event) {
		setText3(event.target.value)
	}

	function changeType4 (event) {
		setType4(event.target.value)
	}

	function changeText4 (event) {
		setText4(event.target.value)
	}

	function changeType5 (event) {
		setType5(event.target.value)
	}

	function changeText5 (event) {
		setText5(event.target.value)
	}

	function changeType6 (event) {
		setType6(event.target.value)
	}

	function changeText6 (event) {
		setText6(event.target.value)
	}

	return (
			<>
						<form onSubmit={handleSubmit}>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label>Game name: {fname}</Form.Label>
							</Form.Group>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label>Update timer (Seconds) : </Form.Label>
								<br/>
								<input
									value={ftimer}
									type="number"
									onChange={changedtimer}
									className="dragdroptimer"
									maxLength="3"
								/>
							</Form.Group>
							<p>Update a word/text(limit 50)</p>
							<Form.Group className="mb-3" controlId="word1">
									<ol>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
											<input value={type1} type="text" onChange={changeType1} maxLength="50" className="finput"/>
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text1} type="text" onChange={changeText1} maxLength="50" className="finput"/>
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
											<Grid item xs={5}>
											<input value={type2} type="text" onChange={changeType2} maxLength="50" className="finput" />
											</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text2} type="text" onChange={changeText2} maxLength="50" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
											<input value={type3} type="text" onChange={changeType3} maxLength="50" className="finput" />
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text3} type="text" onChange={changeText3} maxLength="50" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
											<input value={type4} type="text" onChange={changeType4} maxLength="50" className="finput" />
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
											<input value={text4} type="text" onChange={changeText4} maxLength="50" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
													<input value={type5} type="text" onChange={changeType5} maxLength="50" className="finput" />
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
													<input value={text5} type="text" onChange={changeText5} maxLength="50" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
										<li>
											<Grid container spacing={0}>
												<Grid item xs={5}>
													<input value={type6} type="text" onChange={changeType6} maxLength="50" className="finput" />
												</Grid>
												<Grid item xs={1} className="eq">
													=
												</Grid>
												<Grid item xs={6}>
													<input value={text6} type="text" onChange={changeText6} maxLength="50" className="finput" />
												</Grid>
											</Grid>
										</li>
										<br/>
									</ol>
							</Form.Group>
							<Table>
								<tr>
									<td>
									</td>
									<td className="orderright">
										{uploadedmessage} <input type="submit" value="Submit" className="submitcolor"/>
									</td>
								</tr>
							</Table>
					</form>
			</>

	);
}
export default Flipcardsedit;
