import React from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { DropZone } from './DropZone';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'normal',
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
  },
  cloudText: {
    textAlign: 'center'
  },
  cloudTextSpan: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: 'red',
    padding: '5px 10px',
  },
  cloud: {
    color: '#034F4B',
  },
}));

export const UploadFiles = ({ onUpload, allowedFileTypes, multiple, processing }) => {
  const classes = useStyles();

  const handleFilesChange = (e) => {
    const files = e.target.files;
    files && onUpload(files);
  };

  return (
    <label htmlFor="files-drop-zone">
      <DropZone onFilesDrop={onUpload} className="dropZone">
        {processing ? <h4 className={classes.cloudText}>Processing...</h4> :
          <>
            <FaCloudUploadAlt className={classes.cloud} size="105px"/>
            <p className={classes.cloudText}>
            Drag and Drop or
            <span className={classes.cloudTextSpan}>Browse</span>
            to upload
            </p>

            <input
              id="files-drop-zone"
              accept={allowedFileTypes?.join(', ')}
              onChange={handleFilesChange}
              hidden
              type="file"
              multiple={multiple}
              name="files"
            />
          </>
        }
      </DropZone>
    </label>
  );
};
