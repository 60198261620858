import React, {useState, useMemo, useEffect} from 'react';
import {API} from "aws-amplify";
import {listLeaderboards} from "../../../../graphql/queries";
import {createEngagement, createLeaderboard, updateLeaderboard} from "../../../../graphql/mutations";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Grid from "@material-ui/core/Grid";
import {Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../../css/taptap.css";
import MathJax from "react-mathjax";

function shuffle(items) {
  const entries = items.map((item) => [Math.random(), item])
  entries.sort((a, b) => a[0] - b[0])
  return entries.map((entry) => entry[1])
}

export default function Showitemlist() {
  const [quest, setQuest] = useState();
  const [hquest, setHquest] = useState();
  const [timeval, setTimeval] = useState(0);
  const { id, rid } = useParams();
  const [messages, setMessages] = useState();
  const [thissaved, setThissaved] = useState([]);
  const [isShows, setIsShows] = useState(true);
  const [isshowreset, setIsshowreset] = useState(false);
  const [playpause, setPlaypause] = useState(true);
  const [mathin, setMathin] = useState("");
  const [mathdata, setMathdata] = useState("");
  const [thisimage, setThisimage] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const [counter, setCounter] = useState();
  useEffect(() => {
    const newCards=sessionStorage.getItem("desmosequencinggamesarray");
    setQuest(newCards);
    const newcounter=Number(sessionStorage.getItem("desmosequencinggamescounter"));
    setCounter(newcounter);
    const newmath=sessionStorage.getItem("desmosequencinggames");
    setMathin(newmath);
    const newmaths=sessionStorage.getItem("desmosequencinggamesques");
    setHquest(newmaths);
    const newmathinput=sessionStorage.getItem("desmosequencinggamesmathinput");
    setMathdata(newmathinput);
    const newmathimage=sessionStorage.getItem("desmosequencinggamesqueimage");
    setThisimage(newmathimage);
    const submitrids = sessionStorage.getItem("submitrid");
    if(submitrids){
      setThissaved(submitrids)
      if(submitrids.includes(rid)){
        setDisabled(true);
      }
    }
  }, []);
  const storedItems = useMemo(() => {
    const stored = sessionStorage.getItem('desmosequencinggamesarray');
    if (stored == null) {
      return []
    }
    return JSON.parse(stored)
  }, [])
  const [items, setItems] = useState(() => {
    return shuffle(storedItems)
  })
  const defaultAnswers = items.map(() => {
    return {
      id: '',
      text: '',
      mathstate: ''
    }
  })

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setTimeval(0)
      return <div className="timer">0</div>;
    }else{
      setTimeval(remainingTime)
    }
    return (
        <div className="timer">
          <div className="value">{remainingTime}</div>
        </div>
    );
  }
  const [activeItem, setActiveItem] = useState()

  const [answers, setAnswers] = useState(() => {
    return defaultAnswers
  })
  const answerViews = answers.map((answer, answerIndex) => {
    function handleClick () {
      if (activeItem == null) {
        return
      }
      setAnswers(currentAnswers => {
        const newAnswers = currentAnswers.map((currentAnswer, currentAnswerIndex) => {
          if (answerIndex === currentAnswerIndex) {
            return activeItem
          }
          return currentAnswer
        })
        return newAnswers
      })
      setActiveItem(undefined)
    }
    const key = answer.id !== '' ? answer.id : answerIndex
    const image = answer.mathstate !== '' && (
        <img src={answer.mathstate} style={{ width: '110px' }} />
    )
    return (
        <>
        <li key={key} onClick={handleClick} style={{fontSize: "20px", marginLeft:"8px", marginBottom:"8px", backgroundColor:"#f0f0f0", color:"#0c4128", padding:"5px"}}>
         <div className="centerthis">
          <span style={{marginLeft:"8px"}}>{answer.text}</span> &nbsp; &nbsp;
          {image}
         </div>
        </li>
          </>
    )
  })
  const remainingItems = items.filter(item => {
    const answered = answers.some(answer => answer.id === item.id)
    return !answered
  })
  const remainingViews = remainingItems.map(item => {
    function handleClick () {
      setActiveItem(item);
      setIsshowreset(true)
    }
    const isActive = activeItem != null && activeItem.id === item.id;
    const style = { margin: '5px', padding: '10px', fontSize:"20px", border:"1px solid gray",borderRadius:"10px" }
    if (isActive) {
      style.background='#e82c82';
      style.color="#ffffff";
      style.borderRadius="10px";
      style.transform="scale(1.5, 1.5)"
    }
    const image = item.mathstate !== '' && (
        <img src={item.mathstate} style={{ width: '150px' }} />
    )
    return (
        <div
            key={item.id}
            style={style}
            onClick={handleClick}
        >
          <span>{item.text}</span><br/>
          {image}
        </div>
    )
  })
  function handleReset () {
    setAnswers(defaultAnswers);
    setIsshowreset(false)
  }

  function handleSubmit() {
    const correct = answers.filter((answer, answerIndex) => {
      const item = storedItems[answerIndex]
      return item.id === answer.id
    })
    const vv = correct.length;
    const m = items.length;
    let tt = [];
    if (thissaved){
      tt = [thissaved, rid]
    }else{
      tt = [rid]
    }
    sessionStorage.setItem("submitrid", tt);
    setPlaypause(false);
    let mymeetnames = localStorage.getItem("vreachumnewername");
    let mymeetemails = localStorage.getItem("vreachumneweremail");
    const ttr = timeval * vv;
    const cm = ttr/m;
    const scre = Math.floor(cm);
   if (scre > 0) {
      try {
        API.graphql({
          query: listLeaderboards,
          variables: {
            filter: {
              "meetemail": {
                eq: mymeetemails
              },
              "answerid": {
                eq: id
              }
            }
          }
        }).then(datas => {
          const plays = datas.data.listLeaderboards.items;
          const playsl = plays.length;
          if (playsl > 0) {
            const myscore = plays[0].score;
            const myid = plays[0].id;
            const myscores = scre + myscore;
            try {
              const input = {
                id: myid,
                score: myscores,
                thisgamescore:scre
              }
              API.graphql({
                query: updateLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          } else {
            try {
              const input = {
                answerid: id,
                name: mymeetnames,
                meetemail:mymeetemails,
                score: scre,
                thisgamescore:scre
              }
              API.graphql({
                query: createLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }
          setMessages(`👏 You scored ${scre} points.`)
        })
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        API.graphql({
          query: listLeaderboards,
          variables: {
            filter: {
              "meetemail": {
                eq: mymeetemails
              },
              "answerid": {
                eq: id
              }
            }
          }
        }).then(datas => {
          const plays = datas.data.listLeaderboards.items;
          const playsl = plays.length;
          if (playsl === 0) {
            try {
              const input = {
                answerid: id,
                name: mymeetnames,
                meetemail:mymeetemails,
                score: 0,
                thisgamescore:0
              }
              API.graphql({
                query: createLeaderboard,
                variables: {input}
              });

            } catch (err) {
              console.log(err)
            }
          }else{
            const myid = plays[0].id;
            try {
              const input = {
                id: myid,
                thisgamescore:0
              }
              API.graphql({
                query: updateLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
      setMessages("You scored 0 point.")
    }
    setIsShows(false);
  }

  const getdatas = async () =>{
    const mymeetnams = localStorage.getItem("vreachumnewername");
    const mymeetemals = localStorage.getItem("vreachumneweremail");
    if(mymeetemals) {
      try {
        API.graphql({
          query: listLeaderboards,
          variables: {
            filter: {
              "meetemail": {
                eq: mymeetemals
              },
              "answerid": {
                eq: id
              }
            },
            limit:10000
          }
        }).then(datas => {
          const plays = datas.data.listLeaderboards.items;
          const playsl = plays.length;
          if (playsl === 0) {
            try {
              const input = {
                answerid: id,
                name: mymeetnams,
                meetemail: mymeetemals,
                score: 0,
                thisgamescore:0
              }
              API.graphql({
                query: createLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }else{
            const myid = plays[0].id;
            try {
              const input = {
                id: myid,
                thisgamescore:0
              }
              API.graphql({
                query: updateLeaderboard,
                variables: {input}
              });
            } catch (err) {
              console.log(err)
            }
          }
          setMessages("You scored 0 point")
          setIsShows(false);
          try {
            const input = {
              questionid: id,
              engage: 0,
              people: 1
            }
            API.graphql({
              query: createEngagement,
              variables: {input}
            });
          } catch (err) {
            console.log(err)
          }
        })
      } catch (err) {
        console.log(err)
      }
    }else{
      setMessages("Time is up.")
      setIsShows(false);
    }
  }

  return (
                    <div className="fastanswerspage2">
                      <div className="greenback">
                        <Grid container spacing={0}>
                          <Grid item xs={2}>
                            <div className="lpollheadclock2">
                              <CountdownCircleTimer
                                  isPlaying={playpause}
                                  duration={counter}
                                  size={50}
                                  colors={["#a0dcbc", "#a0dcbc", "#a0dcbc", "#a0dcbc"]}
                                  colorsTime={[10, 6, 3, 0]}
                                  onComplete={getdatas}
                              >
                                {renderTime}
                              </CountdownCircleTimer>
                            </div>
                    </Grid>
                       <Grid item xs={10}>
                        <div className="fastanswes">
                          <h4>{hquest}</h4>
                        </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="cccenter">
                        <MathJax.Provider>
                          <div className="mathshowfont">
                            <MathJax.Node formula={mathdata} />
                          </div>
                        </MathJax.Provider>
                        <br/>
                        <img src={thisimage} className="fillinthimgshow" />
                      </div>
                      <br/>
                      <br/>
                        <>
                          {isShows ?
                              <>
<div style={{
  display: 'flex',
  flexWrap: "wrap",
  width: "100%",
  margin: "0 auto",
  padding:"10px",
  alignItems: "center",
  backgroundColor:"#f0f0f0",
  justifyContent: "center" }}>
                                  {remainingViews}
</div>
                                <br/>
                                    <ol style={{textAlign:"left", margin:"10px", width:"98%"}}>
                                      {answerViews}
                                    </ol>
                                  <br/>
                                  <Grid container spacing={0}>
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={5} className="leftside">
                                      {isshowreset ?
                                              <Button onClick={handleReset} variant="danger">Reset</Button>
                                          : ""
                                      }
                                    </Grid>
                                    <Grid item xs={5} className="rightside">
                                      <Button onClick={handleSubmit} variant="success" disabled={isDisabled}>Submit</Button>
                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>
                                  </Grid>
                                <br/>
                                <br/>
<br/>
                              </> :
                              <>
                                <div>
                                  <br/>
                                  <br/>
                                  <h4 className="pollmaeeage">{messages}</h4>
                                </div>
                              </>
                          }
                        </>
                      </div>


  )
}
