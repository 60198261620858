import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {getPlayslide, listHosts, listLeaderboards} from '../../graphql/queries';
import {useHistory, useParams} from "react-router-dom";
import {Button, Modal, Table} from 'react-bootstrap';
import "../css/leaderboard.css";
import {Helmet} from "react-helmet";
import Deleteroom from "../../img/dash/Delete-Button-X.png";
import {deleteLeaderboard, updateHost} from "../../graphql/mutations";
import Navbars from "../Navbar";
import Grid from "@material-ui/core/Grid";
import Leftarrow from "../../img/left-arrow.png";
import Foot from "../Foot";
import Hostleaderboard from "./leaderboard/hostleaderboard"
import Responserate from "./responserate/responserate";
import Cdleaderboard from "./leaderboard/cdleaderboard";
import Cdtimetracking from "./timetracking/cdtimetracking";
import Moretimereports from "./timetracking/moretimereports";
import Morereports from "./leaderboard/morereports";
import Morereponsereports from "./responserate/morereponsereports";
import Newinfo from "../../img/info.png";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Correctimg from "../../img/view/check-mark.png";
import Incorrectimh from "../../img/view/close.png";
import Noanswer from "../../img/view/question-mark.png";
import Contents from "../../img/view/content-management.png"
import Accordion from 'react-bootstrap/Accordion';

function Reports() {
    const [myData, setMyData] = useState([]);
    const {id} = useParams();
    const history = useHistory();
    const [isOwner, setIsOwner] = useState("");
    const [isUser, setIsUser] = useState("");
    const [roomname, setRoomname] = useState("");
    const [thisdatas, setThisdatas] = useState([]);
    const [timetrackingr, setTimetrackingr] = useState(false);
    const [lessontracking, setLessontracking] =useState(false);
    const [leadersr, setLeadersr] = useState(false);
    const [responser, setResponser] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Time Tracking');
    const [opentimetracking ,setOpentimetracking] = useState(true);
    const [openleader ,setOpenleader] = useState(false);
    const [openrespnserate, setOpenrespnserate] = useState(false);
    const [showroomname, setShowroomname] = useState(true);
    const [aggregater, setAggregater] = useState(false);
    const [startreport, setStartreport] = useState(true);
    const [passcodevalue, setPasscodevalue] = useState("");
    const [show, setShow] = useState(false);
    const [cionopied, setCionopied] = useState("")
    const [showcopy, setShowcopy] = useState(false);
    const [sharefull, setSharefull] = useState(true);
    const [thisshareid, setThisshareid] = useState("")
    const [thislefthours, setThislefthours] = useState(0);
    const [thislength, setThislength] = useState(0)
    const handleClose = () => setShow(false);
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => (setIsUser(user.attributes.email)))
            .catch(err => console.log(err));
        (async () => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            setIsOwner(iowner);
            const myroomname = datas.meetingname;
            setRoomname(myroomname);
            const responses = await API.graphql({
                query: listHosts,
                variables: {
                    filter: {
                        "owner": {
                            eq: iowner
                        }
                    },
                    limit: 10000
                }
            })
            const datass = responses.data.listHosts.items;
            if(datass.length !== 0) {
                const shateid = datass[0].id;
                setThisshareid(shateid);
                const tt = datass[0].usage;
                if(tt !== "") {
                    const timestamp2 = Math.floor(new Date().getTime() / 1000);
                    const differenceInTime = tt - timestamp2;
                    if (differenceInTime > 0) {
                        const  t = datass[0].refer;
                        setSharefull(false)
                        setPasscodevalue(t);
                        const tl = Math.floor(differenceInTime/3600)
                        setThislefthours(tl)
                    }else{
                        setPasscodevalue("");
                    }
                }
            }
        })()
        fetchDas();
    }, []);

    async function fetchDas() {
        const dcounts = [];
        const scounts = [];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "roomid": {
                            eq: id
                        }
                    },
                    limit: 10000
                }
            })
            const datas = response.data.listLeaderboards.items;
            if (datas.length !== 0) {
                setThisdatas(datas);
                setThislength(datas.length)
                datas.forEach((data) => {
                    const listdata = data;
                    scounts.push(listdata)
                })
                scounts.sort((a, b) => {
                    return b.score - a.score;
                });
                scounts.forEach((e) => {
                    dcounts.push(e)
                });
                setMyData(dcounts)
                console.log("dcounts", datas)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleDeleteperson = async (itemId) => {
        let text = "Are you sure you want to delete this player? \nEither OK or Cancel.";
        if (window.confirm(text) === true) {
            const input = {
                id: itemId
            };
            await API.graphql({
                query: deleteLeaderboard,
                variables: {input}
            });
            window.location.reload();
        }
    };
    const renderreports = () => {
        console.log("myData", myData)
        return myData.map((item, index) => (
            isOwner === isUser ?
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.meetemail}</td>
                    <td>
                        {Math.floor(parseInt(item.duration) / 60)}
                    </td>
                    <td>
                        <Accordion>
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header>Activities</Accordion.Header>
                                <Accordion.Body>
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>Activity</th>
                                <th> Correct/ Incorrect Answer</th>
                                <th> Score</th>
                                <th>Timer In</th>
                                <th>Timer Out</th>
                                <th>Duration(Seconds)</th>
                            </tr></thead>
                            <tbody>
                            {item.questions && item.questions.map((item, index) => {
                                const thisas = item.correctanswer;
                                let thisanswer;
             if(thisas === "correct"){
                 thisanswer = `${Correctimg}`
               }else if(thisas === "wrong"){
                 thisanswer = `${Incorrectimh}`
             }else if(thisas === "No answer"){
                 thisanswer = `${Noanswer}`
             }else{
                 thisanswer = `${Contents}`
             }
                              return(
                                  <>
                                  <tr key={index}>
                                      <td>{item.question}</td>
                                    <td><img src={thisanswer} width="24px"/></td>
                                      <td>{item.thisscore}</td>
                                    <td>{item.timerin}</td>
                                    <td>{item.timerout}</td>
                                    <td>{item.duration}</td>
                                </tr>
                                  </>
                              )
                            })}
                            </tbody>
                        </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </td>
                    <td>{item.score}</td>
                    <td>{item.timerin}</td>
                    <td>{item.timerout}</td>
                    <td> Higher </td>
                    <td> Reachum </td>
                    <td>
                        <img src={Deleteroom} onClick={() => {
                            handleDeleteperson(item.id)
                        }} className="dropbtn" alt="delete a person"/></td>
                </tr> : ""
        ))
    }

    const rendertimereports = () =>{
        return myData.map((item, index) => (
            isOwner === isUser ?
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.meetemail}</td>
                    <td>
                        {Math.floor(parseInt(item.duration) / 60)}
                    </td>
                    <td>{item.timerin}</td>
                    <td>{item.timerout}</td>
                    <td>
                        <img src={Deleteroom} onClick={() => {
                            handleDeleteperson(item.id)
                        }} className="dropbtn" alt="delete a person"/></td>
                </tr> : ""
        ))
    }


    function gobackthis() {
        history.push(`/`);
    }

    function timetfunction() {
        setTimetrackingr(true)
        setShowroomname(true)
        setLessontracking(false)
        setLeadersr(false)
        setResponser(false)
        setAggregater(false)
        setStartreport(false)
    }
   function lessonfunction(){
       setLessontracking(true)
       setShowroomname(true)
       setTimetrackingr(false)
       setLeadersr(false)
       setResponser(false)
       setAggregater(false)
       setStartreport(false)
   }
    function leaderfunction() {
        setLeadersr(true)
        setShowroomname(true)
        setTimetrackingr(false)
        setLessontracking(false)
        setResponser(false)
        setAggregater(false)
        setStartreport(false)
    }

    function responsefunction() {
        setShowroomname(true)
        setResponser(true)
        setLessontracking(false)
        setTimetrackingr(false)
        setLeadersr(false);
        setAggregater(false)
        setStartreport(false)
    }

    function aggregatefunction() {
        setAggregater(true)
        setShowroomname(false)
        setResponser(false)
        setLessontracking(false)
        setTimetrackingr(false)
        setLeadersr(false);
        setStartreport(false)
    }

    const handleOptionChange = (event) => {
        const cc = event.target.value;
        if (cc === "Response Rate") {
            setOpentimetracking(false);
            setOpenleader(false)
            setOpenrespnserate(true);
            setSelectedOption("Response Rate")
        }else{
            setOpentimetracking(false);
            setOpenrespnserate(false);
            setOpenleader(true);
            setSelectedOption("LessonLog")
        }
    };

    async function removethis() {
        const input = {
            id: thisshareid,
            refer: "",
            usage: 0
        }
        await API.graphql({
            query: updateHost,
            variables: {input}
        });
        setSharefull(true);
        setShowcopy(false);
        setPasscodevalue("");
    }

    async function sendtodata() {
        try {
            const twoWeeksFromToday = new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000));
            const timestamp = Math.floor(twoWeeksFromToday.getTime() / 1000);
            const input = {
                id: thisshareid,
                usage: timestamp,
                refer: passcodevalue
            }
            await API.graphql({
                query: updateHost,
                variables: {input}
            });
            setSharefull(false);
            setThislefthours(48)
        } catch (err) {
            console.log(err)
        }
    }

    const handleChange = e => {
        const ts = e.target.value;
        if (ts !== "") {
            setPasscodevalue(ts);
            setShowcopy(true)
        }
    }
    return (
        <>
            <Helmet>
                <title>Reports</title>
            </Helmet>
            <>
                <Navbars/>
                {isOwner === isUser ?
                    <>
                        <div className="leaderpage">
                            <div className="reportpageheadview">
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <h5 className="sharedropbtn" onClick={gobackthis}>
                                            <img src={Leftarrow}
                                                 width="13"
                                                 height="13"/>
                                            &nbsp; Lesson List
                                        </h5>
                                    </Grid>
                                    <Grid item xs={10} className="cccenter">
                                        {showroomname ?
                                            <h3>{roomname}</h3>
                                            : ""
                                        }
                                    </Grid>
                                </Grid>
                                <br/>
                                <br/>
                                <Grid container spacing={0}>
                                    <Grid item xs={2}>
                                        <div className="cccenter">
                                            <h4>Report Menu</h4>
                                        </div>
                                        <br/>
                                        <p onClick={lessonfunction} className="hoverreportmenu">Lesson Log</p>
                                        <p onClick={timetfunction} className="hoverreportmenu">Time Tracking</p>
                                        <p onClick={leaderfunction} className="hoverreportmenu">Leaderboard</p>
                                        <p onClick={responsefunction} className="hoverreportmenu">Response Rate</p>
                                        <p onClick={aggregatefunction} className="hoverreportmenu">Report by Date</p>
                                        <hr/>
                                        <br/>
                                        {leadersr ?
                                            <Cdleaderboard data={id}/>
                                            : ""
                                        }
                                        {timetrackingr ?
                                            <Cdtimetracking data={id}/>
                                            : ""
                                        }
                                        {aggregater ?
                                            <>
                                                {sharefull ?
                                                    <p className="hoverreportmenu" onClick={() => setShow(true)}>Share
                                                        Reports</p>
                                                    :
                                                    <p className="grays">Share Reports</p>
                                                }
                                                {!sharefull ?
                                                    <>
                                                        <p className="redthis">Current Code: {passcodevalue}</p>
                                                        <CopyToClipboard
                                                            text={`https://demo.reachum.com/shareReports?p=${passcodevalue}`}
                                                            onCopy={() => {
                                                                setCionopied(true);
                                                                setTimeout(() => {
                                                                    setCionopied(false);
                                                                }, 5000);
                                                            }}>
                                                            <p>Copy Share Link</p>
                                                        </CopyToClipboard>
                                                        <br/>
                                                        <br/>
                                                        <p onClick={removethis}>Stop Sharing</p>
                                                        <p className="grays">{thislefthours} hours left</p>
                                                    </>
                                                    : ""
                                                }
                                            </>
                                            : ""
                                        }
                                    </Grid>
                                    <Grid item xs={10} className="cccenter">
                                        <div className="dashvl"></div>
                                        {startreport ?
                                            <p className="thisreportstart"> Click on a report to get started!</p>
                                            : ""
                                        }
                                        {lessontracking ?
                                            <div className="cccenter">
                                                <h4> Lesson Log</h4>
                                                <br/>
                                                <div className="rightsidethis">
                                                <h5>Participants: {thislength}</h5>
                                                </div>
                                                <Table bordered hover size="sm">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th> Name</th>
                                                        <th> Email</th>
                                                        <th> Total Duration(min)</th>
                                                        <th> Activity Log</th>
                                                        <th> Total Score</th>
                                                        <th> Time In</th>
                                                        <th> Time Out</th>
                                                        <th> Education Level</th>
                                                        <th> Source</th>
                                                        <th> Delete</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {renderreports()}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : ""
                                        }
                                        {timetrackingr ?
                                            <div className="cccenter">
                                                <h4> Time Tracking
                                                    <div className="upgradenew2">
                                                        <img src={Newinfo} alt="info" />
                                                        <span className="tooltiptext2">
View Time Tracking data for a Lesson, including each participant’s name, email, the total amount of time spent in a Lesson, and when they entered and exited the Lesson. This data can then be exported as a CSV file if desired.
                                                        </span>
                                                    </div></h4>
                                                <br/>
                                                <Table bordered hover size="sm">
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th> Name</th>
                                                        <th> Email</th>
                                                        <th> Total Duration(min)</th>
                                                        <th> Time In</th>
                                                        <th> Time Out</th>
                                                        <th> Delete</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {rendertimereports()}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            : ""
                                        }
                                        {leadersr ?
                                            <div className="cccenter">
                                                <h4> Leaderboard
                                                    <div className="upgradenew2">
                                                        <img src={Newinfo} alt="info"/>
                                                        <span className="tooltiptext2">
View a list of Leaderboard scores for all participants in a Lesson. This data can then be exported as a CSV file if desired.
                                                        </span>
                                                    </div></h4>
                                                <br/>
                                                <Hostleaderboard data={id}/>
                                            </div>
                                            : ""
                                        }
                                        {responser ?
                                            <div className="cccenter">
                                                <h4> Response Rate
                                                    <div className="upgradenew2">
                                                        <img src={Newinfo} alt="info"/>
                                                        <span className="tooltiptext2">
Response Rate is calculated by multiplying the number of questions you ask in a Lesson by the number of students in your class, then dividing that number by the total number of answers received.
                                                        </span>
                                                    </div></h4>
                                                <br/>
                                                <Responserate data={id}/>
                                            </div>
                                            : ""
                                        }
                                        {aggregater ?
                                            <div className="cccenter">
                                                <h4>Choose a report type:</h4>
                                                <br/>
                                                <Grid container spacing={0} className="topspace1">
                                                    <Grid item xs={6} className="cccenter">
                                                        <input
                                                            type="radio"
                                                            name="options"
                                                            value="Leaderboard"
                                                            checked={selectedOption === 'LessonLog'}
                                                            onChange={handleOptionChange}
                                                            className="newinviteradio"
                                                        />
                                                        <label className="newinviteradiolabel">
                                                            Lesson Log
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={6} className="cccenter">
                                                        <input
                                                            type="radio"
                                                            name="options"
                                                            value="Response Rate"
                                                            checked={selectedOption === "Response Rate"}
                                                            onChange={handleOptionChange}
                                                            className="newinviteradio"
                                                        />
                                                        <label className="newinviteradiolabel">
                                                            Response Rate
                                                        </label>
                                                    </Grid>
                                                </Grid>
                                                <hr/>
                                                <br/>
                                                {openleader ?
                                                    <Morereports data={id}/>
                                                    : ""
                                                }
                                                {openrespnserate ?
                                                    <Morereponsereports data={id}/>
                                                    : ""
                                                }
                                            </div>
                                            : ""
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                               size="lg" centered>
                            <Modal.Header closeButton>
                                <span>Share Reports</span>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="cccenter">
                                    <h4> Send your reports to an administrator?</h4>
                                    <p>Create a code, click 'Copy Link,' and share via email.</p>
                                    <input type="text" placeholder="Enter code" value={passcodevalue}
                                           onChange={handleChange} maxLength="15"/>
                                    <br/>
                                    {showcopy ?
                                        <div>
                                            <br/>
                                            <span>The code will expire either when you click 'Stop Sharing' or after 48 hours.</span>
                                            <br/>
                                            <br/>
                                            <CopyToClipboard
                                                text={`https://demo.reachum.com/shareReports?p=${passcodevalue}`}
                                                onCopy={() => {
                                                    setCionopied(true);
                                                    setTimeout(() => {
                                                        setCionopied(false);
                                                    }, 5000);
                                                }}>
                                                <Button onClick={sendtodata} className="copys">Copy Link</Button>
                                            </CopyToClipboard>
                                            <br/>
                                            {cionopied ? <span style={{color: 'red'}}>Copied</span> : null}
                                        </div>
                                        : ""
                                    }
                                </div>
                                <br/>
                                <br/>
                            </Modal.Body>
                        </Modal>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </> : ""
                }
                <Foot/>
            </>
        </>
    )
}

export default Reports;
