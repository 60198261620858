import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Room from "./components/view/room";
import Iroom from "./components/view/iroom";
import Mcroom from "./components/view/mcroom";
import Support from "./pages/support";
import Dashboard from "./pages/dashboard/dashboard";
import Rehearse from "./pages/rehearse";
import SelfStudy from "./pages/ondemand/selfStudy";
import Editroom from "./pages/editroom";
import Pricing from "./pages/pricing";
import Profile from "./pages/profile";
import TermandConditions from "./pages/termandConditions";
import Imroom from "./components/host/imroom";
import Mroom from "./components/host/mroom";
import Comroom from "./components/cohost/mroom";
import Vroom from "./components/groupleader/vroom";
import Timer from "./components/timer";
import Rform from "./components/view/rform";
import Vform from "./components/groupleader/vform";
import Uploadvideo from "./components/uploadfiles/vid/uploadvideo";
import Vshowroom from "./components/uploadfiles/vid/vshowroom";
import Pdfshowroom from "./components/uploadfiles/pdf/pdfshowroom";
import Uploadpdf from "./components/uploadfiles/pdf/uploadpdf";
import Imgshowroom from "./components/uploadfiles/img/imgshowroom";
import Uploadimg from "./components/uploadfiles/img/uploadimg";
import Gifshowroom from "./components/gif/gifshowroom";
import Polls from "./components/poll/polls";
import Leaderboard from "./components/reports/leaderboard";
import Pollshowroom from "./components/poll/pollshowroom";
import Giphy from "./components/gif/giphy";
import Qrcodepage from "./pages/qrcodepage";
import Inviteform1 from "./pages/invitepeople/inviteform1";
import Inviteondemandform from "./pages/invitepeople/inviteondemandform";
import Gifroom from "./components/uploadfiles/uploadgif/gifroom";
import Uploadgifs from "./components/uploadfiles/uploadgif/uploadgifs";
import Uploadurl from "./components/uploadfiles/uploadurl/uploadurl";
import Iurlshowroom from "./components/uploadfiles/uploadurl/iurlshowroom";
import Urlshowroom from "./components/uploadfiles/uploadurl/urlshowroom";
import Liveshowroom from "./components/livestream/liveshowroom";
import Uploadlive from "./components/livestream/uploadlive";
import Vimeoshowroom from "./components/uploadfiles/vimeo/vimeoshowroom";
import Uploadvimeo from "./components/uploadfiles/vimeo/uploadvimeo";
import Uploadpptx from "./components/uploadfiles/pptx/uploadpptx";
import Fastanswer from "./components/leaderboardgames/fastanswer/fastanswer";
import Fasteranswerroom from "./components/leaderboardgames/fastanswer/fasteranswerroom";
import Createoptions from "./components/leaderboardgames/options/createoptions";
import Optionshowroom from "./components/leaderboardgames/options/optionshowroom";
import Flipcards from "./components/leaderboardgames/flipcard/flipcards";
import Flipshowroom from "./components/leaderboardgames/flipcard/flipshowroom";
import Multioptionshowroom from "./components/leaderboardgames/multipleoptions/multioptionshowroom";
import Createmultioptions from "./components/leaderboardgames/multipleoptions/createmultioptions";
import Pptxshowroom from "./components/uploadfiles/pptx/pptxshowroom";
import Textslide from "./components/slides/textslide";
import Newtextshowroom from "./components/slides/newtextshowroom";
import Textshowroom from "./components/slides/textshowroom";
import Newtextslideedit from "./components/slides/newtextslideedit";
import Textslideedit from "./components/slides/textslideedit";
import Newteslideedit from "./components/slides/newteslideedit";
import Newteshowroom from "./components/slides/newteshowroom";
import Newerteslideedit from "./components/slides/newerteslideedit";
import Newerteshowroom from "./components/slides/newerteshowroom";
import Uploadvideoques from "./components/leaderboardgames/videoquiz/vid/uploadvideoques";
import Videoquizsetup from "./components/leaderboardgames/videoquiz/videoquizsetup";
import Videoplayer from "./components/leaderboardgames/videoquiz/videoplayer";
import Videoquizfile from "./components/leaderboardgames/videoquiz/videoquizfile";
import Videorecord from "./components/leaderboardgames/videoquiz/videorecord/videorecord";
import Videopreviewpage from "./components/leaderboardgames/videoquiz/videorecord/videopreviewpage";
import Ideorecordr from "./components/recordvideo/ideorecordr";
import Ideopreviewpager from "./components/recordvideo/ideopreviewpager";
import Fastansweredit from "./components/leaderboardgames/fastanswer/fastansweredit";
import Multioptionsedit from "./components/leaderboardgames/multipleoptions/multioptionsedit";
import Optionsedit from "./components/leaderboardgames/options/optionsedit";
import Ideoedit from "./components/recordvideo/ideoedit";
import Pollsedit from "./components/poll/pollsedit";
import Oneoptionquesedit from "./components/leaderboardgames/videoquiz/oneoptionquesedit";
import Imgedit from "./components/uploadfiles/img/imgedit";
import Giphyedit from "./components/gif/giphyedit";
import Flipcardsedit from "./components/leaderboardgames/flipcard/flipcardsedit";
import Urledit from "./components/uploadfiles/uploadurl/urledit";
import Vimeoedit from "./components/uploadfiles/vimeo/vimeoedit";
import Videoedit from "./components/uploadfiles/vid/videoedit";
import Gifsedit from "./components/uploadfiles/uploadgif/gifsedit";
import Blackboard from "./components/blackboard/blackboard";
import Blackboardedit from "./components/blackboard/blackboardedit";
import Blackboardshowroom from "./components/blackboard/blackboardshowroom";
import Mathoptionsedit from "./components/leaderboardgames/mathoptions/mathoptionsedit";
import Mathcreateoptions from "./components/leaderboardgames/mathoptions/mathcreateoptions";
import Mathoptionshowroom from "./components/leaderboardgames/mathoptions/mathoptionshowroom";
import Drawoptions from "./components/leaderboardgames/drawoptions/drawcreateoptions";
import Drawoptionsedit from "./components/leaderboardgames/drawoptions/drawoptionsedit";
import Drawoptionshowroom from "./components/leaderboardgames/drawoptions/drawoptionshowroom";
import Realtimewhiteboard from "./components/realtimeblackboard/realtimewhiteboard"
import Answer from "./components/answers/answer";
import Answerroom from "./components/answers/answerroom";
import Answeredit from "./components/answers/answeredit";
import Tutorials from "./pages/tutorials";
import Draws from "./components/blackboard/draw";
import Fastercorrctanswer from "./components/leaderboardgames/fastanswer/fastercorrctanswer";
import Drawcorrctanswer from "./components/leaderboardgames/drawoptions/drawcorrctanswer";
import Flipcardcorrctanswer from "./components/leaderboardgames/flipcard/flipcardcorrctanswer";
import Mathcorrctanswer from "./components/leaderboardgames/mathoptions/mathcorrctanswer";
import Multicorrctanswer from "./components/leaderboardgames/multipleoptions/multicorrctanswer";
import Onecorrctanswer from "./components/leaderboardgames/options/onecorrctanswer";
import Videocorrctanswer from "./components/leaderboardgames/videoquiz/videocorrctanswer";
import Showdragdrop from "./components/leaderboardgames/taptap/showdragdrop";
import Categorydragdrop from "./components/leaderboardgames/taptap/categorydragdrop";
import Categorytaptapedit from "./components/leaderboardgames/taptap/categorytaptapedit";
import Correcttaptap from "./components/leaderboardgames/taptap/correcttaptap";
import Showsequencing from "./components/leaderboardgames/sequencing/showsequencing";
import Createsequencing from "./components/leaderboardgames/sequencing/createsequencing";
import Sequencingedit from "./components/leaderboardgames/sequencing/sequencingedit";
import Correctsequencing from "./components/leaderboardgames/sequencing/correctsequencing";
import Adobe from "./components/adobeexpress/adobe";
import Adobeshow from "./components/adobeexpress/adobeshow";
import Adobeedit from "./components/adobeexpress/adobeedit";
import Desmos from "./components/desmos/desmos";
import Mirrordesmos from "./components/desmos/mirrordesmos";
import Mathfastanswer from "./components/leaderboardgames/mathgames/fastanswer/mathfastanswer";
import Createranking from "./components/ranking/createranking";
import Rankingedit from "./components/ranking/rankingedit";
import Showranking from "./components/ranking/showranking";
import Fastanswerimgedit from "./components/leaderboardgames/fastanswer/fastanswerimgedit";
import Fasteranswerimgroom from "./components/leaderboardgames/fastanswer/fasteranswerimgroom";
import Fastercorrctimganswer from "./components/leaderboardgames/fastanswer/fastercorrctimganswer";
import Createdesmossequencing from "./components/leaderboardgames/mathgames/sequencing/createdesmossequencing";
import Desmossequencingedit from "./components/leaderboardgames/mathgames/sequencing/desmossequencingedit";
import Desmoscorrectsequencing from "./components/leaderboardgames/mathgames/sequencing/desmoscorrectsequencing";
import Showdesmossequencing from "./components/leaderboardgames/mathgames/sequencing/showdesmossequencing";
import Createdesmosmultioptions from "./components/leaderboardgames/mathgames/multipleoptions/createdesmosmultioptions";
import Multidesmosedit from "./components/leaderboardgames/mathgames/multipleoptions/multidesmosedit";
import Multidesmosshowroom from "./components/leaderboardgames/mathgames/multipleoptions/multidesmosshowroom";
import Multidesmoscorrctanswer from "./components/leaderboardgames/mathgames/multipleoptions/multidesmoscorrctanswer";
import Mathfasteranswerroom from "./components/leaderboardgames/mathgames/fastanswer/mathfasteranswerroom";
import Mathfastansweredit from "./components/leaderboardgames/mathgames/fastanswer/mathfastansweredit";
import Mathfastercorrctanswer from "./components/leaderboardgames/mathgames/fastanswer/mathfastercorrctanswer";
import Createmathslide from "./components/mathslide/createmathslide";
import Editmathslide from "./components/mathslide/editmathslide";
import Mathslideshow from "./components/mathslide/mathslideshow";
import Convozesignup from "./pages/convoze/signup/convozesignup";
import Convozedashboard from "./pages/convoze/convozedashboard";
import Convozeeditroom from "./pages/convoze/convozeeditroom";
import Createanswer from "./components/convozeroom/createanswer";
import Convozerehearse from "./pages/convoze/convozerehearse";
import Chat from "./components/ai/chat";
import Createflipcards from "./components/flashcards/createflipcards";
import Mediaflipcardshow from "./components/flashcards/mediaflipcardshow";
import Mediaflipcardedit from "./components/flashcards/mediaflipcardedit";
import Reports from "./components/reports/reports";
import Exportpdf from "./pages/exportpdf";
import PublicLessonPlan from "./pages/publicLessonPlan";
import ShareReports from "./components/reports/shareReports";
import Uploadppt from "./components/uploadfiles/pptlive/uploadppt";
import Pptedit from "./components/uploadfiles/pptlive/pptedit";
import Pptshowroom from "./components/uploadfiles/pptlive/pptshowroom";
import Words from "./components/wordcloud/words";
import Wordcloudroom from "./components/wordcloud/wordcloudroom";
import Wordcloudedit from "./components/wordcloud/wordcloudedit";
import LessonLog from "./components/reports/lessonLog";
import Bardashbard from "./pages/teams/bardashbard";
import Teamslogin from "./pages/teamslogin";
import Uploadmp3 from "./components/uploadfiles/uploadaudio/uploadmp3";
import Mp3edit from "./components/uploadfiles/uploadaudio/mp3edit";
import Mp3room from "./components/uploadfiles/uploadaudio/mp3room";

export const UserContext = React.createContext();

const App = () => {
    return (
                <Router>
                        <div>
                            <Route exact path={"/host/:id/:mid"} component={Mroom} />
                            <Route exact path="/cohost/:id/:mid" component={Comroom} />
                            <Route exact path={"/ihost/:id/:mid"} component={Imroom} />
                            <Route exact path={"/groupleader/:id/:mid"} component={Vroom} />
                            <Route exact path={"/view/:id"} component={Room} />
                            <Route exact path={"/rform/:id"} component={Rform} />
                            <Route exact path={"/vform/:id"} component={Vform} />
                            <Route exact path={"/iview/:id"} component={Iroom} />
                            <Route exact path={"/view/mc/:id"} component={Mcroom} />
                            <Route exact path="/uploadvideoques/:id" component={Uploadvideoques} />
                            <Route exact path="/videorecord/:id" component={Videorecord} />
                            <Route exact path="/videopreviewpage/:id" component={Videopreviewpage} />
                            <Route exact path="/ideorecordr/:id" component={Ideorecordr} />
                            <Route exact path="/ideopreviewpager/:id" component={Ideopreviewpager} />
                            <Route exact path="/videoquizfile/:id" component={Videoquizfile} />
                            <Route exact path="/videoquizsetup/:id/:rid" component={Videoquizsetup} />
                            <Route exact path="/videoplayer/:id/:rid" component={Videoplayer} />
                            <Route exact path={"/vshowroom/:id/:rid"} component={Vshowroom} />
                            <Route exact path={"/uploadvideo/:id"} component={Uploadvideo} />
                            <Route exact path={"/uploadpdf/:id"} component={Uploadpdf} />
                            <Route exact path={"/mp3room/:id/:rid"} component={Mp3room} />
                            <Route exact path={"/uploadmp3/:id"} component={Uploadmp3} />
                            <Route exact path={"/Mp3edit/:id/:rid"} component={Mp3edit} />
                            <Route exact path="/fastansweredit/:id/:rid" component={Fastansweredit} />
                            <Route exact path="/fastercorrctanswer/:id/:rid" component={Fastercorrctanswer} />
                            <Route exact path="/showdragdrop/:id/:rid" component={Showdragdrop} />
                            <Route exact path="/correcttaptap/:id/:rid" component={Correcttaptap} />
                            <Route exact path="/categorydragdrop/:id" component={Categorydragdrop} />
                            <Route exact path="/categorytaptapedit/:id/:rid" component={Categorytaptapedit} />
                            <Route exact path="/drawcorrctanswer/:id/:rid" component={Drawcorrctanswer} />
                            <Route exact path="/flipcardcorrctanswer/:id/:rid" component={Flipcardcorrctanswer} />
                            <Route exact path="/mathcorrctanswer/:id/:rid" component={Mathcorrctanswer} />
                            <Route exact path="/multicorrctanswer/:id/:rid" component={Multicorrctanswer} />
                            <Route exact path="/onecorrctanswer/:id/:rid" component={Onecorrctanswer} />
                            <Route exact path="/videocorrctanswer/:id/:rid" component={Videocorrctanswer} />
                            <Route exact path="/fastanswerimgedit/:id/:rid" component={Fastanswerimgedit} />
                            <Route exact path="/fastercorrctimganswer/:id/:rid" component={Fastercorrctimganswer} />
                            <Route exact path="/fasteranswerimgroom/:id/:rid" component={Fasteranswerimgroom} />
                            <Route exact path="/imgedit/:id/:rid" component={Imgedit} />
                            <Route exact path="/vimeoedit/:id/:rid" component={Vimeoedit} />
                            <Route exact path="/videoedit/:id/:rid" component={Videoedit} />
                            <Route exact path="/urledit/:id/:rid" component={Urledit} />
                            <Route exact path="/answer/:id" component={Answer} />
                            <Route exact path="/adobe/:id" component={Adobe} />
                            <Route exact path="/adobeshow/:id/:rid" component={Adobeshow} />
                            <Route exact path="/adobeedit/:id/:rid" component={Adobeedit} />
                            <Route exact path="/answeredit/:id/:rid" component={Answeredit} />
                            <Route exact path="/answerroom/:id/:rid" component={Answerroom} />
                            <Route exact path="/flipcardsedit/:id/:rid" component={Flipcardsedit} />
                            <Route exact path="/multioptionsedit/:id/:rid" component={Multioptionsedit} />
                            <Route exact path="/optionsedit/:id/:rid" component={Optionsedit} />
                            <Route exact path="/pollsedit/:id/:rid" component={Pollsedit} />
                            <Route exact path="/ideoedit/:id/:rid" component={Ideoedit} />
                            <Route exact path="/textslide/:id" component={Textslide} />
                            <Route exact path="/newteslideedit/:id/:rid" component={Newteslideedit} />
                            <Route exact path="/newteshowroom/:id/:rid" component={Newteshowroom} />
                            <Route exact path="/newerteslideedit/:id/:rid" component={Newerteslideedit} />
                            <Route exact path="/newerteshowroom/:id/:rid" component={Newerteshowroom} />
                            <Route exact path="/newtextshowroom/:id/:rid" component={Newtextshowroom} />
                            <Route exact path="/textshowroom/:id/:rid" component={Textshowroom} />
                            <Route exact path="/newtextslideedit/:id/:rid" component={Newtextslideedit} />
                            <Route exact path="/textslideedit/:id/:rid" component={Textslideedit} />
                            <Route exact path="/giphyedit/:id/:rid" component={Giphyedit} />
                            <Route exact path="/oneoptionquesedit/:id/:rid" component={Oneoptionquesedit} />
                            <Route exact path="/gifsedit/:id/:rid" component={Gifsedit} />
                            <Route exact path="/flipshowroom/:id/:rid" component={Flipshowroom} />
                            <Route exact path="/flipcards/:id" component={Flipcards} />
                            <Route exact path={"/pdfshowroom/:id/:rid"} component={Pdfshowroom} />
                            <Route exact path={"/uploadimg/:id"} component={Uploadimg} />
                            <Route exact path={"/imgshowroom/:id/:rid"} component={Imgshowroom} />
                            <Route exact path={"/uploadurl/:id"} component={Uploadurl} />
                            <Route exact path={"/uploadlive"} component={Uploadlive} />
                            <Route exact path={"/giphy/:id"} component={Giphy} />
                            <Route exact path={"/gifshowroom/:id/:rid"} component={Gifshowroom} />
                            <Route exact path={"/liveshowroom/:id/:rid"} component={Liveshowroom} />
                            <Route exact path={"/uploadpptx/:id"} component={Uploadpptx} />
                            <Route exact path={"/uploadgifs/:id"} component={Uploadgifs} />
                            <Route exact path={"/gifroom/:id/:rid"} component={Gifroom} />
                            <Route exact path={"/leaderboard/:id"} component={Leaderboard} />
                            <Route exact path={"/pptxshowroom/:id/:rid"} component={Pptxshowroom} />
                            <Route exact path={"/urlshowroom/:id/:rid"} component={Urlshowroom} />
                            <Route exact path={"/uploadvimeo/:id"} component={Uploadvimeo} />
                            <Route exact path={"/iurlshowroom/:id/:rid"} component={Iurlshowroom} />
                            <Route exact path={"/createmultioptions/:id"} component={Createmultioptions} />
                            <Route exact path={"/Multioptionshowroom/:id/:rid"} component={Multioptionshowroom} />
                            <Route exact path={"/polls/:id"} component={Polls} />
                            <Route exact path={"/pollshowroom/:id/:rid"} component={Pollshowroom} />
                            <Route exact path={"/vimeoshowroom/:id/:rid"} component={Vimeoshowroom} />
                            <Route exact path={"/fastanswer/:id"} component={Fastanswer} />
                            <Route exact path={"/blackboardshowroom/:id/:rid"} component={Blackboardshowroom} />
                            <Route exact path={"/blackboardedit/:id/:rid"} component={Blackboardedit} />
                            <Route exact path={"/blackboard/:id"} component={Blackboard} />
                            <Route exact path="/tutorials" component={Tutorials} />
                            <Route exact path={"/mathoptionshowroom/:id/:rid"} component={Mathoptionshowroom} />
                            <Route exact path={"/mathoptionsedit/:id/:rid"} component={Mathoptionsedit} />
                            <Route exact path={"/mathcreateoptions/:id"} component={Mathcreateoptions} />
                            <Route exact path={"/drawoptionshowroom/:id/:rid"} component={Drawoptionshowroom} />
                            <Route exact path={"/drawoptionsedit/:id/:rid"} component={Drawoptionsedit} />
                            <Route exact path={"/drawcreateoptions/:id"} component={Drawoptions} />
                            <Route exact path={"/fasteranswerroom/:id/:rid"} component={Fasteranswerroom} />
                            <Route exact path="/createoptions/:id" component={Createoptions} />
                            <Route exact path="/mathfastanswer/:id" component={Mathfastanswer} />
                            <Route exact path="/optionshowroom/:id/:rid" component={Optionshowroom} />
                            <Route exact path="/support" component={Support} />
                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/pricing" component={Pricing} />
                            <Route exact path="/chat" component={Chat} />
                            <Route exact path="/timer" component={Timer} />
                            <Route exact path="/shareReports" component={ShareReports} />
                            <Route exact path="/termandConditions" component={TermandConditions} />
                            <Route exact path="/showsequencing/:id/:rid" component={Showsequencing} />
                            <Route exact path="/correctsequencing/:id/:rid" component={Correctsequencing} />
                            <Route exact path="/createsequencing/:id" component={Createsequencing} />
                            <Route exact path="/sequencingedit/:id/:rid" component={Sequencingedit} />
                            <Route exact path={"/createranking/:id"} component={Createranking} />
                            <Route exact path={"/showranking/:id/:rid"} component={Showranking} />
                            <Route exact path={"/rankingedit/:id/:rid"} component={Rankingedit} />
                            <Route exact path="/createdesmossequencing/:id" component={Createdesmossequencing} />
                            <Route exact path="/desmossequencingedit/:id/:rid" component={Desmossequencingedit} />
                            <Route exact path="/desmoscorrectsequencing/:id/:rid" component={Desmoscorrectsequencing} />
                            <Route exact path="/showdesmossequencing/:id/:rid" component={Showdesmossequencing} />
                            <Route exact path="/createdesmosmultioptions/:id" component={Createdesmosmultioptions} />
                            <Route exact path="/multidesmosedit/:id/:rid" component={Multidesmosedit} />
                            <Route exact path="/multidesmosshowroom/:id/:rid" component={Multidesmosshowroom} />
                            <Route exact path="/multidesmoscorrctanswer/:id/:rid" component={Multidesmoscorrctanswer} />
                            <Route exact path="/mathfasteranswerroom/:id/:rid" component={Mathfasteranswerroom} />
                            <Route exact path="/mathfastansweredit/:id/:rid" component={Mathfastansweredit} />
                            <Route exact path="/mathfastercorrctanswer/:id/:rid" component={Mathfastercorrctanswer} />
                            <Route exact path={"/createmathslide/:id"} component={Createmathslide} />
                            <Route exact path={"/editmathslide/:id/:rid"} component={Editmathslide} />
                            <Route exact path={"/mathslideshow/:id/:rid"} component={Mathslideshow} />
                            <Route exact path={"/convozeeditroom/:id"} component={Convozeeditroom} />
                            <Route exact path={"/createanswer/:id"} component={Createanswer} />
                            <Route exact path={"/convozerehearse/:id"} component={Convozerehearse} />
                            <Route exact path={"/createflipcards/:id"} component={Createflipcards} />
                            <Route exact path={"/exportpdf/:id"} component={Exportpdf} />
                            <Route exact path={"/publicLessonPlan/:id"} component={PublicLessonPlan} />
                            <Route exact path={"/mediaflipcardedit/:id/:rid"} component={Mediaflipcardedit} />
                            <Route exact path={"/mediaflipcardshow/:id/:rid"} component={Mediaflipcardshow} />
                            <Route exact path={"/uploadppt/:id"} component={Uploadppt} />
                            <Route exact path={"/pptshowroom/:id/:rid"} component={Pptshowroom} />
                            <Route exact path={"/pptedit/:id/:rid"} component={Pptedit} />
                            <Route exact path={"/words/:id"} component={Words} />
                            <Route exact path={"/draw"} component={Draws} />
                            <Route exact path={"/wordcloudedit/:id/:rid"} component={Wordcloudedit} />
                            <Route exact path={"/wordcloudroom/:id/:rid"} component={Wordcloudroom} />
                            <Route exact path={"/rehearse/:id"} render={props => {
                                return <Rehearse {...props} />
                            }} />
                            <Route exact path={"/selfStudy/:id"} render={props => {
                                return <SelfStudy {...props} />
                            }} />
                            <Route exact path={"/lessonLog/:id"} render={props => {
                                return <LessonLog {...props} />
                            }} />
                            <Route exact path={"/reports/:id"} render={props => {
                                return <Reports {...props} />
                            }} />
                            <Route exact path={"/mirrordesmos/:id"} render={props => {
                                return <Mirrordesmos {...props} />
                            }} />
                            <Route exact path={"/desmos/:id"} render={props => {
                                return <Desmos {...props} />
                            }} />
                            <Route exact path="/qrcodepage/:id" render={props => {
                                return <Qrcodepage {...props} />
                            }} />
                            <Route exact path={"/editroom/:id"} render={props => {
                                return <Editroom {...props} />
                            }} />
                            <Route exact path="/inviteform1/:id" render={props => {
                                return <Inviteform1 {...props} />
                            }} />
                            <Route exact path="/inviteondemandform/:id" render={props => {
                                return <Inviteondemandform {...props} />
                            }} />
                            <Route exact path="/convozedashboard" component={Convozedashboard} />
                            <Route exact path="/convozesignup" component={Convozesignup} />
                            <Route exact path="/realtimewhiteboard" component={Realtimewhiteboard} />
                            <Route exact path="/bardashbard" component={Bardashbard} />
                            <Route exact path="/teamslogin" component={Teamslogin} />
                            <Route exact path="/" component={Dashboard} />
                        </div>
                </Router>
    )
}

export default App
