import React, {useEffect, useState} from "react";
import {API, Auth} from 'aws-amplify';
import {getPlayslide, listHosts, listLeaderboards, listPlayslides} from '../../../graphql/queries';
import "../style.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Grid from "@material-ui/core/Grid";

function Morereports(props) {
    const  id  = props.data;
    const [thisdatas, setThisdatas] = useState([]);
    const [thisalldatas, setThisalldatas] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [errormessage, setErrormessage] = useState("")
    useEffect(() => {
        (async() => {
            const response = await API.graphql({
                query: getPlayslide,
                variables: {id}
            })
            const datas = response.data.getPlayslide;
            const iowner = datas.owner;
            try{
                const response = await API.graphql({
                    query: listPlayslides,
                    variables: {
                        filter: {
                            "owner": {
                                eq: iowner
                            }
                        },
                        limit:10000
                    }
                })
                const datas = response.data.listPlayslides.items;
                setThisdatas(datas)
            }catch(err){
                console.log(err)
            }
        })()
    }, []);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
async function getdatas(){
    if(endDate > startDate) {
        const filteredDates = thisdatas.filter(item => {
            const updatedAtDate = new Date(item.createdAt);
            return updatedAtDate >= startDate && updatedAtDate <= endDate;
        });
        const filteredIds = filteredDates.map(item => ({
            id: item.id,
            meetingname: item.meetingname
        }));
        setThisalldatas(filteredIds)
    }else{
        setErrormessage("Display To is not greater than Display From")
    }
}

    return (
        <>
        <div>
            <Grid container spacing={0} className="topspace1">
                <Grid item xs={1} className="cccenter">
                </Grid>
                <Grid item xs={2} className="cccenter">
                    <p>Display From: </p>
                </Grid>
                <Grid item xs={2} className="cccenter">
                <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="MM/dd/yyyy"
                    className="dateinput"
                />
                </Grid>
                <Grid item xs={2} className="cccenter">
                    <p>Display To: </p>
                </Grid>
                <Grid item xs={2} className="cccenter">
                <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="MM/dd/yyyy"
                    className="dateinput"
                />
                </Grid>
                <Grid item xs={2} className="cccenter">
                    <button onClick={getdatas}>Search</button>
                </Grid>
                <Grid item xs={1} className="cccenter">
                </Grid>
            </Grid>
            <p className="redthis">{errormessage}</p>
        </div>
        <div className="lessonslist">
            <ol>
                {thisalldatas.map((item, index) => (
                    <li key={index} className="biggertext"><p onClick={() => {
                        window.open(`https://demo.reachum.com/lessonLog/${item.id}`)
                    }}> {item.meetingname}</p> </li>
                ))}
            </ol>
        </div>
            </>
    );
}

export default Morereports;
