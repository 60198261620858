import React, { useState, useEffect } from 'react';
import {API} from 'aws-amplify';
import {useParams} from "react-router-dom";
import { getFasteranswer } from "../../graphql/queries";

const  Mediaflipcardshow = ()  =>{
    const { id,rid } = useParams();
    const [thisimage, setThisimage] = useState('')
    const [thisgimage, setThisgimage] = useState('')
    const [thismathimage, setThismathimage] =useState('')
    const [openthis, setOpenthis] = useState(false)
    const [isFlipped, setIsFlipped] = useState(false);
    const [thismessage, setThismessage] = useState("Learn More")
    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData(){
        const response = await API.graphql({
            query: getFasteranswer,
            variables:{id:rid}
        })
        const datas = response.data.getFasteranswer;
        const tt = datas.fanswer;
        if(tt!== null){
            setThisgimage(tt)
        }
        const mt = datas.qmathstate;
        if(mt!== null){
            setThismathimage(mt)
        }
        const mimage = datas.fquestion;
        if(mimage !== null){
            setThisimage(mimage)
        }
    }
    const handleCardClick = () => {
        setIsFlipped(!isFlipped);
        setThismessage("Guess")
    };
    return (
        <>
            <div className="mediaflipcard">
                <div className={`mediaflip-card ${isFlipped ? 'flipped' : ''}`}>
                    <div className="mediaflip-card-inner">
                        <div className="mediaflip-card-front" onClick={handleCardClick}>
                                <h1>{thisgimage}</h1>
                        </div>
                        <div className="mediaflip-card-back" onClick={handleCardClick}>
                            <div className="mediainnerflipcard">
                              <div className="flashcardleft">
                                    <h4>{thisimage}</h4>
                              </div>
                                <div className="flashcardright">
                                    <img src={thismathimage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
            </div>
        </>
    );
}

export default Mediaflipcardshow;
